export const SliderProducts = {
    Kits: [
        'GPKITBORRACHASFOX2',
        'GPKIT10XG052385Q1',
        'GPKIT4XBXO5W30GA',
        'GPKITBORRACHASGOLG7',
        'GPKIT2XG052385Q0',
        'GPKIT10XBXO5W30AA',
        '50290576',
        'GPKIT4X46348847',
        'BH8A19A435AA',
        'GPKITREVFORDROCAM',
        'GPKIT2X5U4831721A1NN',
        'F1FZ7B546B',
        '2G5052184',
        '7702270913',
        'GPKITREVFORDDRAGON',
        '5U0054620C',
        'GPKIT3XGN1G12405CA',
        '7090166',
        'GPKITBORRACHASGOLG4',
        '7701477014',
        '7089633',
        'LBRW005844',
        '117203694R',
        'BRPRT10628',
        'GPKITREVCOMPLETAKWID2',
        'GPKIT51836085108',
        '7702271349',
        'GPKITTROCAOLEOTORODIESEL',
        'GPKIT7XBRPRT20087',
        'GPKIT2X5U3831721A1NN',
        'GPKITPBARROYARIS',
        'GPKIT4MOLDURASPALIO',
        'GPKITTODASCORREIASEA211V2',
        'PV1700D030B1',
        '50927787',
        'GPKIT6X7092803',
        'GPKIT4X1884608073',
        '7702271200',
        '8201740600',
        'GPKIT51766536',
        'GPKIT3X224330437R',
        'GPKIT5XK68231014LA',
        'GPKIT4XKSP261405AB',
        'GPKIT4X9816863980',
        '7090104',
        '5U0121201B',
        'GPKITREVISAO1K4MCOMFILTRO',
        '7089638',
        'GPKITTROCAOLEOTORODIESEL4',
        'JN1Z7B546C',
        '7089067',
        'GPKITTROCAOLEOTORODIESEL3',
        'GPKIT1SB837431B5AP32',
        'GPKIT4X46480362',
        '130C10516R',
        'GPKITEMBKIAHYUND10',
        'LBRP005734',
        '50928122',
        '7089857',
        '50927341',
        'V04010057CE7Q',
        '50927617',
        'GPKIT4XFS112405AB',
        '7711733127',
        'GPKIT2X51959492',
        'GPKIT5X8660089534',
        'GPKITTROCAOLEOEA11116C',
        '04C141015M',
        '55254269',
        'GPKITSINCROKOMBI',
        '543A00300R',
        'GPKITBATENTES543A00952R',
        '7701208891',
        '543A03661R',
        '032141016E',
        '50290171',
        'GPKIT4X6EA035710',
        'BRPRT11010',
        '504068388',
        '543A00952R',
        '31480361',
        'LBRW005846',
        'PV6250D010',
        'GPKITTODASCORREIASEA211V6',
        '98500024',
        'GPKIT4XGJZZ502M2BRA',
        'GPKIT7XK68218057LB',
        '4605B628',
        '179774',
        '55257219',
        'GPKIT8XK68231014LA',
        'GPKITFRISOSTETOTRITON',
        'K82214056',
        '93349818',
        '55277616',
        'GPKITCOMANDOK4M',
        'GPKITTROCAOLEOCVTCOROLLA20',
        'GPKITTODASCORREIASEA211V5',
        'GPKITREVM4RBASE',
        'GPKITBATENTES543A00300R',
        'FA6Z7B546A',
        'GPKIT4X9808389880',
        'GPKIT5W3831721A5X6827705',
        'GPKITTODASCORREIASEA211',
        'BRPRT11057',
        'GPKITREVGDCHEROKEE',
        'PW5D00K001',
        'GPKIT4VELAS8201611343',
        'GPKITTROCAOLEOTOROFLEXB5',
        'GPKITREVISAOH4MCAPTURLOGAN3',
        '100223582',
        'GPKIT2X4881502320',
        '7088845',
        'GPKIT4XGS55553R2BRA',
        'GPKIT4X5960H7',
        'GPKIT2X5Z0412331C',
        'GPKIT214989KE0B',
        '8200725335',
        'GPKIT4VELAS9091901256',
        'GPKIT2X402065RA2A',
        '1621267280',
        '50928587',
        'GPKITREVISAOCOMPLETOEA111162',
        'GPKIT5XK68444159LA',
        'GPKIT5U0035501H5U0035849C',
        'GPKITTODASCORREIASEA211V3',
        'GPKITREVISAO1B4DKWID3',
        'GPKITREV204152YZZD6',
        'GPKIT5XK68218920LA',
        'GPKITTROCAOLEOETORQ',
        '7701477533',
        'GPKIT2X0888681915',
        'GPKITTROCAOLEOTORODIESEL2',
        'GPKIT5Z095540910B',
        '50927805',
        '1684448180',
        '7088926',
        'GPKIT2XJ2MJ1125AA',
        'GPKIT4VELAS9091901299',
        'GPKITREVT270',
        'GPKIT4VELAS9091901261',
        'GPKIT2AMORTECEDORES543020714R',
        'GPKITREVISAO1K4M',
        'GPKIT7X0888680807',
        'GPKIT6XK68547228LA',
        'GPKIT2X7090796',
        'BRPRT10996',
        '7701477515',
        'CWPA0831',
        '55270143',
        'GPKITBORRACHASFOX3',
        'GPKITREVTCE13',
        'GPKITOLEOFILTRO1520865F0E',
        'M4R32MB021SAW',
        '29400397',
        'GPKIT5U080995758',
        'GPKIT4VELAS7700500155',
        'GPKIT4X5960G4',
        'GPKITBORRACHASFOXTRAS',
        'GPKITREVISAO1B4DKWID2',
        'GPKITTROCAOLEOTOROFLEX2',
        'GPKITREVISAOFRONTIER25',
        '50928994',
        '50927715',
        'GPKIT3X1654509380',
        'GPKIT4X55200755',
        'GPKITN90617501036103677',
        'GPKIT4X55267121',
        'GPKIT5XBRPRT20099',
        'PV4030K010',
        '117209732R',
        'GPKITBOMBACORREIASMEGANEK4M',
        'GPKITDISTRIBPSA1616V',
        '71754211',
        '31480362',
        '50927788',
        'GPKIT3X9803800180',
        'GPKIT2H0868565EY20AY',
        'GPKITTROCAOLEOCOMPASSFLEXB2',
        'GPKITMANGAMAROK',
        'GPKITREV290915YZZM3',
        'GPKIT6VELAS274103C000',
        'GPKIT100205013',
        '32213096',
        'GPKIT4VELAS04C905607',
        'GPKIT2X9823082180',
        'GPKIT4X224017726R',
        'GPKIT3X55267995',
        'GPKITCALCODESLIZGOL',
        'GPKIT030109423B030109411B',
        '98500130',
        'GPKIT100185653',
        'GPKIT4X46531918',
        'GPKIT4X9809802380',
        'GPKIT3X46353272',
        '150A06727R',
        '7717000006',
        'GPKITREVISAOH4MCAPTURLOGAN2',
        'GPKITREV190915YZZM3',
        '7701476327',
        'GPKIT86600895378660089774',
        'GPKITTROCAOLEOTOROFLEX3',
        'GPKIT4X5M5G12405AA',
        'GPKIT2X5U0407625B',
        'GPKIT4X403151796R',
        'GPKIT4VELAS9091901298',
        'GPKIT3VELAS04C905607',
        'GPKIT3VELASHB202015',
        'GPKIT9XBXO5W30AA',
        'GPKIT2X9827816880',
        '117206170R',
        '117200777R',
        'PZV1806019FF',
        '130C17529R',
        'GPKITREVISAO1K7M',
        'GPKIT302058177R306206219R',
        'GPKITCN157540ACBM557A564AB',
        'GPKIT6X2741023700',
        '2G5071310',
        '7088880',
        'GPKITTROCAOLEOCVTYARIS',
        'PZ06712708',
        'PV5010D021B1',
        'GPKITTROCAOLEO13FIREFLY',
        '7702271556',
        '1SM15BR300',
        '46354124',
        'GPKIT2GP807221GRU',
        '67800366A',
        'CAPA2747',
        'GPKIT2AMORTECEDORES562105006R',
        'GPKITGJZZ040R3G12E040R2',
        'GPKIT3VELAS1884908085',
        'GPKIT2X5U4831721A1NN33',
        'GPKIT4VELAS9008091244',
        '7701476645',
        'PV50102011',
        'GPKITFILTROSMEGANE16',
        'GPKITREVISAO1B4DSANDEROLOGAN',
        '302186786R',
        '302057116R',
        'GPKIT3X0888681915',
        'GPKITTROCAOLEO10FIREFLYB',
        'GPKIT4X1632745480',
        'GPKIT4X5960K3',
        'GPKIT5U0820803K5Z0820411E',
        'GPKIT4VELAS9091901164',
        'GPKIT4X403152550R',
        'GPKIT4VELAS9008091245',
        'GPKITREVISAO1B4DSL3LITROS',
        'GPKITREVRGDLEX',
        'GPKIT8X030109411B',
        'GPKIT4X5801594342',
        'GPKITREVISAOMASTER23COMPLETOV2',
        'GPKITCORREIATENSOREA211TSI',
        '7701477537',
        'LBRW000181',
        'GPKITREVISAO1M9T',
        'GPKITTROCAOLEO13FIREFLYB',
        'GPKIT4X55267995',
        'GPKIT881874E300GW',
        'GPKIT5X0888083399',
        'GPKIT4BICOS032906031R',
        'GPKIT5X8660089842',
        'GPKIT4VELAS1881411051',
        'GPKIT882864E300GW',
        'GPKIT6X1884611060',
        'GPKIT51848658',
        'GPKIT4X403155RA0E',
        'GPKIT4VELASIX35GAS',
        'GPKIT4X036129689B',
        '7089039',
        '8550506762',
        '302051548R',
        '98500560',
        '24586361',
        '55219692',
        'GPKIT9XK68218057LB',
        '130C19924R',
        'GPKITTROCAOLEOETORQ2',
        'GPKIT5U0809961D5U0809958D',
        'GPKIT4XGJZZ530R2BRA',
        'GPKIT4X224013BS7B',
        'GPKIT4X8200207049',
        'GPKIT4X7092615',
        'GPKITDEFLETORKICKS',
        'GPKIT2X1643172880',
        'GPKIT8XK68218925LA',
        'GPKIT2X6R0501541B',
        '7711733695',
        'CAPA3091',
        'GPKITREVISAO1H4M',
        'GPKITREVISAO1H4M2',
        'GPKIT5U019985157B55E',
        'LBRW005854',
        'PV4560K001',
        'GPKITREVISAO1M4R',
        'GPKIT6XG052182A2',
        '7088113',
        '77367304',
        'GPKITREVFIREFLY10COMPL',
        '5031F2',
        '7702271203',
        '71754009',
        'GPKITREV104152YZZD6',
        'GPKIT4X166006863R',
        'GPKITBORRACHASGOLG4B',
        'GPKIT4X2240100Q0F',
        'GPKIT2X7083084',
        'GPKIT4X8671004086',
        'GPKITGN1Z6268DGN1G6K245BD',
        'GPKIT6XMTSW144',
        'GPKIT2X543022374R',
        'GPKIT3XK68218950LA',
        'GPKIT7X7092803',
        'GPKIT4X46353272',
        'GPKIT4VELAS101905625',
        'GPKIT4X403159KE0B',
        'GPKIT2G1941773A74A',
        'GPKIT2X5Z0823729',
        'GPKITCABOSVELASROCAM',
        'GPKITRESERVKOMBI',
        'GPKIT5XGJZZ502M2BRA',
        'GPKIT4XK68218950LA',
        'GPKIT2X46455892',
        'GPKIT036115636D030115611P',
        'GPKITREVISAOH4MDUSTER',
        'GPKITBOMBACORREIASDUSTERK4M',
        '7088847',
        'GPKIT5Z0698151B',
        '7701477380',
        '302053201R',
        '50928940',
        'GPKITTROCAOLEOEA11116B',
        '999G6LZ000',
        '50928111',
        '806016505R',
        'GPKITEMBREAGEMATUADORDUSTER16',
        '46337019',
        'GPKIT2XWHT004739',
        'GPKIT4X0888083399',
        'GPKIT4X1882308101',
        'GPKIT8X0888084536',
        'GPKIT5XGJZZ530R2BRA',
        'GPKIT8XGS55545M2BRA',
        'GPKITBORRACHASFOX',
        'GPKIT4X1106500Q0L',
        '1643637980',
        'GPKITREVISAO1B4DKWID',
        '1643639680',
        '94744060',
        '302103084R',
        'GPKIT4633757955202374',
        '7089341',
        '26318208',
        '7090330',
        '7701475193',
        'BH2T16450AACR',
        'PC2850D008',
        'GPKIT04E121600CJ',
        'GPKITTROCAOLEOCOMPASSFLEXB3',
        'GPKIT30100ED80B',
        '130C19110R',
        'GPKITBOMBACORREIASKANGOOK4M',
        'GPKITREM4RCOMFILTRO',
        'GPKIT519386014P',
        'GPKIT17B955425A03C17B955426A03C',
        'GPKIT4XGJZZ530Q2BRA',
        'GPKIT4X55226520',
        'GPKIT4XGJZZ250R2BRA',
        'GPKIT5U0201922A5U0201553A',
        'GPKIT5X8660089620',
        '55224935',
        'GPKIT16X46475925',
        '2050R7',
        '7093039',
        'GPKITTROCAOLEOEA11116BCOMB',
        '7702270914',
        'GPKIT5W0011031',
        '1638159680',
        '7091295',
        '302053243R',
        'GPKIT5X1K0899185',
        'GPKITE43029AN2AIN',
        '1635823880',
        '46340471',
        'GPKITREV304152YZZD6',
        '100258450',
        'JZZ198015C',
        'GPKITCA4205912',
        'GPKIT4X5960K2',
        'GPKIT1SB941016',
        'GPKITRESERVEA111A',
        'GPKIT1JE941015L16',
        'GPKIT668945RB0B',
        'GPKIT4X0888084536',
        'GPKIT2AMORTECEDORES543024025R',
        'GPKIT2X9677161080',
        '7701479150',
        '302050901R',
        'GPKITCALCOFORD',
        '7095016',
        'BRPRT10496',
        '310010K020',
        '04C141015S',
        '5801407375',
        'GPKIT5W08585651NN',
        '71754559',
        'GPKITTREVTOROFLEXCOMPL',
        'GPKITCORRTENSOREA111',
        '1S7Z6268BC',
        '7094710',
        'APS598625',
        '98500019',
        'GPKITBATENTESENTRA',
        'GPKITTROCAOLEOCOMPASSFLEXB4',
        '1631627780',
        'CAPA2745',
        'BRPRT10587',
        'GPKIT4X403159AA0C',
        'GPKIT2XCAPA1675',
        'GPKIT2X5Z08234991NN',
        'GPKIT5XMTSW144',
        'GPKIT4X1885508080',
        'GPKIT4X1885308060',
        'GPKITSUPORTESDIANTEIROFOX',
        'GPKIT4VELAS9091901275',
        'GPKIT5X0888084536',
        'GPKITTODASCORREIASEA111',
        '130C16917R',
        '46835713',
        '302054795R',
        'GPKITREVISAO1D4DCOMBAR',
        'GPKITREVISAO1K7MCOMFILTRO',
        '175201434R',
        '80011176',
        '55223631',
        'GPKITTROCAOLEOEA211TSI2',
        '5U0098609BB',
        'GPKITTROCAOLEOT270',
        'GPKITREVISAONIVUS',
        '1610793280',
        '7089066',
        'GPKITREFLETORESGOLG6',
        '8201740955',
        'GPKITREVISAOFRONTIER23C',
        'GPKITCXSUPRADIADOR',
        '7092459',
        '32298420',
        'GPKIT4VELAS7700500168',
        'GPKIT3VELAS04E905602A',
        'GPKIT2X1636504080',
        'GPKIT2AMORTECEDORES543024709R',
        'GPKIT3BICOS04E906036AR',
        'GPKIT7X0888083399',
        'GPKIT5U02019225U0201553A',
        'GPKIT1SB8099612',
        'GPKIT4XGS55545M2BRA',
        'GPKIT6X059130277FC',
        'GPKIT2X321513279',
        'GPKIT5Z9860145152NN',
        'GPKITBANDEJAFLUENCE',
        'GPKIT5U7827517AE18',
        'GPKIT130C11551R117209732R',
        'GPKITTROCAOLEOTOROFLEX',
        'GPKITREVNISSANGERAL',
        '7702271198',
        'GPKITTROCAOLEODSG6',
        '100261408',
        '36132453961',
        '50700021',
        'BRPRT10285',
        'GPKITREV13FIREFLY',
        'V04010020B',
        '0C6141671D',
        'BRPRT10197',
        'GPKITCORREIATENSORPINOEA211',
        '7088846',
        'GPKITREVISAO1F4R',
        'GPKITBALANCINSTUCHOSEA211',
        'GPKITTROCAOLEO10FIREFLY',
        'GPKIT2X5U0819707ST3',
        'GPKIT4X55226521',
        'GPKIT100175616',
        'GPKITZBA823369',
        'GPKITBATENTESENTRAB',
        'GPKIT5U0035209936',
        'GPKIT4VELAS1884908080',
        'GPKIT3VELAS04E905602F',
        'GPKIT130203HC5A',
        'GPKIT030115105S032103161K',
        'GPKIT100242580',
        'GPKIT2X2119452415',
        'GPKIT9X8660089614',
        'GPKIT5U1955409B10B',
        'GPKIT6Q0955453P',
        'GPKIT4X1822A147',
        '55230367',
        'GPKITTROCAOLEOEA11116E',
        'GPKITTROCAOLEOHYUNDAI10B',
        '34218569413',
        '7711733758',
        'PV17002040B0',
        '7081699',
        '1611503880',
        'GPKIT6XWHT007480A',
        'GPKIT5W08585591NN',
        'GPKITREVVERSA16',
        '7089012',
        '32106891974',
        'GPKITBALANCINSTUCHOSEA2114CC',
        '7711732168',
        '117200334R',
        'GPKITREV404152YZZD6',
        'GPKITREVB4DSANDLOGANCOMPLETO',
        'GPKITTREVTOROFLEXCOMPL2',
        'GPKITTROCAOLEOEA211TSI10',
        'LBRWKIT006',
        'GPKITREVNISSANMARCH16',
        'CN1Z99264A26A',
        '7702270345',
        'CAPA2746',
        'GPKIT5XGS55553R2BRA',
        'GPKIT4VELAS030905607',
        'GPKIT2X403155RA0E',
        'GPKIT4X403159LH0C',
        'GPKIT4BICOS166091245R',
        'GPKIT735407064',
        'GPKIT2XGJZW012R2',
        'GPKIT2X1139410315',
        'GPKIT4VELAS224019874R',
        'GPKIT377419549AWHT000425',
        'GPKIT6X1BP30487AA',
        'GPKIT7X0888681926',
        'GPKITBOBINASVELASB4D',
        'GPKIT3VELASHB202016',
        'GPKIT4X166091245RBUCHAETRAVA',
        '6EA052186A',
        'GPKIT2X5Z09559855Z0955751A',
        'GPKITJUNTASEGRAMAROK',
        'GPKITTROCAOLEOHYUNDAI16B',
        'GPKIT3X5U0857607Y20',
        '130C13130R',
        'GPKITSPOILERTRASUP',
        '4605B958',
        'CAPA1004',
        'GPKIT3XGJZZ250R2BRA',
        '31401425',
        'GPKITTODASCORREIASEA211V4',
        '1643013580',
        'GPKITTODASCORREIASEA211V13',
        '1635823080',
        'GPKITSUPORTESDIANTFOX',
        'GPKITTROCAOLEOEA11116',
        'GPKITCHROMEDELNIVUS',
        '7701476745',
        'GPKIT3X0888680807',
        '55243330',
        'GPKIT032103063AD',
        '495761M600',
        '2551A346',
        '2925308C',
        'GPKITTROCAOLEOEA11116H',
        'GPKIT4X55231678',
        'GPKIT3X0888083399',
        'GPKIT545005RA0D',
        'GPKIT2X5U4837249',
        'GPKIT2XG12E040R2',
        'GPKIT2X5U0881375',
        'GPKIT70902937090294',
        'GPKIT4XBRPRT20103',
        'GPKIT7X0888084536',
        'GPKIT3X04E905110Q',
        'GPKIT4X55263233',
        'GPKIT1SB819404A9B9',
        'GPKIT5Z080593132',
        'GPKIT2AMORTECEDORES562101820R',
        'GPKIT6XBRPRT20087',
        'GPKITRESERVEA211A',
        'GPKIT130201HC0C',
        'GPKIT2X1647883380',
        'GPKIT4X1BP31567AA',
        'GPKIT1JE945095F96F',
        'GPKITBOMBACORREIASDUSTERF4R',
        '29400355',
        'GPKITTROCAOLEOEA888GEN2',
        'GPKITREVISAO1D4DCOMB',
        'GPKITTROCAOLEOEA11116BCOMB3',
        '302054753R',
        '24271356',
        'BH9A17K000BA',
        'GPKITREVISAOFRONTIER23',
        '55228690',
        'GPKITTODASCORREIASEA211V11',
        '7702271551',
        'GPKITBOMBABAIXAALTABICOSAMAROK',
        '7701477517',
        'GPKIT2X377857561Y20',
        'GPKIT5X0012115D',
        '120A17400R',
        'GPKIT2GP853675A2ZZ2GP853675C2ZZ',
        'GPKITMOTULEA888GEN2',
        '98500017',
        '7701475628',
        'GPKIT4X030103157E',
        'CAPA3023',
        'GPKIT5U0853653F041',
        '32270114',
        '302051060R',
        '32329657',
        'GPKITSUPORTESTRASEIROGOL',
        'GPKITTROCAOLEOEA211BUJAO',
        '117209294R',
        'GPKITREVISAOMASTER23COMPLETO',
        'GPKIT1J0407181357407182',
        '130C16661R',
        'GPKIT321512335',
        'GPKITMOTULEA888GEN3',
        'GPKIT04C141016S02T141153AA',
        'GPKITREVRGDLEX3',
        'GPKIT8X030109423B',
        'GPKITFLAUTASAMAROKV6',
        'GPKITBATENTES543A03661R',
        'GPKITREVFORDROCAM2',
        'GPKIT10XK68218925LA',
        'GPKIT2X51836108',
        'GPKITETIQUETASPF',
        'GPKIT030198955A030198955C',
        'GPKIT2H5807627C9B9',
        'GPKIT4XKSP148183AD',
        'GPKIT4X7094388',
        'GPKIT4X403151862R',
        'GPKIT4X12122455258',
        'GPKIT2X6Q5827550B',
        'GPKIT2H680770708',
        'GPKIT4XBRPRT20097',
        'GPKIT8X55210509',
        'GPKIT032103383N',
        'GPKIT2XG052527R2',
        'GPKIT5U0422811A12A',
        'GPKIT4VELAS224019202R',
        'GPKIT4X026105401',
        'GPKITEMBLEMASCOMFORTLINE',
        'GPKIT7XBRPRT20097',
        'GPKIT2X6Q4867911JB41',
        'GPKITFAROLMASCNEGRAGSIENA',
        'GPKIT2X2378575051',
        'GPKIT2X6EA827761A',
        'GPKIT7XGS55545M2BRA',
        '130C11551R',
        'GPKITTROCAOLEOEA21116BCOMB',
        'GPKITREVISAOEA111COMPLETOB',
        'GPKITREV10FIREFLY',
        '04C141016J',
        '1666762380',
        'GPKITTROCAOLEOTORODIESEL6',
        'GPKITREV13FIREFLYFD',
        'BG5T6268AA',
        'GPKITTAMPASAMAROKV6',
        'GPKITREVISAOEA111COMPLETO',
        'GPKIT4XWHT005302',
        'GPKITREVEA211TSILS',
        'GPKITTODASCORREIASEA211V10',
        'GPKITREVISAOHYUNDAI10B',
        '7700840099',
        '1637819080',
        '1682770480',
        'GPKITBOMBABICOSAMAROK4CC',
        '411200530R',
        '1643012880',
        'GPKIT5PARAFUSOSEA211',
        'GPKITMOTULEA211',
        '50928337',
        'GPKIT1SB0354531SB035710',
        '7701477540',
        'GPKITMOLDURASSAVEIROCROSSG6',
        '1SD20BR010',
        'BK3Q6268BA',
        'GPKITTROCAOLEOAMAROK4CC2',
        'GPKITGRADESPAJERO',
        '7701478728',
        'GPKITREVISAOFRONTIER23B',
        'GPKITPFCOMANDOVW',
        'GPKIT5U0820159AE60',
        'PC4030K014',
        '46926TA0A02',
        'GPKITBATENTES543207065R',
        'GPKIT6EA8536889CFOD',
        'GPKIT23784342512378434231',
        'GPKIT2X6Q0411314R',
        'GPKIT8XBRPRT20087',
        'GPKIT2X5Z4837249',
        'GPKIT2X6Q5943021',
        'GPKIT2X2QB413031J',
        'GPKIT3X7092615',
        'GPKIT2X6Q08371879B9',
        'GPKIT4X5960F3',
        'GPKIT4X8671004070',
        'GPKIT5XGS55545M2BRA',
        'GPKIT2X2Q0411314A',
        'GPKIT4VELAS101905610C',
        'GPKIT2X5U0413031A',
        'GPKIT5U0407365A66A',
        'GPKIT4VELAS1881408063',
        'GPKITCOXINSARGO10',
        'GPKIT6XGS55553R2BRA',
        'GPKIT4XMTSW144',
        'GPKIT5U7609721B',
        'GPKIT6EA805911A12A',
        'GPKIT4BICOS04E906036AR',
        'GPKIT2XG055512A2',
        'GPKIT4X219616',
        'GPKIT4X03L130277QALMAX',
        'GPKIT4X1SB601149CZQ',
        'GPKIT7XBRPRT20151',
        'GPKIT2X54618JX00A',
        'GPKIT3XN91130401',
        'GPKIT4X2115133811',
        'GPKIT4X03L130277Q',
        'GPKIT2X1S0413031F',
        '117204663R',
        'GPKIT5U0867109',
        '55268011',
        'GPKIT7X1643067780',
        'GPKITCORREIASVW3CCV2',
        '1643012980',
        '55243331',
        '32213657',
        '302050632R',
        'GPKIT3XG052385Q0',
        'CAPA2061',
        'GPKIT5U0820721B',
        '24589757',
        'GPKITREVISAOFRONTIER25D',
        'GPKIT6X059130519B',
        'GPKITTROCAOLEOCOMPASSFLEX',
        'GPKITSUPORTESDIANTEIROGOLG7',
        'GPKITTROCAOLEOEA11116F',
        'PV5010A010B0',
        'GPKITTROCAOLEOCOMPASSFLEXB',
        '51890248',
        'GPKITTROCAOLEODSG7',
        'BRPRT10590',
        '55219720',
        '4M0063262A',
        'GPKITGJZZ107M2BRACOMFILTRO',
        'GPKITTODASCORREIASEA211V9',
        'GPKITTROCAOLEOHYUNDAI10',
        'GPKITEMBLEMASTCROSS',
        '1BP31778AA',
        'GPKITTROCAOLEOAMAROKV6',
        '50926444',
        'GPKITBOMBACORREIASLOGANK7M',
        'GPKITREVRGDLEX2',
        'BG8AA156A04BA',
        '7089635',
        'GPKIT2GP8059019B9',
        'GPKITTROCAOLEOEA211TSI2ECON',
        'GPKITREVISAOCOMPLETOEA11116',
        '29400217',
        'GPKITCORREIASVW3CC',
        'GPKIT16XN10430503',
        '9464466388',
        'GPKIT2Q0955453F1K6955651',
        'GPKITTROCAOLEOAMAROK4CC',
        '5Z0071597D',
        '29400356',
        '7091606',
        '117206746R',
        '7091637',
        'GPKITTROCAOLEOFIRE5W30',
        'GPKIT5Z6945105B06B',
        'GPKIT4VELAS04E905602A',
        'GPKIT2X8660089538',
        'GPKIT2X5X0943021',
        'GPKIT4X5U0601147EYSW',
        'GPKIT2X51868075',
        'GPKIT62222EA500',
        'GPKIT4X100246072',
        'GPKIT4VELAS9091901262',
        'GPKIT8X55186463',
        'GPKIT4VELAS12120035531',
        'GPKIT3X7092811',
        'GPKIT4X2GM601147BUWP',
        'GPKIT2X51761830',
        'GPKITBOCALPOLOVIRTUS',
        'GPKIT2X51959682',
        'GPKIT10XG052164R1',
        'GPKIT2X5Q0615301F',
        'GPKIT6XGJZZ530R2BRA',
        'GPKIT2X6QE827550',
        'GPKIT4VELAS06K905601D',
        'GPKIT5U7945095G',
        'GPKIT2X5U0098609BC',
        'GPKIT4VELAS1884611070',
        'GPKIT5195181617',
        'GPKIT4VELAS9008091246',
        'GPKIT4X2114053171',
        'GPKIT3XGS55553R2BRA',
        'GPKIT5Z0201231A',
        'GPKIT2X8660089537',
        'GPKIT4X04E905110Q',
        'GPKIT6VELAS12120039634',
        'GPKIT4VELAS04E905612C',
        'GPKITTROCAOLEOTORO24',
        'BRPRT10192',
        '7701477013',
        'GPKITTROCAOLEOEA21116COMB',
        'GPKITMOTRIOROSA',
        'GPKITBICOSEBOMBAAMAROKV6',
        '03L198119F',
        '77515A2A474',
        'GPKIT9XBRPRT20097',
        '302057590R',
        'GPKITTROCAOLEOEA21116',
        'GPKITTROCAOLEOCOMPASSFLEX3',
        'GPKIT5U0807184B5U0807183B',
        '32373125',
        '100260531',
        'GPKIT02610917528131',
        '7091640',
        'GPKITCORREIASUPTSI',
        '7092463',
        'GPKITBOMBAFLAUTAAMAROK',
        'GPKIT12X059129717S',
        'GPKITJZZ198015C02T141153AA',
        '46308764',
        'GPKIT5U0805583E84',
        '263890881R',
        'GPKITTROCAOLEOEA888GEN3',
        'GPKITREVISAO1D4D',
        'GPKITTROCAOLEOEA11116D',
        'GPKITMOTRIOVERDE',
        'GPKITTREVTOROFLEXCOMPL3',
        'GPKIT032141016E02T141153AA',
        'GPKIT1SB807183E4',
        'GPKITTROCAOLEOEA111CBUJAO',
        '50928338',
        'GPKIT7XK68218057GE',
        '7091119',
        'GPKIT2GP853651G9B9',
        '806019662R',
        'GPKIT15X059129717S',
        'GPKITREVTHP',
        '1654512780',
        'GPKITMOTRIOAMARELO',
        '8201313792',
        '30622764',
        'CD210041',
        'GPKIT6X059103085L',
        'GPKIT5Z0823363A',
        'GPKITTROCAOLEOEA211TSI2B',
        '302054789R',
        '7090746',
        'GPKITTROCAOLEOEA211',
        'GPKITTROCAOLEOCOMPASSFLEX2',
        '302059954R',
        '7711734344',
        'GPKITSUPORTESTRASVOYAGE',
        'PC5931200Z',
        '495062PA20',
        'GPKIT2X1133112951',
        'GPKIT10X2151323001',
        'GPKITMOTULEA211V2',
        '919403K005QQH',
        '7090867',
        '1643108480',
        '7702271360',
        '24586359',
        '8201516550',
        '7711732170',
        '32358W030P',
        'GPKITGRADESSAVCROSS',
        'GPKIT5U7807393E4',
        'GPKIT500388688',
        'BRPRT11020',
        '7087176',
        'GPKIT5Z1857522A5Z1857521',
        '55228245',
        '71734907',
        'GPKIT059130089DNE90',
        '85640458',
        '31359568',
        '1619230180',
        'GPKIT2AMORTECEDORES562100788R',
        'GPKITCAPASCORREIAEA111',
        'GPKIT5U4837113BZI5',
        'GPKIT2X5Q0501541C',
        'GPKIT5Z0941007A08A',
        'GPKIT2X6Q0411314Q',
        'GPKIT2X1K0505541D',
        'GPKIT1S0407151A52A',
        'GPKIT4VELAS9091901291',
        'GPKIT2X1J0412331C',
        'GPKIT2X2QB413031Q',
        'GPKIT5Z0819403A9B9404',
        'GPKIT2X377412329B',
        'GPKIT3VELAS224019874R',
        'GPKIT4XJZZ905603',
        'GPKIT1X166091245RBUCHAETRAVA',
        'GPKIT4X8201739941',
        'GPKIT4X12120040551',
        'GPKIT6EA867911B9B912',
        'GPKIT3X0888681926',
        'GPKIT4X7092811',
        'GPKIT2X5U0035453A',
        'GPKIT545009LH0F',
        'GPKIT5Z0823301HE2H',
        'GPKIT2X6RF412319',
        'GPKIT3X224013BS7B',
        'GPKITLANTERNASKOMBI',
        'GPKIT2X1J0411314R',
        'GPKITLANTERNASSANDERO',
        'GPKIT2X1643172780',
        'GPKIT2X1J0407181',
        'GPKIT2X5U0413031AE',
        'GPKIT2X2GX413031C',
        'GPKIT6EA9980026EA955427',
        'GPKIT4X8660089536',
        'GPKIT5Z48374771NN81',
        'GPKIT2X6N0412249E',
        'GPKIT3X9803800280',
        'GPKIT5W081946566',
        'GPKIT4VELAS9091901210',
        'GPKIT2X543203VA0A',
        'GPKIT2XGS55553R2BRA',
        'GPKITPALHETASUP',
        'GPKITPESTANASHB20',
        'GPKIT4VELAS06H905601A',
        'GPKITROLAMENTO8200651172',
        'GPKIT2X2H0413031N',
        'GPKIT2X8660089544',
        'GPKIT5U01296185Z0129684A',
        'GPKIT2X5U7827793B',
        'GPKITBANDEJADUSTER',
        'GPKIT10X0491033853',
        'GPKIT8VELAS12120039634',
        'GPKIT2X1J0501249C',
        'GPKIT5Z086377971N',
        'GPKIT101905610C032905409B',
        'GPKIT2X51836085',
        'GPKIT4X06K905601B',
        'GPKIT2X8660089539',
        'GPKIT5U1941007D08D',
        'GPKIT20X5Z0867299',
        'GPKIT2XGJZZ502M2BRA',
        'GPKIT5U09570871FR',
        'GPKIT2Q0698151RJZW698451',
        'GPKIT4X5U0601147NZ31',
        'GPKITE43029AN2AINB',
        'GPKIT5XN90788402',
        'GPKIT6VELAS1884611070',
        'GPKIT2X1S0513025P',
        'GPKIT4X7091619',
        'GPKIT2X2Q0411314C',
        'GPKIT2Q0698151R2QB615301E',
        'GPKIT4AMORTKWID',
        'GPKIT5U7945095J96G',
        'GPKITBOMBABAIXAAMAROK',
        'GPKIT5XG052167A4NOEA888GEN2',
        'GPKIT7L0823633H',
        '7088928',
        'GPKITTROCAOLEOEA211ECON',
        '31338929',
        'GPKIT12X04E109411N',
        '06121KVS305',
        'GPKIT2X6Q0512131B',
        'GPKITTODASCORREIASEA211V12',
        '1643012480',
        '51946712',
        '302052458R',
        '04C141016S',
        'GPKITCORREIATENSOREA111',
        '5U0498099A',
        '04E141018',
        'GPKITOLEOFILTRO1520865F0EB',
        '5Z0201231A',
        '1679938180',
        '1BP30836AA',
        '7711733444',
        '7702271444',
        'MB2A7540AA',
        '1635663280',
        'GPKITTROCAOLEOFIRE5W40',
        'GPKITTROCAOLEOAMAROKV6B',
        '302052307R',
        '93277629',
        '7711732027',
        'BRPRT11343',
        'GPKIT2X5Z0615301BB',
        '11129899381',
        '55227112',
        'GPKITBUCHASREPAROGOLG2',
        'GPKITTROCAOLEOEA888GEN2B',
        '1643108380',
        '117206838R',
        'GPKITREVISAOHYUNDAI16B',
        'GPKITREVT270B',
        '98500020',
        'GPKIT04E121119AM',
        '03L141016M',
        '735523357',
        'AT4Z6268A',
        'GPKIT2GP805901B9B9',
        '7717070074',
        '1643119680',
        'GPKITREV250TSI',
        'GPKIT10XWHT001865',
        'GPKITREV200TSICOMPLETA',
        'GPKIT5U0853101',
        'GPKIT04E121605E',
        'GPKIT2X2H0422405',
        'GPKIT8XN90970702',
        '7088952',
        '117202848R',
        '237273',
        '7091147',
        '7701469496',
        'GPKIT5U0807221ABGRU',
        '1611284980',
        'GPKITTROCAOLEOFIRE5W30BP',
        'GPKITWHT005302',
        'GPKITTROCAOLEOEA21116B',
        'GPKIT06A109119J',
        '24591145',
        'GPKITPFCOMANDOVWTSI',
        '140357308R',
        '237102472R',
        '8200855938',
        '7089444',
        'GPKITEMBLTCROSS200',
        'GPKITN10571405',
        'GPKITREVISAOFRONTIER25B',
        'BRPRT10142',
        'GPKITTROCAOLEOMOPARDIESEL',
        'GPKIT2X5Z0955985',
        'GPKITREVISAOHYUNDAI10',
        '302054100R',
        'GPKITBICOSEBOMBAAMAROKV62',
        'GPKITTROCAOLEOETORQ4',
        '04E141016H',
        'GPKIT030109119AB',
        'GPKITSUPORTESDIANTVIRTUS',
        '31659996',
        'GPKITREV250TSICOMPLETA',
        'BRPRT10769',
        'GPKIT8XBRPRT20097',
        'GPKIT2XN10320101',
        '55563645',
        '130C13538R',
        'GPKITREV13FIREFLYFE',
        '06155LUB001',
        '6001548020',
        'GPKITTROCAOLEOEA211BUJAOANEL',
        '130C16776R',
        'GPKITREV200TSI3',
        '7088930',
        '7711425257',
        '130C13666R',
        'GPKIT10X6Q0121367A',
        'GPKIT933491234',
        'GPKITREVISAO1B4DSLCOMFILTRO',
        'GPKIT3GRADESTCROSS',
        'GPKIT0CT141153C04C141016S',
        '8200704394',
        'BRPRT10723',
        'BRPRT10564',
        'GPKITTROCAOLEOEA111CBUJAOC',
        '7711732237',
        'GPKITTROCAOLEOEA111163',
        '8V0698647',
        'GPKITREV13FIREFLYFC',
        '1608222080',
        'GPKITCORREIASF4R',
        '5U0498201',
        '31664974',
        '2S657540DC',
        'GPKIT5U0820159B60B',
        '302058177R',
        'GPKIT032103063ADV2',
        'GPKIT2X6QE513353',
        'GPKITREVISAO1F3R',
        'GPKITREV200TSI2',
        'CN157540AC',
        '4230707',
        'JZZ198015E',
        'GPKIT4X839601361',
        'GPKIT2X377807189',
        'GPKITREVCOMPLETAKWID',
        'GPKITTROCAOLEOTORODIESEL5',
        '71773157',
        'GPKITTROCAOLEOEA11116BCOMBLS',
        '97200M4001FFF',
        'LBRW005188',
        '7091635',
        '7711732214',
        'GPKITCORREIASUPMSI',
        'GPKIT2X2QB407183',
        'GPKIT2GP8531012ZZ',
        'GPKIT2GM8548199B9',
        'GPKIT2X5QM412249',
        'GPKIT5U0805583C1NN84',
        'GPKIT2X7X0721365',
        'GPKIT2X357413175A',
        'GPKIT5U0807239E40E',
        'GPKIT5U0121407B',
        'GPKIT1K0412249B1K0412331C',
        'GPKIT4X377501541',
        'GPKIT8XK68218925GB',
        'GPKIT1SB827550',
        'GPKIT2X546133BA0A',
        'GPKIT2X5W4831721A1NN',
        'GPKIT2XAPR601307GRPI',
        'GPKIT4X12120042724',
        'GPKIT2X1J6827550B',
        'GPKITE3B517528BAAB',
        'GPKIT377809961A',
        'GPKIT6Q0831411A',
        'GPKITN90288901N0138158',
        'GPKIT2X5Z0407625',
        'GPKIT2XG052164R1',
        'GPKIT5U794100506',
        'GPKIT5U0853665AB9B9',
        'GPKIT2X1J0412331C6R0412249',
        'GPKIT2X2QB411315',
        'GPKIT5U794509596',
        'GPKITBORRACHASPARATI',
        'GPKIT100203046100197617',
        'GPKIT3X04C906031E',
        'GPKIT2X2115011911',
        'GPKIT5Z0853601CFDY5Z0853343',
        'GPKITAMORTECEDORDTTOROAUTO',
        'GPKIT2X2Q0411314B',
        'GPKIT4X2GT601147Z31',
        'GPKIT4XG052164R1',
        'GPKIT4X12120055028',
        'GPKIT2X377412503AG',
        'GPKIT4X2115112491',
        'GPKIT2X377827550E',
        'GPKIT2X2QB615301E',
        'GPKIT5W0819465A66',
        'GPKIT2X5Z0615301B',
        'GPKIT5U7945095F96F',
        'GPKIT2AMORTECEDORES543029585R',
        'GPKIT2X1636506280',
        'GPKIT2X5Z4839249A',
        'GPKIT2X1S0615301C',
        'GPKIT4X100263033',
        'GPKIT6XWHT004923B',
        'GPKIT7X0721335A7X0721361',
        'GPKIT5U08536771NN',
        'GPKIT5U7860147KN248',
        'GPKIT2GP805911B12B',
        'GPKIT2X2H0615301D',
        'GPKIT03L131511Q',
        'GPKITPALHETAJETTA',
        'GPKITRESERVEA211B',
        'GPKIT5U694510506',
        'GPKIT2X2QB407183357407182',
        'GPKIT2X6RD411315',
        'GPKIT2GP80772324',
        'GPKIT5Z1941007M08',
        'GPKIT6X274103C000',
        'GPKIT5XGJZZ107M2BRA',
        'GPKIT5VELAS101905601F',
        'GPKIT4XGJZZ107M2BRA',
        'GPKIT4X224481KT1A',
        'GPKIT5U79451055U7945106',
        'GPKIT4X5U0601147TZ31',
        'GPKIT2X2QB615301D',
        'GPKIT2X2H0412303D',
        'GPKIT2X503527',
        'GPKIT2X52004363',
        'GPKIT6QE941007D08D',
        'GPKIT032103601AAPARAF',
        'GPKIT06K10348306K103583',
        'GPKIT6EA806930A29C',
        'GPKIT5X219554',
        'GPKIT24XG052164R1',
        'GPKIT10016762324',
        'GPKIT2X5U7413175',
        'GPKIT5U7860381E2KN2',
        'GPKIT4X032107411C',
        'GPKIT2AMORTECEDORES543026348R',
        'GPKIT2GM8547319B932',
        'GPKIT2AMORTECEDORES562103964R',
        'GPKIT4X55228879',
        'GPKIT2X5Z6827550E',
        'GPKIT4XH2MJ19544CA',
        'GPKIT7XBRPRT20079',
        'GPKIT03L903137AC06H145255F',
        'GPKIT032998905032905409B',
        'GPKIT4X1J0601171XRW',
        'GPKIT2X562108239R',
        'GPKIT1K0407183E357407182',
        'GPKIT4XGJZZ107R2BRA',
        'GPKIT4X403156203R',
        'GPKIT2X3055121352',
        'GPKIT10017808283',
        'GPKIT2X377711133',
        'GPKIT4X811407181A',
        'GPKIT4VELAS12120034098',
        'GPKIT2G0941661D62D',
        'GPKITFILTRODSG7',
        'GPKITANELBUJEA888',
        'GPKIT2X546139VB0A',
        'GPKIT5X48373551NN',
        'GPKIT5U18194031NN41',
        'GPKIT12XG052167A4NO',
        'GPKIT06B115611R06A103663C',
        'GPKIT6EA805071B72B',
        'GPKIT12XGS55553R2BRA',
        'GPKIT2X2115133811',
        'JZZ198015D',
        'GPKIT10XBRPRT20097',
        'GPKITCALCODESLIZGOLB',
        '638320007R',
        'GPKITTROCAOLEOEA888GEN3LS',
        'GPKIT10X056109119A',
        'GPKITCORREIATENSOREA111B',
        '32242867',
        'GPKITREVPSA1616V',
        '302051004R',
        'GPKIT04E109244B',
        '0113AKRE306',
        '5U0052184F',
        'GPKITBUCHASPQ24',
        '52300378',
        'GPKITFILTROSEA111',
        'GPKITWHT004914',
        'GPKIT04E109119L',
        '328795',
        '30731727',
        'GPKITTROCAOLEODQ500',
        '7089554',
        'GPKIT357413175A1H0412303B',
        'GPKITTROCAOLEO25JETTA',
        '1BP30834AA',
        'GPKITREV200TSI',
        'GPKIT6Q0807184AE3A',
        'GPKITTROCAOLEOEA11116BLS',
        '237106531R',
        'GPKIT4XG052167A4NOFILTRO',
        '34116852852',
        '10267466',
        '130C16781R',
        'GPKIT4XGJZZ250R2BRAFILTRO2',
        'GPKIT04E109244D',
        'GPKITCORREIASTAMPAOK4M',
        'GPKITFRISOTRASVIRTUS',
        'GPKIT10X6QE121367',
        'GPKIT04E260849J',
        '130C13954R',
        'GPKITN10690201',
        '7711732033',
        'GPKIT04C109479J',
        '1611981780',
        'GPKITGJZZ107M2BRACOMFILTRO2',
        '130C12131R',
        '1680600QA4',
        'GPKITCORREIASVWEA211',
        'GPKIT5U7827517AE1805A06A',
        'GPKITTROCAOLEOEA21116ECON',
        '543A06915R',
        'GPKITK68218920LAFILTRO',
        '5Z0052184B',
        '7701477522',
        'GPKITREVISAONIVUSLS',
        'GPKIT302054789R7700102781',
        '7701209242',
        'GPKITTROCAOLEOEA888GEN2C',
        '06121KVK305',
        '53186778',
        'GPKIT026109119D026109243M',
        '5295014140N',
        'GPKITREV5W30THP',
        'GPKIT321512335B',
        '1680600Q0L',
        'GPKIT2GP0615002GP061325',
        'GPKIT5U0853677F9B9',
        '7711134823',
        'GPKITBOMBABICOSAMAROK4CCV2',
        '9467633588',
        '5G0071685GRU',
        '302051253R',
        '6QE498099',
        '0123DKRE305',
        '02E398029B',
        '1611279180',
        '1680600Q1D',
        'GPKITTROCAOLEOAMAROK4CC3',
        'GPKITCOXINSEA21116',
        '7087082',
        '6001550003',
        '0123AKVK305',
        '31336865132',
        '302057688R',
        'GPKITPESTANASGOLG3G4',
        'GPKITMOTULEA888GEN2V2',
        'GPKITREVEA21114',
        'GPKIT5U0853665B1NN',
        'GPKIT52200366',
        'GPKIT5U0853665S9B9',
        'GPKIT04E145933AD',
        'GPKIT04E121605M',
        '7088951',
        'GPKIT04C141016J',
        'GPKITREVFORD3',
        'JZZ198015',
        'H0610KWG911',
        '63750',
        '0123BKRE305',
        '7701475108',
        '0941BK36305',
        'GPKITREVJETTA14MK6',
        '1BP30817AA',
        '353043CA00QQK',
        '6001549089',
        'BRPRT10644',
        'GPKIT04E145933AG',
        '376919051A',
        'GPKIT04C260849E',
        'GPKITTROCAOLEOEA888GEN3B',
        '7702270442',
        'GPKIT8XWHT007845',
        'GPKIT4XWHT000884',
        '302055852R',
        'GPKIT10XJZZ109119',
        'GPKITTROCAOLEOETORQ3',
        'GPKIT8X050109309N',
        'CAPA2231',
        'BRPRT10401',
        'GPKITREV12PURETECH',
        'GPKITREVISAOHYUNDAI16',
        'GPKITREVPSAB',
        'GPKITTROCAOLEOAMAROK4CC5',
        '0941AK36305',
        'GPKIT7089967',
        '50927760',
        'GPKIT2H6127401G',
        'GPKITREVISAOFRONTIER23F',
        '1SC16BR003',
        'GPKIT10X77365902',
        'GPKIT5523041055218890',
        'GPKITREVTIIDA',
        'GPKITSUSPDTMVK',
        '7091313',
        'GPKIT5U0807221TGRU',
        '71734906',
        '0904AK31305',
        '15101RNAA00',
        'GPKITREVISAONIVUS2',
        '06121KSS305',
        '8200992553',
        'K2142125000',
        'GPKIT933491933492',
        '16148549916',
        'H0615KPF000',
        'H5321GFP900',
        'GPKIT55219692',
        'GPKITCORREIASEA211E',
        'GPKIT3X04E103157K',
        'GPKIT04E141031D04E141025L',
        'GPKIT16X1K06011739B9',
        '150A09501R',
        '130C15830R',
        'GPKIT2XN90686902',
        'GPKIT2X5Z6827550J',
        'GPKITTROCAOLEOEA21116BCOMBLS',
        'GPKIT04C260849EB',
        'GPKITTROCAOLEOAP',
        'GPKITTROCAOLEO25JETTAB',
        '0123AKWG305',
        '2050A0',
        '0904AK62305',
        '8200828444',
        'GPKITREVISAOFRONTIER23E',
        '61667208073',
        '4905009A',
        'H0620KVSJ00',
        'GPKIT4XGJZZ250R2BRAFILTRO',
        'CDPA0261',
        'GPKIT12X8120AL',
        'CDPA0258',
        '2H0898375EINF',
        'GPKITREV200TSICOMPLETA3',
        '8V0998121',
        '71771442',
        'GPKITMOLDSTRADACS',
        '08P033R48T0C',
        '412003C550',
        '71776901',
        '7702271553',
        'GPKITBICOSEBOMBAAMAROK2011',
        'V04010085G',
        '7711568028',
        '117206887R',
        'GPKITREVISAONIVUS3',
        'GPKITTROCAOLEOHYUNDAI16',
        'BRPRT10567',
        'H0640KGA901',
        'GPKIT5U0807183E4',
        '06F198119A',
        'GPKIT219616DIESEL',
        'GPKITMOTULEA888GEN3V2',
        '7717000004',
        'GPKIT059198405B',
        'GPKIT1K06011739B9',
        'PV62502010',
        'H0640K13900',
        'GPKITTROCAOLEOAMAROK4CC4',
        '7701477032',
        'GPKITREFLETORESVOYAGE2013',
        '323582718R',
        '52300644',
        '55248344',
        'GPKITREVPSA1616VB',
        '8200669525',
        'GPKITMOLDURASLANTERNATRASGOL',
        '34118569410',
        '130C12067R',
        'GPKIT7088534',
        '5083007A',
        'GPKITTROCAOLEOEA11116G',
        'GPKIT219615DIESEL',
        '32270862',
        'GPKIT2XN0177612',
        '24586360',
        'GPKIT2X1133112691',
        '32242869',
        'GPKITREVISAO1M9TB',
        'GPKITREV200TSI4',
        'GPKITREVISAO1G9U',
        'GPKIT5961814',
        '7091636',
        '2H0075111E',
        '0123DKWG305',
        'GPKITFAIXASGOL',
        'GPKIT4X059130519',
        '63217456519',
        'GPKIT8XG052167A4NO',
        '9567437888',
        'GPKITTROCAOLEOAMAROKV6C',
        '0904BK62305',
        '682602440R',
        '5054908',
        '7090706',
        '31664609',
        '41300241',
        'GPKITREVNISSANGERALC',
        '7711732241',
        '7701475945',
        'GPKITAM5Z7B546BAM557A564AB',
        'V04010088A',
        '7701206592',
        '117209956R',
        'H0620KGA900',
        '52300643',
        'GPKITREV200TSICOMPLETA2',
        '7711732654',
        '7700417172',
        '8200150759',
        'GPKIT7088138',
        '441006176R',
        '7701469776',
        '54668EB71A',
        '205259',
        'CV6Z7B546B',
        '0904AKSS305',
        '7700100967',
        'GPKIT4X1K0899185',
        'GPKITREVJETTA14MK6B',
        '7089359',
        '46352495',
        '0442812290',
        'PV17002010A0',
        '1607044080',
        'GPKIT6X0012243',
        'GPKIT5XN90891601',
        '93579R1060FFF',
        'GPKITREVISAO1D4DCOMBAR2',
        '0123EKWG305',
        'GPKIT2X5U0863851A',
        'GPKITTROCAOLEOEA111CBUJAOD',
        'K68165797AA',
        '50901734',
        'GPKIT032115611P',
        'GPKIT2X51761830TAMPA',
        'GPKITCAPASCORREIAGOL10',
        'GPKITREV12PURETECHB',
        '0123BKWG305',
        '819001SC00',
        'GPKIT6X5U78642039B9',
        '263897056R',
        '0122AKSS305',
        'GPKIT20X2151323001',
        '7086007',
        '735532309',
        '54610D3000',
        'GPKITTODASCORREIASEA111V3',
        'GPKITTODASCORREIASEA211V8',
        '8201714682',
        '7089529',
        'GPKITTROCAOLEOEA111CBUJAOB',
        'GPKIT2GP806333AEL7',
        'GPKIT5Z0805757B',
        'GPKITREVISAO1G9U9L',
        '0904CK31305',
        'BRPRT10796',
        'GPKITREVEA11116',
        '11848544599',
        '77448546808',
        'GPKIT2GP061270',
        '2GP071133',
        '0904BKSS305',
        '100270709',
        'EB3Z5E258B',
        '319403AX0A',
        'GPKITREVBPRODIESEL',
        'GPKIT20X1K06011739B9',
        '52300441',
        'BH2T16450BAPRE',
        '50927967',
        '6001551411',
        'GPKIT16X3C06011739B9',
        'AM5Z7B546B',
        '100226815',
        'K68144163AF',
        'GPKITTROCAOLEOEA11110CBTK',
        'GN1Z7B546F',
        '805031478R',
        '0123AK69305',
        'GPKIT1K6955651443955465',
        'H0640K62B00',
        '80B064205A',
        '55271678',
        '06121K31305',
        '117205934R',
        '5Q0198037',
        '7702271565',
        'GPKITN0160276',
        'GPKITREV200TSICOMPLETABTK',
        '7089636',
        'GPKITJZZ698151AEDISCOS',
        '55278194',
        'GPKITGRADESCROSS',
        'GPKIT2XN90335004',
        'GPKITREVISAOFRONTIER25C',
        '495934D700',
        '3791190',
        'GPKIT04C141015M02T141153AA',
        '9414EE',
        '41002288195',
        'GPKIT7XG052182A2',
        'APS198015K',
        'GPKIT2GP853651P9B9',
        '229960',
        '150A03157R',
        '1610000280',
        '39793403',
        'GPKITREVISAOFRONTIER23G',
        'GPKITREVNISSANGERALB',
        'GPKIT030199275G',
        'H0640K19900',
        'GPKIT3XG052385Q1',
        '7091639',
        'GPKIT10WHT002473',
        '8201609652',
        'GPKITREVISAOFRONTIER23D',
        'GPKIT2X07C133588P',
        'GPKIT10X5U0121073',
        'V04010037E',
        '0904GKSS305',
        '11121025095',
        'GPKITTROCAOLEOCOMPASSFLEXBB',
        '0904BK31305',
        'GPKITTROCAOLEOEA111COMFBTK',
        'H0640KRE851',
        '36318520871',
        '11129457898',
        '2QB498103B',
        'GPKITTROCAOLEOEA211TSI2C',
        'GPKITCORRTENSOREA111B',
        '600389059R',
        'GPKIT5X080723940',
        'GPKIT6EA945095B96B',
        'GPKIT2X1J0513425',
        'GPKIT10XK68218925GB',
        'GPKIT2X5428677801NN',
        'GPKIT25XLIT04145170',
        'GPKIT8XWHT007089',
        'GPKITGRADESFOX',
        'GPKIT2X1J0512149B',
        'GPKITDISCOSDIANTBMW640',
        'GPKIT2X5Z4831721E1NN',
        'GPKIT4X32783718711NN',
        'GPKIT02E305051C',
        'GPKIT5GB80704950',
        'GPKIT2X5Q0411315D',
        'GPKIT2X5Q0411315A',
        'GPKIT2X2QB513049L',
        'GPKIT5Q0411315APORCA',
        'GPKIT2AMORTECEDORES543020634R',
        'GPKIT5U7827793BPARAF',
        'GPKIT2X377512137',
        'GPKIT2X5Q0615301H',
        'GPKIT2H1941015L16L',
        'GPKIT3X7092615FIRE',
        'GPKITSUPDIANTNIVUS',
        'GPKIT4X03L130277C',
        'GPKIT5Z0941007C',
        'GPKIT2XJZZ513025E',
        'GPKIT4X030109601AS',
        'GPKIT2X2GX513049A',
        'GPKIT4X7092613',
        'GPKIT1K0121407F2Q0121321A',
        'GPKIT6XN0385494',
        'GPKIT5U0853665G9B9',
        'GPKIT4X12120039664',
        'GPKIT5U0853677D9B9',
        'GPKIT2XJZZ413031C',
        'GPKIT2X811407181A',
        'GPKIT059103148AL49AL',
        'GPKIT2X5U0885683A71N',
        'GPKIT3X01X301127C',
        'GPKIT6EA82330102',
        'GPKIT2X5U6827550B',
        'GPKITDISCOSDIANTBMWX5M',
        'GPKITBATENTES8200723741',
        'GPKIT8XN90894601',
        'GPKIT2X51791068',
        'GPKIT4X2195549818914980',
        'GPKIT032121110K',
        'GPKIT2AMORTECEDORES562108189R',
        'GPKIT4X7S6G12405AA',
        'GPKIT2X5Z4833721C1NN',
        'GPKIT2X51791059',
        'GPKIT2X1JE943021',
        'GPKIT2X6N0412331E',
        'GPKIT4X55186463',
        'GPKIT2X289321198R',
        'GPKIT2X5U0615301E',
        'GPKITCORREIASEA211B',
        'GPKIT2X2114012731',
        'GPKIT5206328384',
        'GPKIT2X5Z0615301D',
        'GPKITOLEO06A115561B',
        'GPKIT852215RB0A220',
        'GPKIT5W080723940',
        'GPKIT8X7734791',
        'GPKIT4X030129717E',
        'GPKIT2X1K0407183E',
        'GPKIT2X2G5827550A',
        'GPKIT2X51826649',
        'GPKITCORREIASEA211D',
        'GPKIT2X1SB943021',
        'GPKIT2X5U0927753',
        'GPKIT4X857407181',
        'GPKIT6EA941005C06C',
        'GPKIT5Z1941008Q',
        'GPKIT4X101905608A',
        'GPKIT2X5U0412545',
        'GPKIT5U0422803A04A',
        'GPKIT4X04E906036AF',
        'GPKIT4XGJZZ530Q2BRAFILTRO',
        'GPKIT2AMORTECEDORES543029417R',
        'GPKIT10024040102',
        'GPKITTROCAOLEOEA113',
        'GPKIT2X2QB615601B',
        'GPKIT2X2H0411313C',
        'GPKIT4X5U7601147Z31',
        'GPKIT30541132713054113131',
        'GPKITPALHETASFOX',
        'GPKIT8XWHT005422B',
        'GPKIT2X5Z0412331C6N0412249E',
        'GPKITCORREIASEA211A',
        'GPKIT5X55200024',
        'GPKIT5U0853665B1NN66',
        'GPKIT2X6R0941511',
        'GPKIT5X030103464A',
        'GPKIT2X34116860907',
        'GPKITJUNTASAMAROKV6',
        'GPKIT2X2H0411317B',
        'GPKIT2X546114237R',
        'GPKIT2XZBC609657',
        'GPKIT030105269HN90501402',
        'GPKIT2X377823499A',
        'GPKIT4X030129717G',
        'GPKIT2X424966',
        'GPKIT06H103483C06H103483D',
        'GPKIT4X224015R97B',
        'GPKIT2X402069LH0A',
        'GPKIT5U7853596',
        'GPKIT2X2GX615301',
        'GPKIT5U0807239G40',
        'GPKIT4X030129717F',
        'GPKIT6EA853665A9B966',
        'GPKIT5X06A115561B',
        'GPKIT5Z0858071C1FRDIFUSOR',
        'GPKIT2X5U0513025L',
        'GPKIT4X030107411H',
        'GPKIT16X55186463',
        'GPKIT036103383AF',
        'GPKIT735365021',
        'GPKIT2X5U7513025D',
        'GPKIT4X219554',
        'GPKIT5NN80704950',
        'GPKITPALHETASUP2',
        'GPKIT4X6C0601171XQI',
        'GPKIT2X376827133A',
        'GPKIT5111739685354',
        'GPKIT377881292A71N',
        'GPKIT2X1JE698649A',
        'GPKIT543029LH1B',
        'GPKIT6XGJZZ502M2BRA',
        'GPKIT2X5W0419809',
        'GPKIT4X1K06011739B9',
        'GPKIT6X5Z0853695',
        'GPKIT4X04L103070B',
        'GPKIT2Q0698451B51R',
        'GPKIT6Q0121407D',
        'GPKITPALHETASTIGUAN',
        'GPKIT2X11383758161NN',
        'GPKIT4X032109611N',
        'GPKIT032103601AA',
        'GPKIT2X5Z088167182V',
        'GPKIT2X6Q0411314P',
        'GPKIT2X2QB513049E',
        'GPKIT2GP805911A12A',
        'GPKIT2X2H0412319',
        'GPKIT2X5U5827489B',
        'GPKIT4X101905601C',
        'GPKIT2X2QB513049K',
        'GPKIT8120S98120AL',
        'GPKIT543029KE0B',
        'GPKIT3XG052164R1',
        'GPKIT3XWHT004923B',
        'GPKIT6Q6839477C5AP78',
        'GPKIT2X305721559A',
        'GPKIT2X377412375',
        'GPKIT2G0941661C62',
        'GPKIT51959683',
        'GPKIT5U0853665H9B966',
        'GPKIT2X1J0412359',
        'GPKIT14XBRPRT20087',
        'GPKIT2X5Z0805757B',
        'GPKIT3X12120040551',
        'GPKIT2H0853675F2ZZ2H6853948DIDJ',
        'GPKIT2X2Q0886249D',
        'GPKIT3X51898494',
        'GPKITSUPORTESTRASVIRTUS',
        'GPKIT2X6340A3',
        'GPKIT5XWHT005263',
        'GPKIT2XAPS598625',
        'GPKIT2X1S0615301L',
        'GPKIT1SB80593132',
        'GPKIT4X032109601P',
        'GPKIT2X5W3831721A1NN',
        'GPKIT2X21194523112119452851',
        'GPKITPALHETASTIGUANADITIVO',
        'GPKIT10018451618',
        'GPKIT5X373867646',
        'GPKIT2X8660089543',
        'GPKIT2X211611483',
        'GPKIT2X2QB513049T',
        'GPKIT2GP853665A9B9',
        'GPKIT032115611NADD',
        'GPKIT2X51843831',
        'GPKIT2AMORTECEDORES543023522R',
        'GPKIT10XLIT04145170',
        'GPKIT4X219539',
        'GPKIT2X2178073351',
        'GPKIT2XWHT004739SUP',
        'GPKIT2X5Z6827550B',
        'GPKIT5Z0821141A42',
        'GPKIT032121110C',
        'GPKIT2X032121119J',
        'GPKIT2X5U0513025F',
        'GPKIT1X51836085108',
        'GPKIT377201201D5Z0201231A',
        'GPKIT4X1027112331',
        'GPKIT3X7S6G12405AA',
        'GPKIT2X1K0505465AA',
        'GPKIT6QD80718384',
        'GPKIT2GP941005E06E',
        'GPKIT5U7860145AKN246',
        'GPKIT6XBRPRT20079',
        'GPKIT10XN90788402',
        'GPKIT5U0941007D08',
        'GPKIT5U78642039B9',
        'GPKIT5U0807239B40',
        'GPKIT5XN90813202',
        'GPKIT5U7853752BAJ51',
        'GPKIT2X2H0411313D',
        'GPKIT4X5U0601147R8Z8',
        'GPKIT2XJZZ513025F',
        'GPKIT7422L6L7',
        'GPKIT2Q0825236G',
        'GPKITSUPORTESTRASVIRTUSB',
        'GPKIT2GP807049B',
        'GPKIT1K0805965J9B9',
        'GPKITCORREIASEA211C',
        'GPKIT4VELAS1885510060',
        'GPKIT12XBRPRT20087',
        'GPKIT4X2195541109AL',
        'GPKIT4XN90698606',
        'GPKIT10X036103384G',
        'GPKIT8120S8S98120AL',
        'GPKIT4X5U0601147BYSW',
        'GPKIT4X5U0819707ST3',
        'GPKITJZW998003DADITIVO',
        'GPKIT6XN90891601',
        'GPKIT4X98550378',
        'GPKIT10XN90974701',
        'GPKIT2GP853665B9B966',
        'GPKIT5U0035849C',
        'GPKIT643983643230',
        'GPKIT377199381E82E',
        'GPKIT2X6QE121367',
        'GPKIT2X37388568371N',
        'GPKIT15XJZZ109119',
        'GPKIT2X5Z9827550A',
        'GPKIT5U7809957A58A',
        'GPKIT3X04E905110QVELAS',
        'GPKIT2X2QB413031K',
        'GPKIT2AMORTECEDORES543021348R',
        'GPKIT4XZBA803583',
        'GPKITFILTROSAMAROKV6',
        'GPKIT16XN10748001',
        'GPKIT2GP807393A94A',
        'GPKIT2XWHT007697',
        'GPKIT10X6N0867276',
        'GPKITREV12PURETECHD',
        'GPKIT12XG052164R1',
        'GPKIT24X8660089842',
        'GPKIT5U0035849CB',
        'GPKIT2X6QE121367A',
        'GPKIT5X219539',
        'GPKIT2XJZZ513025B',
        'GPKIT2X6C0412311',
        'GPKIT2X5U0881275',
        'GPKIT3XGJZZ250R2BRAB',
        'GPKIT3VELAS04E905612C',
        'GPKIT2XBL0073',
        'GPKIT2X377823499AB',
        'GPKITREV12PURETECHC',
        'GPKIT4X6Y0839181',
        'GPKIT5XG052164R1',
        'GPKIT5U0805583D1NN',
        'GPKIT5XN90335004',
        'GPKIT2X2119552151',
        'GPKIT1SB94510506',
        'GPKIT5U0805583E9B984',
        'GPKIT6EA9980022G59554279B9',
        'GPKIT110440110438',
        'GPKIT4X1J0711280C',
        'GPKIT2X1SB863447A041',
        'GPKIT852203BA0B',
        'GPKIT5Z0407255BA56',
        'GPKITQUEBRASOLPALIO',
        'GPKIT2X5QX615601D',
        'GPKIT2GM853273A9B974',
        'GPKIT4X030107411L',
        'GPKIT2X543026080R',
        'GPKIT100240044',
        'GPKIT2X2119452311',
        'GPKIT3XN10441602',
        'GPKIT2X5U7413031D',
        'GPKIT2119531615625',
        '91KIT3WGENQQK',
        'GPKIT5Z0805323GRU24',
        'GPKIT5U7955453ADD',
        'GPKIT5W4837329A30A',
        'GPKIT6438AV643230',
        'GPKIT4X7H06011739B9',
        'GPKIT2X211501121',
        'GPKIT5U0805583J9B984',
        'GPKIT211411313141',
        'GPKIT6QE945105A06',
    ],
    Oleos: [
        '7090460',
        '7087629',
        'K68218920LA',
        'G052385Q0',
        '0888084546',
        '0888681206',
        'GJZZ502M2BRA',
        'K68231021LA',
        'BRPRT20107',
        '7079352',
        '7089740',
        'BXO5W20CA',
        '7081654',
        'G052527R2',
        'K68218057GD',
        'GJZZ107M2BRA',
        'CL0530MOB2',
        'GJZZ530R2BRA',
        '7089420',
        '0888084556',
        'GS55553R2BRA',
        '7082460',
        'G052385Q1',
        'G004000M2',
        '1SO18BR000',
        'GPKIT4XBXO5W30GA',
        '8660007081',
        'K68444159LA',
        'G055512A2',
        'K68218655GB',
        '0888681017',
        'BRPRT20040',
        '0888581100',
        '3420355',
        '0888681915',
        '111100314R',
        'GPKIT10XBXO5W30AA',
        '7088486',
        'CL0540MOB',
        '7083938',
        '45500905',
        'BRPRT20079',
        '7702266430',
        '7702266469',
        'MT570',
        '0888084536',
        '1SO18BR001',
        '7090756',
        '0888085286',
        '0888084530',
        'K68218057AC',
        '0888581400',
        'K68218925LA',
        '7702267005',
        '150107151R',
        '030115611P',
        'K68218951LA',
        '7092612',
        '111401253R',
        '0888083355',
        '030103485AA',
        'BRPRT20099',
        'K68547228LA',
        '0888581206',
        '55200024',
        'K68218057LB',
        '1SO18BR002',
        'K68231014LA',
        '0888083450',
        'BRPRT20105',
        '51820784',
        '8660089614',
        '99N88MB000VERDE',
        '7092615',
        '0888680807',
        'CL0530MOB1',
        '52141158',
        'BXO5W30GA',
        'GPKITTROCAOLEOTORODIESEL',
        'GPKIT7XBRPRT20087',
        'BRPRT20041',
        'BH1T75W90AA',
        'CL0W20MOB',
        'BXO19A509B',
        '150000205R',
        '7702266423',
        '46343226',
        '8660089620',
        'GJZZ250R2BRA',
        '7084018',
        '0309190811',
        'LBRW004808',
        'GPKIT5XK68231014LA',
        'E3BG6675BA',
        '0888980091',
        '55212608',
        '1060A217',
        '52105420',
        '8200313628',
        '1SO18BR003',
        'G052182A2',
        '1SO18BR006',
        '8660089616',
        'K04549626LA',
        '1643067780',
        '7090105',
        '214506956R',
        'MTSW144',
        '46337579',
        'GPKIT5X8660089534',
        'GPKITTROCAOLEOEA11116C',
        '7711172381',
        'ISA556',
        '7702267214',
        '8660089691',
        '032115251D',
        '237964624R',
        'K04549625LA',
        '7092614',
        '1608515380',
        'G12E040R2',
        '5U0422371A',
        '8200795337',
        'GPKIT4XGJZZ502M2BRA',
        '030115611AA',
        '7088239',
        'GJZZ020C2BRA',
        'GPKIT7XK68218057LB',
        'GPKIT8XK68231014LA',
        '0888581060',
        '150004776R',
        '46344925',
        'G060726A2',
        'BRPRT20090',
        'K68231015LA',
        '150002040R',
        'BXO5W30AA',
        '0888581080',
        'G055540A2',
        '111458860R',
        'GS55545M2BRA',
        '03L115105F',
        '06A103663C',
        'GPKITTROCAOLEOCVTCOROLLA20',
        '7092803',
        'GPKITREVM4RBASE',
        'K68218655LA',
        '030103485AC',
        '0888083656',
        '8200741642',
        'BK2Q6600CA',
        'BRPRT20091',
        '7090112',
        'CL1040MOB',
        '150508107R',
        'GPKIT4XGS55553R2BRA',
        '7088596',
        '111458247R',
        '31439720',
        '1220A071',
        '110438',
        '7089720',
        'TOTALHBF4',
        '032115611N',
        '93231111',
        '8660089534',
        'GPKIT5XK68444159LA',
        '150502247R',
        '7701067122',
        '8200335007',
        'GPKITREVISAOCOMPLETOEA111162',
        'GPKIT5XK68218920LA',
        '98550134',
        'GPKITREV204152YZZD6',
        '2S653R700BF',
        'BRPRT20097',
        'K68218890LA',
        '059115630BF',
        '23796ET00A',
        'GPKIT2X0888681915',
        '219616',
        '55266797',
        '208364',
        '111404541R',
        'GPKIT6XK68547228LA',
        'GPKITREVISAO1K4M',
        'K68218057GE',
        '2142103001',
        '7092616',
        'GPKIT7X0888680807',
        '3399337',
        '0888681926',
        'BJ1A0W20AA',
        'TOTAL5W30',
        '04C115611F',
        '2330025922',
        '7092613',
        '3254223711',
        '52196824',
        '51977753',
        '111119774R',
        'GPKITREVTCE13',
        'GPKITOLEOFILTRO1520865F0E',
        'GPKITREVISAO1B4DKWID2',
        'GPKITREVISAOFRONTIER25',
        'GJZZ530Q2BRA',
        '2920A221',
        '111510942R',
        'GPKIT5XBRPRT20099',
        '8660007080',
        '8200671272',
        '264102G000',
        '216063JX2B',
        '1636508080',
        '55223623',
        'GK2Z6600C',
        'GPKITREV290915YZZM3',
        '0882380021BR',
        '7079356',
        '46349499',
        '46336064',
        '059117070J',
        '8201005241',
        '8200397125',
        '150A06727R',
        'BRPRT20087',
        '1BP31601AA',
        '93231110',
        '55283219',
        '8200671285',
        '7717003712',
        'GPKIT9XBXO5W30AA',
        'GPKITREV190915YZZM3',
        'GPKITTROCAOLEOTOROFLEX3',
        '8200255423',
        '2651038000',
        'MB1A6600AA',
        '55196505',
        '216061XS0A',
        'GPKITREVISAO1K7M',
        '7711943755',
        '7084019',
        'GPKITTROCAOLEOCVTYARIS',
        '110440',
        '46345895',
        '46336536',
        'BRPRT20103',
        'GC3Z9278B',
        '31439721',
        '111408024R',
        '5W30P',
        '06K103600AA',
        '55273759',
        'G1FT035',
        '8200251904',
        '55247746',
        'GPKITREVISAO1B4DSANDEROLOGAN',
        'GPKIT3X0888681915',
        '93344704',
        'GPKITREVISAO1B4DSL3LITROS',
        '7700873354',
        'GPKIT5X0888083399',
        '55281269',
        '98550378',
        '04E103485B',
        'GPKIT5X8660089842',
        '264104E400',
        'GPKITREVISAO1M9T',
        'GPKIT4XGJZZ530R2BRA',
        'GPKIT4X7092615',
        '059115103CM',
        '0888083399',
        '11417690442',
        'GPKIT8XK68218925LA',
        'GPKIT9XK68218057LB',
        '2447025052',
        '46345894',
        '9675477380',
        'GPKITREVISAO1H4M',
        'GPKITREVISAO1H4M2',
        '98553886',
        'GPKIT6XMTSW144',
        'GPKIT3XK68218950LA',
        '130419589R',
        'K68218950LA',
        'GPKIT5XGJZZ502M2BRA',
        'GPKIT4XK68218950LA',
        'GPKITREVISAO1M4R',
        'GPKIT6XG052182A2',
        'GPKITREV104152YZZD6',
        'GPKIT036115636D030115611P',
        '43608905',
        'GPKIT4X0888083399',
        '46346283',
        '1BP31603AA',
        'GPKIT8X0888084536',
        'GPKIT5XGJZZ530R2BRA',
        'GPKIT8XGS55545M2BRA',
        'EB3G7H255DA',
        'K68127987AK',
        'GPKITTROCAOLEOEA11116B',
        'GPKITREVISAO1B4DKWID',
        'GPKIT4XGJZZ530Q2BRA',
        '308514690R',
        '46343224',
        '53359619',
        'GPKIT4XGJZZ250R2BRA',
        '83222355599',
        'G060175A2',
        'GPKIT5X8660089620',
        '1BP31567AA',
        'GPKIT4633757955202374',
        '924408308R',
        '8660007079',
        '2H6317019',
        '059103602BD',
        '1240A100',
        'GPKIT4X0888084536',
        '55279645',
        '7717003661',
        'GPKITREV304152YZZD6',
        '1636508280',
        'BJZZ700Q2',
        '46404094',
        '8660089901',
        '24586232',
        'K68218041LA',
        '321079831R',
        '0C3315105A',
        '1BP31602AA',
        '111103BS0A',
        'GPKIT5XMTSW144',
        'GPKIT5X0888084536',
        '8660089928',
        '1226122130',
        'GPKIT7X0888083399',
        '55223582',
        'E3BG6600DC',
        '55247075',
        '7084840',
        'GPKIT4XGS55545M2BRA',
        '7094388',
        '7083264',
        'GPKITTROCAOLEOT270',
        '030115611AB',
        '8200639421',
        '036919081D',
        '7083280',
        '04E115611AM',
        '94728699',
        '93293030',
        'GPKIT030115105S032103161K',
        '24578508',
        'XS2Z9278CA',
        'GPKIT9X8660089614',
        '55268185',
        '059117015K',
        '036115251AJ',
        '215104A401',
        '08234P99A1ND1',
        'GPKITTROCAOLEODSG6',
        'GPKITREVISAO1F4R',
        'FCSD3890750004',
        'GJZZ040R3',
        '83512355290',
        '820733R',
        '150104LC1A',
        'GPKIT5XGS55553R2BRA',
        'GPKITTROCAOLEOEA11116E',
        'GPKITTROCAOLEOHYUNDAI10B',
        '0450000170',
        '55510150',
        '1501000QAR',
        '111107817R',
        '030115636D',
        'CL1540MOB',
        'G055529A2',
        '4311928070',
        'GPKIT7X0888681926',
        '8200834449',
        '223214864R',
        'BRPRT20151',
        'GPKITREV404152YZZD6',
        'GPKITTROCAOLEOEA211TSI10',
        'B000750Q2',
        'CD120054',
        '7092802',
        'GPKIT2XG12E040R2',
        '030115105S',
        'GPKITTROCAOLEOHYUNDAI16B',
        'GPKIT3X0888083399',
        '7094387',
        '46346172',
        '26800042',
        'GPKIT4XBRPRT20103',
        '0888086116',
        '150A17037R',
        '111101KA1A',
        'GPKIT7X0888084536',
        '46350298',
        '059103161AT',
        '8200678386',
        '07K115610A',
        'GPKIT6XBRPRT20087',
        'GPKIT4X1BP31567AA',
        '8200046612',
        '038103157C',
        '06B115611R',
        'GPKIT3XGJZZ250R2BRA',
        'GPKITTROCAOLEOEA11116',
        'GPKIT3X0888680807',
        'GPKIT4XBRPRT20097',
        '52273000',
        '7082459',
        'B000750M1',
        '8660089749',
        'GPKITTROCAOLEOEA888GEN2',
        'GPKIT10XK68218925LA',
        '5801630224',
        'GPKIT4X7094388',
        '06J115105AG',
        '98553888',
        '460915JW0A',
        '8660089536',
        '50858024001',
        '06A919081J',
        '7091279',
        '1131C5',
        'K68322925LA',
        '9031147038',
        'K68439090AA',
        'GPKIT2XG052527R2',
        'FB3Q6675AB',
        '93295145',
        '55231133',
        'GPKITREVISAOFRONTIER23',
        '2132142021',
        '98550549',
        '55206749',
        'GPKIT7XBRPRT20097',
        'GPKITMOTULEA888GEN2',
        'OL001',
        'GPKIT7XGS55545M2BRA',
        'GPKIT4X030103157E',
        'GPKITTROCAOLEOEA211BUJAO',
        'GPKITMOTULEA888GEN3',
        'BH5ADOT4AA500',
        '98550168',
        '1BP31570AA',
        '575101S000',
        'GPKIT8XBRPRT20087',
        '16993411',
        '24580288',
        '7701473505',
        '08232P99K1ND3',
        'GPKIT3X7092615',
        '98552923',
        'GPKIT5XGS55545M2BRA',
        '43604905',
        '98MM6600D7B',
        '98550159',
        'BXO10W40AA',
        'BB3Q6B624EA',
        '0K08133066B',
        '7700103946',
        '8200141457',
        'GPKIT6XGS55553R2BRA',
        'GPKIT4XMTSW144',
        'GPKIT4X219616',
        'GPKIT7XBRPRT20151',
        '5802177791',
        '1510037030',
        'GPKITMOTULEA211',
        'GPKITREVISAOFRONTIER23B',
        '93286309',
        'GPKIT7X1643067780',
        'GJZZ107R2BRA',
        '71778099',
        '115607698R',
        '15610RE2E01',
        '032115611P',
        'GPKITTROCAOLEOCOMPASSFLEX',
        '7086741',
        '93307462',
        'GPKIT6XGJZZ530R2BRA',
        '150003601R',
        'GPKITTROCAOLEOCOMPASSFLEXB',
        '0888083510',
        'BXO15W40FA',
        '036103085G',
        '03L115105B',
        'GPKIT3XGS55553R2BRA',
        'GPKITTROCAOLEOHYUNDAI10',
        '98550135',
        'GPKITTROCAOLEOAMAROKV6',
        '028919081J',
        'G052516A2',
        '1610725580',
        '93205290',
        '8200245034',
        '98551028',
        'GPKITMOTRIOROSA',
        '028117021L',
        '98550551',
        '04C115105E',
        'K68218925GB',
        'GPKIT9XBRPRT20097',
        'GPKITTROCAOLEOEA21116',
        '46343225',
        '1253141920',
        '8200368373',
        '08200HCF2',
        'GPKIT3X0888681926',
        '55270192',
        '111459423R',
        '2330025230',
        '1J0907660F',
        'GPKITTROCAOLEOEA888GEN3',
        'GPKITREVISAO1D4D',
        'GPKITTROCAOLEOEA11116D',
        '06C103485N',
        'G052145S2',
        '111401435R',
        '113200L040',
        'GPKITTROCAOLEOEA111CBUJAO',
        'GPKIT7XK68218057GE',
        '176720',
        '04E115105M',
        '98550553',
        'GPKIT4X8660089536',
        '036998076',
        'GPKIT2XGS55553R2BRA',
        '111404310R',
        '1132067010',
        'GPKITTROCAOLEOEA211',
        'GPKITMOTULEA211V2',
        '94724344',
        '8660089842',
        'GPKIT2XGJZZ502M2BRA',
        '50851024001',
        '130218357R',
        '1240A055',
        '90409617',
        '08233LUB020',
        '25188650',
        'GPKIT5XG052167A4NOEA888GEN2',
        '98550552',
        '7701062221',
        'GPKITTROCAOLEOEA211ECON',
        '6000630376',
        '04E117021K',
        '90793AB43400',
        '24591033',
        'GPKIT8XK68218925GB',
        '04E115105AH',
        '06K103485D',
        'GPKITOLEOFILTRO1520865F0EB',
        '032103601AA',
        '0C3906051B',
        '261104A701',
        '90467648',
        '150103110R',
        'GPKITTROCAOLEOAMAROKV6B',
        '31437022',
        '9807979380',
        '041115105',
        'GPKITTROCAOLEOEA888GEN2B',
        '1250A010',
        '93382730',
        '150104LC0A',
        '45502905',
        '111103HC0A',
        '7701036310',
        'GPKITTROCAOLEOEA21116B',
        '93302315',
        '04C103464G',
        '150560163R',
        'GPKIT5XGJZZ107M2BRA',
        '30450248',
        'GPKIT4XGJZZ107M2BRA',
        '31401947',
        'CN1Z6600B',
        'CM5G6600DC',
        '8200831629',
        '038919081K',
        '06J117070C',
        'GPKIT5X219554',
        'GPKIT8XBRPRT20097',
        '216064JZ0B',
        'GPKITTROCAOLEOEA211BUJAOANEL',
        'K68218057LA',
        'V861706980',
        '7700857225',
        '030103157E',
        '150411KT0A',
        '977751',
        'GPKITTROCAOLEOEA111163',
        '256155T0004',
        'GPKIT4XGJZZ107R2BRA',
        'GPKITREVISAO1F3R',
        '032103493P',
        '90793AB43500',
        'GPKIT12XG052167A4NO',
        '7717003714',
        'GPKIT06B115611R06A103663C',
        '2142133134',
        '13207CJ40A',
        'GPKIT12XGS55553R2BRA',
        'G052745A3',
        '3834522',
        '305611301B',
        '204030D',
        '7702266073',
        '25186240',
        '1K0121070BD',
        'BE8Z6675A',
        'GPKIT10XBRPRT20097',
        'GPKIT10XK68218925GB',
        '41098905',
        'GPKITTROCAOLEOEA888GEN3LS',
        'GPKIT25XLIT04145170',
        '8200555112',
        '24579148',
        '11145CK80B',
        'GPKITTROCAOLEO25JETTA',
        '61200062',
        'GPKITTROCAOLEOEA11116BLS',
        'GPKIT4XG052167A4NOFILTRO',
        'GPKIT4XGJZZ250R2BRAFILTRO2',
        '0K08133065A',
        '11428637812',
        '03L117021C',
        'GPKIT4X7092613',
        'GPKITTROCAOLEOEA21116ECON',
        'GPKITK68218920LAFILTRO',
        'GPKITTROCAOLEOEA888GEN2C',
        '1510028020',
        '03L115105C',
        '150100934R',
        '2H0317019',
        '03L103603M',
        '06H103600AA',
        '06L919081',
        '8200783518',
        '1BP31569AA',
        '415534E000',
        '111103404R',
        '04C115251K',
        'GPKITMOTULEA888GEN2V2',
        '2142125002',
        'B2618OLE025',
        '04E103474K',
        '7089563',
        '8200274074',
        '17217593856',
        '06H115105DF',
        '1BP31571AA',
        '261254A010',
        '213502B011',
        '32510349310',
        '31670242',
        'GPKIT5X55200024',
        '11427852375',
        '25610PRM013',
        '7700873355',
        '06H103138E',
        '150103AA1A',
        '261004A012',
        '15101RNAA00',
        '06L117070',
        'G055515A2',
        'GPKIT4X219554',
        '8200582009',
        '8200120216',
        '8201151808',
        'GPKIT3X04E103157K',
        'GPKIT6XGJZZ502M2BRA',
        '25204142',
        '150A09501R',
        '06D919081B',
        '7086221',
        'GPKITTROCAOLEOAP',
        'GPKITTROCAOLEO25JETTAB',
        '8200969622',
        'GPKIT4XGJZZ250R2BRAFILTRO',
        '219576',
        '11427852376',
        '032103493AA',
        '7700874078',
        'GPKITTROCAOLEOHYUNDAI16',
        'GPKIT219616DIESEL',
        'GPKITMOTULEA888GEN3V2',
        '264324A700',
        '111458280R',
        '8200015498',
        'GPKIT14XBRPRT20087',
        '06A103483D',
        '51891388',
        '219554',
        'GPKITTROCAOLEOEA11116G',
        'GPKIT219615DIESEL',
        '0K08133067A',
        '321093769R',
        'GPKITREVISAO1M9TB',
        'G060190A2',
        '04E103729',
        '31492531',
        'GPKITREVISAO1G9U',
        'GPKIT032115611NADD',
        'GPKIT10XLIT04145170',
        '8200535857',
        'GPKIT4X219539',
        '432520W000',
        '2920A070',
        'CM5Z6675D',
        '8200642783',
        '8200518233',
        '150007220R',
        '06B103623P',
        '7700869464',
        '04C115251G',
        '8200760467',
        'GPKITTROCAOLEOAMAROKV6C',
        '17217645692',
        '04E103157K',
        '03L198070',
        '11417823009',
        '8200800258',
        '912055T0004',
        '219539',
        '243603CAA2',
        '11537577014',
        '111400957R',
        'MN163743',
        '06D103634E',
        '7092920',
        '04E109423F',
        '111103BR0A',
        '118292508R',
        '8201705104',
        '62800047',
        '036103493BK',
        'XW4Z6600DA',
        'BRPRT20081',
        '0BU301125',
        '215004Z400',
        '213055917R',
        '2151025001',
        '8200280347',
        '059115103BS',
        '24148642575',
        '030103493CD',
        '91208PPP003',
        'GPKIT12XBRPRT20087',
        'GPKITTROCAOLEOEA111CBUJAOD',
        'GPKIT032115611P',
        'K68304252AC',
        '03L145735F',
        '90793AB43200',
        'N0160276',
        '9831294580',
        '7090244',
        '71749189',
        '213056765R',
        'GPKIT4X98550378',
        '8200613201',
        '1366791',
        '11537577015',
        '2213242850',
        '31274049',
        '118263662R',
        '213059651R',
        'GPKITREVISAO1G9U9L',
        '90009PY3000',
        '7634888',
        'K05047370AC',
        'GPKIT24X8660089842',
        '986833',
        'GPKIT5X219539',
        '115605M300',
        '7088237',
        '3349352030',
        'GPKIT3XGJZZ250R2BRAB',
        '30637339',
        '06A103601BC',
        '8200290716',
        'MR581104',
        '07K115611G',
        '0888581060AL',
        'GPKITREV200TSICOMPLETABTK',
        'K06513414AA',
        '3W0301323',
        'GPKIT7XG052182A2',
        '150A03157R',
        '0B5321473',
        '8200452397',
        '03L907660C',
        'GPKITREVISAOFRONTIER23D',
        '21606EB40A',
        '8201698698',
        '31368636',
        '04C103085B',
        'GPKITTROCAOLEOCOMPASSFLEXBB',
        '030115112B',
        'GS60577M2BRA',
        'G060567A2',
        'G052172M2',
    ],
    Filtros: [
        '7701206928',
        '7098068',
        'GN159C676AC',
        'G2MJ9601HA',
        '51940646',
        'B319234H918',
        'GPKITREVFORDROCAM',
        '7088629',
        '52162685',
        '8660089514',
        'JZZ115561A',
        '1444TT',
        '50008507',
        '8200809315',
        '28113M4000',
        '1109AL',
        '8660089507',
        'GPKITREVFORDDRAGON',
        'KU2J9155AA',
        'K05184231AA',
        'AA5Z6714A',
        '9818914980',
        '9851412980',
        'JZZ129620D',
        '51854599',
        '8660089505',
        'CAPA3115',
        'JZZ129620B',
        'JZZ129620A',
        '8200386495',
        '51874053',
        'JZZ819653D',
        '2H6129620',
        '030129620L',
        '52046268',
        '8660089510',
        'GU2J9601BA',
        'JZZ819653',
        '8660089500',
        '52063810',
        '04C129620A',
        'JU2Z6731A',
        '8660089509',
        '46751179',
        'CAPA3114',
        '7093233',
        '9801366680',
        'G3MJ9155BA',
        '5Z0201511B',
        '53489525',
        '51977574',
        '8V5118D543AA',
        '2630002503',
        '175066082R',
        'CDPA0249',
        '144029',
        '233000D100',
        '2Q0819644',
        'K04861688AB',
        '5Q0129620B',
        '32257032',
        '06J115403Q',
        '7700274177',
        '7091886',
        '165467751R',
        '152085758R',
        '8200768927',
        '2H6129620A',
        '165007931R',
        'JZZ129620C',
        '2M5Z9155CA',
        'DG9Z19N619CA',
        '281131Y100',
        '8660089502',
        '71779555',
        '8660089515',
        '51842923',
        '7082301',
        '165467674R',
        '9809532380',
        '311123J500',
        '1444TF',
        '1680682480',
        '1275810',
        '2995811',
        '04152YZZD6',
        '8660089516',
        '04E115561H',
        '6QE201511C',
        '32140029',
        '1520865F0E',
        'BRPRT10422',
        '2H6129620C',
        '1SB201511',
        '71754237',
        'HU5Z9155B',
        '7701208613',
        'JS19B072BA',
        '2630035505',
        '71753740',
        '97133D1000',
        'GPKITTROCAOLEO13FIREFLY',
        '2H6127401C',
        '1312766080',
        '1K0201051K',
        '03H115562',
        '31370161',
        '8660089503',
        '11427953129',
        'GPKITTROCAOLEODSG7',
        'MZ691140',
        'K68082570AA',
        '9811909980',
        '1614111980',
        '51806073',
        '9835123980',
        '100188209',
        '1780121050',
        '77365902',
        '1F0129620',
        '32312226',
        'K05083285AA',
        '03L103493H',
        '971331S000',
        '90915YZZM3',
        '9809643880',
        'CD120013',
        '7083750',
        'CD150293',
        '52080841',
        '73500049',
        '1444VH',
        '165856598R',
        'AB399B072AD',
        '7091882',
        '152093920R',
        '7701479124',
        'GK2Z9365A',
        '9827965880',
        '281132H000',
        'CDPA0250',
        '319102H000',
        '1567E1',
        'GPKITTROCAOLEOETORQ',
        '51898497',
        '77366065',
        '263304A001',
        '7701207667',
        '7702295178',
        '1567E0',
        '8200768913',
        'K04892339AB',
        '177050Y060',
        '310902E000',
        '46796194',
        '319112E000',
        '311121W000',
        '52175780',
        '9676133480',
        '319801S000',
        '1K0201051L',
        '77366607',
        '5X0201801',
        '165784131R',
        '165543RC2A',
        '17801F0030',
        '8201102931',
        '51898494',
        '317281XF02',
        '87139YZZ75',
        '55281160',
        '7091875',
        '51857959',
        '13508023',
        '7700300478',
        '7091878',
        'CD130103',
        '263203CAA0',
        '263203A100',
        '31465948',
        '97133F2000N',
        '6000631700',
        '7091266',
        '04C129620D',
        '319112K700N',
        '90915TA005',
        '8201676037',
        '165465171R',
        '172765133R',
        '2321747010',
        '214680361R',
        '152082327R',
        'G92DH47070',
        '0BH325183B',
        '9829671380',
        'K04884899AC',
        '971332G000',
        '31370089',
        '30630752',
        'CA550043',
        'CDPA0248',
        '46347605',
        '100204596',
        '17801AZA08',
        '0AW301516H',
        '51854598',
        'JZZ819653C',
        '31407748',
        '272773JC1C',
        '29400355',
        '5Z0129607J',
        'JU2J6714AA',
        '28113A9100',
        '1606402680',
        '3517857',
        'JX6Z9601A',
        '317283JX0C',
        '06A115561B',
        'G3MJ6714AA',
        '178010C010',
        '52220125',
        '281133K200',
        '16400ZT50A',
        '32242190',
        '1520800QAG',
        '90915YZZD2',
        '51857956',
        '7091879',
        '165461HC1A',
        '53119599',
        'BRPRT10429',
        '97133B2000',
        '06L115562B',
        'J7BZ9601B',
        '311122P000',
        'MR398288',
        '8660089513',
        '281131S100',
        '3172850X0A',
        '2330021010',
        '232170Y050',
        '281132K000',
        '8660089501',
        '281132W100',
        '8200257642',
        'J7BZ9601A',
        'CD150291',
        '272778970R',
        '6373199A',
        '16400EC00D',
        '87139YZZ74',
        '8200505566',
        '7086604',
        '164009963R',
        '6380580A',
        'CN159601AB',
        '7092236',
        '030115561AR',
        'GPKITTROCAOLEOEA21116COMB',
        '317263JX0A',
        '152089F60A',
        '16400EC00A',
        '28113R1200',
        '51898495',
        '23300F2030',
        '7091876',
        '2H0127401B',
        'B319224H900',
        '319224H900N',
        '19347728',
        '2H0127401G',
        '15208BN30A',
        '77367623',
        '3172628X0A',
        '04E115561AC',
        'AV6N19G244AA',
        '2H0127401D',
        '27891EL00A',
        '2330075140',
        '030129620K',
        '1365070080',
        '46796687',
        '165467753R',
        '50055447',
        '971332K000N',
        '7087808',
        '971332B010',
        '1770A253',
        'CA130172',
        '1640000Q1E',
        '149504085R',
        '8200656780',
        '353300W090',
        '100197684',
        '31112A70A0',
        '263203C100',
        '165008632R',
        '55283511',
        '311123R000',
        '1674213480',
        '1780121060',
        '8854802170',
        '3533012090',
        '13717678281',
        '77368658',
        '28113D3300',
        'AB399601AD',
        '2C5Z9155BC',
        '3533060050',
        '9674922980',
        '165002569R',
        '1674210980',
        '164004350R',
        '51910095',
        '149502908R',
        '29400397',
        '175714998R',
        '272767139R',
        '036129689B',
        '871390K080',
        '353300W021',
        '8200420871',
        'BK319601AD',
        '11428409567',
        '55273753',
        '1640000Q2M',
        '8200971790',
        '8200858600',
        '1420P5',
        '11428562889',
        '55208286',
        '7702442110',
        '9091510009',
        '319222J000',
        '311121R000',
        '165466LA0A',
        '319112G000',
        '51857965',
        'GPKITTROCAOLEO10FIREFLYB',
        '8660003780',
        '93316245',
        '3533012040',
        '11427673541',
        '5Q0819669',
        '1476508400',
        '1520900Q0H',
        'GPKITTROCAOLEO13FIREFLYB',
        '165289KA0A',
        'G92DH47060',
        'GPKIT4X036129689B',
        'CD150292',
        '1444VK',
        '2330030450',
        'GPKITTROCAOLEOETORQ2',
        '1780131120',
        '52041908',
        'JS16744AA',
        '17801AZG02',
        '52057535',
        '71749828',
        '319114D500',
        '6Q0201511',
        '1640501T0A',
        '51977028',
        '165465RA0A',
        '971332L000',
        'K68102241AA',
        '32140027',
        '165265RA0A',
        '29400042',
        '7088847',
        '3533028010',
        '233900L070',
        '100251423',
        '13718529998',
        'EB3Z9365B',
        '1640000Q3K',
        '13328591019',
        '52100212',
        'G3MJ9601BA',
        'G92DH12050',
        '16127451424',
        '165005RA0B',
        '165003RC2B',
        '281132S000',
        'DS7Z9601D',
        '32242188',
        '4632139010',
        '9091510004',
        'GPKITREM4RCOMFILTRO',
        '52079874',
        '16403JR00B',
        '50015864',
        'CARE050501',
        '319254E100',
        '52056287',
        '6379899A',
        '4632123000',
        '1780130040',
        'CD110661',
        '175068840R',
        '90915YZZD4',
        '55231516',
        'K52108325AA',
        '31404958',
        '272775NA0A',
        '155253599',
        '31368022',
        '319222B900',
        'K68469692AA',
        'BRPRT10417',
        '25171377',
        '7091881',
        '317263JX0AP',
        '1399873080',
        '04E129620',
        '3533012050',
        '9091530002',
        '3197344001',
        '7088140',
        '233900L050',
        'K68229402AA',
        '2919108',
        '31497285',
        '976194E000',
        '2QB201801',
        '2339030180',
        '1345990080',
        '971332H001',
        'JZZ129620E',
        'CAPA2935',
        '960155834R',
        '263203C30A',
        '7701064237',
        '8200362442',
        '8200947663',
        '04E115561T',
        'CAPA3111',
        'B971332E210',
        '2330031120',
        'GPKITTROCAOLEO10FIREFLY',
        '1654600Q2E',
        '28113F2000',
        '1359644080',
        '17801AZG03',
        '31330050',
        '5Z0129620',
        '87139YZZ76',
        '2QB201293',
        '281134F000',
        '32146443',
        'BRPRT10420',
        '319223E10A',
        'BRPRT10795',
        '282104E510',
        '30757155',
        '8201219252',
        '16546EB70J',
        '8713952020',
        'CAPA2746',
        '281133E000',
        '272775JJ0B',
        'K04726067AA',
        'K68079744AD',
        'MZ690445',
        'GN159601AA',
        '7094709',
        '377129620',
        '030129607BG',
        '281132S000N',
        'MB6A9601AA',
        '5Z0201511A',
        '3533060030',
        '164039587R',
        'CD130001',
        '94733164',
        '32242191',
        '5U0129618',
        'BRPRT10421',
        '9804281880',
        '16010SAA000',
        '2925308C',
        '06D115562',
        '87139YZZ52',
        '165463TA1J',
        'K68039537AA',
        '059198405B',
        '25206377',
        'E3BZ9155B',
        '11428092620',
        '165464877R',
        '29400015',
        '32257123',
        '1780138011',
        'K68191349AC',
        'EB3Z9600F',
        '281133X000',
        'LU5Z9155A',
        '1635825080',
        '9802348680',
        'GPKITTROCAOLEOEA11116H',
        '8200371661',
        '971332B005',
        '30788490',
        '8200025862',
        '4632126000',
        '971333J100',
        '53490623',
        '7085110',
        '1780177050',
        '311123Q500',
        '95021102',
        '165466859R',
        '149503940R',
        '1610693780',
        '46343218',
        '6379301K',
        '7711228919',
        'FT4Z6731A',
        '8200431051',
        '80292TR8M01',
        '42403110',
        'GPKITREVFORDROCAM2',
        'CD110653',
        '8713947010',
        '8660089517',
        '03L115562',
        '319801Y700N',
        '1780131131',
        '7090440',
        '11427826799',
        '6R0820367',
        '100243873',
        '1103L8',
        '9713307010N',
        '28110R1100',
        '7702295871',
        '7094882',
        '9813908880',
        'K68002433AC',
        '12605565',
        '0261155613',
        '4M0819439B',
        'AB399176AC',
        '8713930040',
        '93312682',
        '52105417',
        '07K129620',
        '1K1819653B',
        '62700280',
        '281302S600',
        '32146444',
        '25206966',
        '281133S100',
        '319112K000',
        '6E5Z9601EA',
        '377819638',
        '19347808',
        '52160963',
        '3530352030',
        'JZW201511',
        '04E129620B',
        '317261XF00',
        'K68096453AA',
        '272773223R',
        '272773277R',
        '1J0201511A',
        '030198030',
        '1686484780',
        'G92DH42010',
        '1780131090',
        'DG9Z9601A',
        '19433934',
        '281102P200',
        '02E305051C',
        '1770A420',
        'WHT006896',
        '8660089508',
        '64116823725',
        '11427622446',
        '1103J7',
        '281131R100',
        '11428570590',
        '88905845',
        '24591518',
        'GPKITGJZZ107M2BRACOMFILTRO',
        '52175784',
        '281122S110',
        '53314055',
        '5Z0129684A',
        '272779698R',
        '90915YZZJ2',
        '11428575211',
        '8692305',
        '51973250',
        '55231652',
        '28113B2000',
        '64119382886',
        '281132P100',
        '1345984080',
        '25430R5L003',
        '281134E500',
        '46817415',
        'FS19601AA',
        '15410MFJD02',
        '80291TF3E01',
        '7711945897',
        '29400217',
        '152088127R',
        '5C0129620',
        '46416684',
        '16500ET00B',
        '80291T9DK01',
        '31449209',
        '29400356',
        'K04593914AB',
        'MR404847',
        '165469905R',
        '6Q0820367B',
        'K68535618AA',
        'GPKITTROCAOLEOFIRE5W30',
        '7091877',
        'K68037059AA',
        '51773404',
        '7E0819631',
        'BRPRT10419',
        'AB3919N619AA',
        '8856852010',
        'GPKITTROCAOLEOTORO24',
        '2321723010',
        '1643099780',
        '319112K700',
        '311112B000',
        '93260511',
        'K05013470AE',
        '7082141',
        '8200820860',
        'K68257030AA',
        '263203CKB0',
        '3191109000',
        '93319842',
        '7091880',
        '28113M4000AS',
        '52102242',
        '25FL5949',
        'CD110654',
        '26241944',
        '1780125020',
        '16900TJ0M51',
        'CAPA3118',
        '0415231090',
        '31112C9000',
        '11427854445',
        '25FL5548',
        '46723245',
        'AV619601AE',
        '31274952',
        '314532W100',
        '7090323',
        'CDPA0516',
        '97133B2000N',
        '1BP31616AA',
        '97133D3000N',
        '51784591',
        'JZZ819653B',
        '13718513944',
        '272889739R',
        '16900SNZM51',
        '971332F010N',
        '46420988',
        '7722936',
        '2824A007',
        '28113N9000',
        'GPKITTROCAOLEOEA211TSI2B',
        '90351570',
        '84390002',
        '17048SNA010',
        '25FL4722',
        '233000Y010',
        '319113E200',
        'GPKIT5U01296185Z0129684A',
        '19347719',
        '97133G8000',
        '281133S800',
        '64119237555',
        '1BP31382AA',
        '6379677A',
        'K68073648AA',
        '281133E500',
        'JZW819653E',
        '64316835405',
        '319254E110',
        'K68079744AC',
        '64319194098',
        '97133C1000',
        '3533071010',
        '0K71E23570A',
        '31112C9100',
        '278913NL0A',
        'BK2118D543AA',
        '1654600QAU',
        '079198405D',
        '30750013',
        '97133A9000',
        '971332H001N',
        '281133J100',
        '06L115408D',
        '7711228912',
        '11428585235',
        '272775081R',
        '27274EB70A',
        '11427848321',
        'ZBA261275G',
        '51973251',
        '281132F800',
        'EM5G6714AA',
        '1500A286',
        '46544820',
        'K1AML00006A',
        '13717577457',
        '13718577170',
        '177000L390',
        '19417555',
        '93373161',
        '165283BR0A',
        '8200848492',
        '1654600Q1J',
        '8201153808',
        '11427105320',
        '165464820R',
        '7S7Z6B856B',
        '1643096080',
        'K68157291AA',
        '059115446E',
        '1BP31304AA',
        '2H6127401G',
        'JZW819653G',
        '31497284',
        '13717638566',
        'B281132S000',
        '5Z0129684B',
        '97133R0000',
        'CD110387',
        '53146541',
        'BRPRT10688',
        '8660089634',
        'GPKITTROCAOLEOFIRE5W40',
        '165001258R',
        '7803A028',
        '8200947664',
        '13328584874',
        '281303E710',
        '11427583220',
        '5S659155AA',
        '52220937',
        '1K0129620D',
        '19350512',
        '1629036580',
        '2171296201',
        'K68260792AB',
        '30748212',
        '7701069212',
        '30745344',
        '272778557R',
        '7H0819631',
        '51724833',
        '233900L100',
        '311122B000',
        'JZW819653B',
        '25450P4V013',
        '14215282',
        '7701065774',
        '263203N000',
        '13728561572',
        '31453H5000',
        '17211MKPJ00',
        '971332E910',
        '263203CAA0N',
        '177000L111',
        'CD110656',
        'JZZ129620',
        '7701206705',
        'GPKITTROCAOLEOFIRE5W30BP',
        '31338685',
        '11427953125',
        '172205AAA00',
        '4H0819439',
        '62700296',
        '272773HU0B',
        '6000633307',
        '13718518111',
        '52208163',
        'JZZ115561',
        '46723435',
        '64319312318',
        '25098459',
        'BG1T9155CA',
        'G3MJ9155CA',
        '1S0820367',
        '165761HK0A',
        '15400RTA003',
        '7711945906',
        'GPKITTROCAOLEOETORQ4',
        '93362610',
        '12636838',
        'CAPA3112',
        '13270886',
        '1BP31612AA',
        '4F0201511D',
        '0K6B023603AS',
        '04E129618Q',
        '96950990',
        '1H0819644B',
        '1722051BH00',
        '9803867580',
        '90535131',
        '29400412',
        '46808398',
        '03C115721A',
        '31407747',
        '13717534825',
        '6Q0201051J',
        '52101541',
        '0K6B023603',
        '178010Y040',
        '0GC325183A',
        '17211MJWJ00',
        '971333SAA0N',
        '8660089512',
        '96815102',
        '3516852050',
        '1780137021',
        '0K60C23570',
        'K05058693AA',
        '55199862',
        'K68301863AA',
        '29400390',
        'BG1T9W365AA',
        '8W0129617A',
        'GPKITFILTRODSG7',
        '13718580428',
        '16940T14M00',
        '4S0201511',
        '1303389100',
        '64115A29D99',
        '2811307100',
        '31112C1101',
        '97133R0500',
        '976193E010',
        '97133C5000N',
        '971332E210N',
        '7700112686',
        '03L115433C',
        '8713902150',
        '97133F2000',
        'GPKITFILTROSEA111',
        '1109CL',
        '97KB9601BA',
        '165765JL0A',
        'GPKITTROCAOLEODQ500',
        '13367308',
        '51836363',
        '1K0819644B',
        '3M519B072CH',
        '2H0129620A',
        '0B5325330A',
        '1567C8',
        '19347742',
        'GPKIT02E305051C',
        '2630035503',
        '64312284828',
        '19347721',
        '263003CAB1',
        'JZZ201511A',
        '1635830180',
        '94757663',
        '4M0133843G',
        '13271191',
        '64319313519',
        '55196902',
        '51979427',
        '17211KWTA20',
        '8W0133843D',
        '2M5Z9155AB',
        '1BP31614AA',
        'GPKIT3X7092615FIRE',
        'GPKITGJZZ107M2BRACOMFILTRO2',
        '71754149',
        'CDPA0254',
        '2811308000',
        '11427508969',
        '971332W000',
        '4G0133843',
        '226755893R',
        '11428593186',
        '23323508',
        '6E5Z9601GA',
        '7702295409',
        'AM559155AB',
        '31342920',
        '13718507320',
        'GPKIT4X2195549818914980',
        '30780376',
        'DG9Z19N619AA',
        '8K0133843M',
        '98FU9155AA',
        '90411732',
        '7700274106',
        '64119272642',
        '8E0819439',
        '13718577171',
        '144084',
        '93281612',
        '19350509',
        '1440E0',
        '1780122020',
        '64115A1BDB6',
        'GPKITOLEO06A115561B',
        '03C115561H',
        '1BP31611AA',
        '7700860823',
        '165464JM1A',
        '5U0201827',
        '282102E100',
        '8200820859',
        '172205WAH00',
        '2H0131563G',
        '28113G6000',
        '165465JL0A',
        'GPKIT4XGJZZ530Q2BRAFILTRO',
        'GPKITTROCAOLEOEA113',
        '5M3518D543AB',
        'GPKITREVFORD3',
        '13717582908',
        '8200521692',
        '17213K99A00',
        '17225R1NT00',
        '11425A33C43',
        '319114D600',
        '17220RNAY00',
        '2L2Z9601AC',
        '8R0129739A',
        '1K1819669',
        '17210MGSD30',
        '01J301517D',
        '88902619',
        '8201370532',
        'K68386779AA',
        '6R0819653',
        '31256837',
        '13717619267',
        '13717602643',
        '1770A260',
        '1BP31615AA',
        '2963308',
        '19347737',
        'GPKITTROCAOLEOETORQ3',
        '06L115678Q',
        '1114959900',
        '7701064235',
        '165001183R',
        '1K0129620G',
        '077115562',
        'BE8Z9601A',
        '165462604R',
        '28113F6200',
        '1722651BH00',
        '24253434',
        '52086684',
        '7701048886',
        'GPKIT10X77365902',
        '17220KSSJ30',
        '8660089504',
        '1312461000',
        '971333SAA0',
        '17210KZR600',
        'GPKIT5X06A115561B',
        '51879762',
        '30769018',
        '172205R0008',
        '7090329',
        '06A115405CC',
        'AB3Z9J280B',
        'K53034051AB',
        'JZZ201511B',
        '8W0129618F',
        '95905361',
        '281103X000',
        '1722055CE01',
        '15410KYJ902',
        '30680293',
        '07K115408',
        '17210K29900',
        '7701071327',
        '4S0819439',
        '90559549',
        '2H0959175',
        '04C129689C',
        '17220RZPG00',
        '13717532754',
        '032129620C',
        '13717601868',
        '17220R1AA01',
        '7701209837',
        '165465JL1A',
        'CD150294',
        'K04891916AA',
        '4H0129620M',
        '1606267680',
        '17048TEK000',
        '53327307',
        '178010L040',
        '420133844E',
        '64119248294',
        '4K0133844E',
        '17220K78B00',
        '7094559',
        '8W0133843',
        'K68079487AA',
        '1303389800',
        '17801AZG01',
        '314532D530',
        'CA550057',
        '17211KVSF00',
        '319234H900',
        '17210K53B00',
        'BG5X6731AA',
        'CAPA3119',
        '1BP31617AA',
        '17210MJED00',
        '178010Y020',
        '31339023',
        '13717713428',
        'GPKIT3X51898494',
        '17213KVKB30',
        '1301136300',
        '7701206592',
        '1643093180',
        '172024289R',
        '17225RNAA00',
        'K68079487AB',
        '1643100480',
        '314202S100',
        '17211K31900',
        '97133N9100',
        '263513C250',
        '31112L1000',
        '19347805',
        '7T4Z9601A',
        '13718509032',
        '1444SP',
        '164003953R',
        '17211KWGB00',
        '19350510',
        '16910KVKB22',
        'AE5Z19N619A',
        '17220KZZ900',
        '97133G6000',
        '8200492426',
        '17220PWAY00',
        '165463884R',
        'MD951626',
        '8200795348',
        '172206HLP00',
        '314532P000',
        '28113AT100',
        '5Q0201801',
        '2QB201511',
        'BG5T9W155AA',
        '06E115562H',
        '319112F000',
        '281124E500',
        '8200780972',
        '165283BS0A',
        'K55111302AA',
        'EB3Z9600J',
        'CAPA3120',
        '1087701600',
        '17220RRAA00',
        '2H0198308',
        'K68223044AA',
        '030129607CG',
        'K68535621AA',
        'G92DH47010',
        '93312933',
        '8200459854',
        '165001429R',
        'GPKIT4X2195541109AL',
        '17210MGMB10',
        '4K0133844D',
        '7700111834',
        '8200104272',
        '8200505567',
        '8200310867',
        '1K0129622D',
        '971819429B',
        '8200113817',
        '30862730',
        '11427807177',
        '11428507683',
        '322012685R',
        '25430PLR003',
        '7076396',
        '92196275',
        '281134E000',
        '11427721779',
        '17220PWA505',
        '06J115441A',
        '175066432R',
        '1520800QAC',
        '07C115562E',
        '1303390000',
        '28113H8100',
        'K04105409AC',
        'K04861756AA',
        '263502J000',
        '9807902380',
        '281132B000',
        'K04861688AA',
        '4K0133843G',
        '7701065985',
        '7700720978',
        'GPKITFILTROSAMAROKV6',
        '272775JJ0A',
        '1109AN',
        '11427634292',
        '7702453090',
        '7C3Z9601A',
        '7701064298',
        '7701033713',
        '17211MGZD00',
        '973043F000',
        '55594651',
        'K68492616AA',
        'GPKITREV12PURETECHD',
        '29400357',
        '272772183R',
        'GPKITTROCAOLEOEA11110CBTK',
        '8W0133843C',
        '1300280300',
        '263502M100',
        '52062431',
        '17210K97J00',
        '2H0129615A',
        '07K129607J',
        '172205BAA00',
        '165464JM1C',
        'K68033193AA',
        '282101M400',
        '13717850055',
        'GPKITREV12PURETECHC',
        '1780102050',
        '1505A195',
        '2H6127401E',
        '31454L1300',
        '7701071286',
        '17210K80C00',
        '17220RX0A00',
        '314532H500',
        'K52022378AB',
        'K68257791AA',
        '229960',
        '4M0127434H',
        '1L0129620A',
        '1J0129684CG',
        '9806561080',
        '13719468656',
        '28113L1000',
        '17210K97T00',
        '4G0133843K',
        'K52022378AA',
        '16910KWT305',
        '9859861880',
        'GPKIT2X07C133588P',
        'K52022346AA',
        '5C0129601E',
        '06E115562C',
        '17210MFFD00',
        'K68197867AB',
        '28220A7000',
        'GPKITTROCAOLEOEA111COMFBTK',
        '13717850056',
        'GPKITTROCAOLEOEA211TSI2C',
        '0GC325183D',
        '96445424',
    ],
    Acessorios: [
        '7711732545',
        '50928562',
        '7093046',
        '50928686',
        '7093199',
        '5U0035209',
        '5U0035936',
        '7086592',
        '51472461168',
        '50927610',
        '6EC061500',
        '2GK061500041',
        'BRPRT11107',
        '502906500',
        'PV2410A010',
        '52159108',
        '2GP061500',
        '7094610',
        'PZ0020D010',
        '2GK061160',
        '281150132R',
        'BJ0AA130D00KA',
        'BH8A19A435AA',
        '7093263',
        '8201737104',
        '50290239',
        '738321116R',
        '50926647',
        'PV2130D061',
        '52104419',
        'K82214666',
        '8201737105',
        '50290325',
        '5U0061500A1NN',
        '51472354155',
        '5U0054620C',
        '51475A2DA74',
        '7090166',
        'BRPRT11087',
        'R1R12MB000',
        'BXR12MB000',
        '50291054',
        'R1N60MB000',
        '6EA061500',
        '7087147',
        '50926388',
        '50290240',
        '7092358',
        '5U7860382KN2',
        '7093527',
        'LBRP005384',
        '50927787',
        '738873659R',
        '50928337',
        '5Z98601451NN',
        '7090104',
        '7711732226',
        'BRPRT11133',
        '51960432',
        'R1R13MB000',
        'LBRC002288',
        '51472451585',
        'BRPRT11022',
        '50928937',
        'BRPRT10753',
        '51475A50923',
        '5U00615001NN',
        '7702271188',
        '50290190',
        '50928586',
        'R1R12MB010',
        '50927857',
        'BRPRT10320',
        'BRPRT10829',
        '7700438342',
        'K82214658AB',
        '5U7860147AKN2',
        '50927617',
        '75998AR030',
        '7711733127',
        'PZ00212006',
        '735592555',
        '100270299',
        'K5UQ33DX8AD',
        '85715R2000N',
        '32270144',
        '8200607392',
        '51472354164',
        '738204986R',
        'AR087',
        '7092357',
        'PV2130D011',
        '2H7061500041',
        'BRPRT10195',
        'CAPA1840',
        '51472407168',
        '50290498',
        '98057182XY',
        'LBRW005846',
        'PV6250D010',
        '32357460',
        '51472458551',
        'PV24102030',
        'EB3Z41290D12EB',
        '5U7860381KN2',
        '7093052',
        'BRPRT10627',
        '75998144TI',
        '738203810R',
        '7095205',
        'BXR13MB000',
        '2GP061163',
        '51472450511',
        'CA511903',
        'PT76716220',
        '98057183XY',
        '8201739945',
        'CA511545',
        'LBRP005749',
        '8201739942',
        '7095102',
        '7711732177',
        '6EA863011QUE',
        '1SM13BR005',
        '738210421R',
        'BRPRT10792',
        '6EC061500A',
        '51472239936',
        '7711733422',
        '51472461169',
        '50928587',
        '50927786',
        '17B061500',
        '50928934',
        '31470728',
        'K82219004AC',
        '7094512',
        '1SF14AC300',
        '6EC061163',
        '990507720R',
        'PZV7514210',
        '7711732193',
        '51472458552',
        '100213076',
        '73821AZ66A',
        'R1N21MB000',
        'BRPRT10539',
        '7702271459',
        'PV2410D011',
        'PV2130D012',
        '808185RB0A',
        '50928205',
        'PZV8311210',
        '7711733592',
        'R1R14MB010',
        '5U5863463C1BS',
        '5C6035160',
        '8201740796',
        '51472287852',
        'CAPA3152',
        '58510YY880',
        '50928935',
        '7711733245',
        '8201734926',
        '2H3863011EUN',
        '7094816',
        '50290146',
        '50927788',
        '32345294',
        '51472451586',
        '7095416',
        '7711732225',
        '7702270627',
        '75998AR020',
        '7711210818',
        'CAPA2609',
        '51472452201',
        '1SM13BR004',
        '50928635',
        '32332378',
        '808195RB0A',
        '5Z98601461NN',
        '2G5061163',
        '32332512',
        '7711733246',
        '51472450512',
        '08P173M6810',
        '51472365853',
        '7094513',
        '50928936',
        'PV2410D020',
        'V04010025C',
        'BRPRT10197',
        '51472452204',
        'PV2410D021',
        '6EA061500A',
        'K82214195',
        '26328044',
        '6EA853689BFOD',
        '50290094',
        '5NL06150082V',
        '51472447606',
        '51472447608',
        '749016KL2J',
        '749028884R',
        '7702271556',
        '1SM15BR300',
        '51475A2DA75',
        '7711733299',
        '50927856',
        '51475A20D31',
        '5Z0035160',
        '738874240R',
        'LBRW000181',
        '31693758',
        'PC2460K004',
        '50928090',
        '50928943',
        '50290497',
        '8201600177',
        '32338449',
        '8201562892',
        'CAPA4353',
        '990455264R',
        '100235113',
        'PW30102001',
        'CAPA1361',
        'LBRW005854',
        'PV4560K001',
        'BRPRT11124',
        '08P17T14810A',
        'BRPRT10608',
        'BG8AA156A04BA',
        '7702271545',
        '50928940',
        '50290107',
        '51472458569',
        '990443204R',
        'V04010046C',
        '1SM14BR003',
        '749029959R',
        '2GP061500A',
        '7095224',
        '58510YY540B0',
        '31470219',
        '7702270196',
        '51472414219',
        '51969936',
        '6EA061270',
        '100217350',
        '32270145',
        '82712338617',
        '7711732140',
        'BRPRT11292',
        '51472353820',
        '51472414218',
        '100256667',
        '98386217VV',
        'BRPRT11125',
        '31435433',
        '908921901R',
        '802840357R',
        'GPKIT5Z9860145152NN',
        '7702271632',
        'CAPA3013',
        '2GP061163B',
        '32332883',
        '51472461166',
        '39833586',
        '31373686',
        '5U7061500A1NN',
        '2GP061270',
        '80011176',
        'GPKIT5U0035209936',
        '100188780',
        '30789025',
        '32147907',
        '32351087',
        '5U7860148AKN2',
        '897410K101',
        'PV62502010',
        '7711733081',
        'BXR14MB000',
        'BRPRT10968',
        'CN15N42528AB',
        '80A071151',
        '7661A154',
        '5Q0919279',
        'BRPRT10685',
        '80001853',
        '8W0061511A041',
        '51472458567',
        'PV2130D063',
        '51847904',
        '51732447',
        '7711733430',
        '5Z0853687A',
        '08U453V0800',
        '51472451592',
        '08P173V0810',
        '51472287859',
        '735592552',
        'BRPRT11432',
        '1S0035491',
        'LBRW005901',
        '100224269',
        '908931HL0A',
        '8200485540',
        '759981044T',
        '735599052',
        'CAPA3147',
        '7090093',
        'BRPRT10401',
        '51472414589',
        '32332513',
        '8201749750',
        '32261235',
        '52159113',
        '6813431',
        'PV21302011',
        '51472347734',
        '5U7853050AMY2',
        '51966263',
        '32351093',
        '32351090',
        '31454718',
        '1648690980',
        '51472451589',
        'K82212996AB',
        '51475A20D39',
        '7094911',
        '32216555',
        '83B061501041',
        'MVHS235BT',
        'BRPRT10891',
        '1SB853433CQHF',
        '701010002AA',
        '100197315',
        '100249115',
        '50926444',
        '51883308',
        '52052926',
        'KB3Z7855100V',
        'V04010046B',
        'BRPRT11362',
        '1ST20BR005',
        '7711732161',
        '7089887',
        '5Z0071597D',
        '50928567',
        '6EA951171',
        '8201739943',
        '50928338',
        '32357518',
        '6EC061270',
        'V04011001A',
        '32251530',
        '848900620R',
        '100198565',
        '46792649',
        '100259276',
        '100201488',
        '990473021R',
        '990469004R',
        '100176284',
        '738200465R',
        '83A071151',
        '5U7860147KN2',
        '7095059',
        '7090867',
        '7092463',
        'BRPRT11020',
        '85640458',
        '256400001R',
        'PV2130D014',
        '1SM13BR001',
        'BRPRT10368',
        '51472239937',
        'BRPRT10661',
        '08P173R4810A',
        '08U453R4800',
        '32251592',
        '32220944',
        '100202865',
        '802842966R',
        '84898ZX00A',
        '828169KE1A',
        '822836608R',
        '8200699748',
        '990391HL0A',
        'BRPRT10926',
        '100250709',
        '51883309',
        '990J44175R',
        '51974542',
        '8200754138',
        '2G5064317',
        '908954321R',
        '808189KF0A',
        '738871956R',
        'GPKIT5U7860147KN248',
        'GPKIT5U7860381E2KN2',
        'K05178271AC',
        '31408915',
        '738830089R',
        '735599053',
        '4M0071151',
        '738225RB0A',
        '808945JL0A',
        '100207595',
        '9826801580',
        '628891JB0A',
        '6EA853688BFOD',
        '100192455',
        '7535102120',
        '51804366',
        '735578840',
        '908898600R',
        '2GM853601FDPJ',
        '98284027DX',
        'BRPRT10586',
        '51814108',
        '628901813R',
        '6EA853630ADPJ',
        '6EA853689AFOD',
        '30796427',
        '7535102140',
        '5U7853675A739',
        '51974465',
        '2G5853601DPJ',
        '908926547R',
        '735503991',
        '3C0837891',
        '100192456',
        '51974538',
        '6EA853688AFOD',
        '2GP064317A',
        '2G5853689BDPP',
        '100249278',
        '5U0853601AULM',
        '628891JA0A',
        '5H0853601DDPJ',
        '100201489',
        '6EA8536012ZZ',
        '5U5853687B739',
        '908894649R',
        '908890528R',
        '100265504',
        '6EA853689CFOD',
        '2GP853689BFOD',
        '52142516',
        '5K0853601FULM',
        '908929756R',
        '5H0853601HDPJ',
        '2G5853688CFOD',
        'GPKIT100205013',
        '628904BA0A',
        '5Z0853601D739',
        '50151685',
        '51818499',
        '100256754',
        '51851679',
        '9097502159',
        '52040550',
        '7531102220',
        '908890837R',
        '754420D500',
        '2G5853688BFOD',
        '5Z0853685GGQF',
        '100256289',
        '2H6853948DIDJ',
        '22G853630DPJ',
        '17A853688FDPJ',
        '628903HM0A',
        '8631807000',
        '2GP853688BFOD',
        '51944206',
        '5U0853601BOLP',
        '100248899',
        'MN167684',
        '628909311R',
        '628901KA0A',
        '2G5853688CDPP',
        '14100968',
        '863531F010',
        '8200115115',
        '100265041',
        '100264265',
        '7530102060',
        '100256290',
        '753010D040',
        'CA510019',
        '863101S000',
        '735451029',
        '2G5853689BFOD',
        '908904JA0A',
        '2G5853689CFOD',
        '2GP853675C2ZZ',
        '5Z0853685C739',
        '9097502138',
        '8200027424',
        '8200535890',
        '100241545',
        '9800743480',
        '5H0853601NDPJ',
        '100265296',
        '7415A615',
        '848101807R',
        '6N0853687H739',
        '2G58536872ZZ',
        '8200052586',
        '100249117',
        '51142328447',
        '5U6853687',
        '100206684',
        '7415A560',
        '2GJ853687E2ZZ',
        '52047057',
        '2H6853630DPJ',
        '52040548',
        '5NA853688C2ZZ',
        '2G5853687041',
        '51767288752',
        '908885R00A',
        '5U0853685D739',
        '100250162',
        '46819862',
        '100203253',
        '90975T2008',
        '46817202',
        '2GK853689B2ZZ',
        '51824802',
        '908905RB0A',
        '5U6853675739',
        '96758508VD',
        '628902376R',
        '46757491',
        '908954235R',
        '628901U600',
        '6EA853689FOD',
        '5Z0853601CFDY',
        '98002499DX',
        '2H6853601ADPJ',
        '735579354',
        '5U5853675739',
        '848909804R',
        '46788088',
        '2K5853600DPJ',
        '628904JA0A',
        '2GK853688DDPJ',
        '908925RB0A',
        '51974535',
        '100192453',
        '51974540',
        '5NA853688ADPJ',
        '5U0853630ULM',
        '100201494',
        '754410D030',
        '8200068687',
        '98553976',
        '5NA853601JZA',
        '8200469132',
        '908965RC0A',
        '7415A708',
        '908965RB0D',
        '46843975',
        '2GP853675A2ZZ',
        '5Z0853601AFDY',
        '908890019R',
        '84896ZJ60C',
        '7544202570',
        '84896ET00A',
        'K68305597AA',
        '51883311',
        '2GK853689DDPJ',
        '9675090880',
        '86310A0000',
        '90975T2030',
        '17A853679BWYR',
        '46792651',
        'GPKITEMBLEMASCOMFORTLINE',
        '7700824625',
        'GPKIT2GP853675A2ZZ2GP853675C2ZZ',
        '930620687R',
        '2H0853601AULM',
        '100248626',
        '2H6853675BMGM',
        '53494339',
        '7544202350',
        'GPKIT6EA8536889CFOD',
        '52047059',
        '8200707433',
        '623827948R',
        '51147026186',
        '46792656',
        '7703081151',
        '2G5853688BDPP',
        '5U7853675C739',
        '6EA853630FOD',
        '100249401',
        '32220642',
        '51792238',
        '95122564',
        '8200867304',
        '7700817027',
        '1JE853686A',
        '754440D210',
        '53386955',
        '5Z4853047AAA',
        '848100075R',
        '908894785R',
        '100201495',
        '51878544',
        '51900873',
        '7700310940',
        '7544102310',
        '46819858',
        '51781557',
        '2H6853750D2IM',
        '5NA853630FOD',
        '51932710',
        '2H0853675F2ZZ',
        '754420D460',
        '7544142070',
        '908897184R',
        '100260230',
        '51953599',
        '6EA853675BSEW',
        '51147376339',
        '6001550573',
        '46792655',
        '848903630R',
        '100244885',
        '5G0853617A',
        '2H0853675AGQF',
        '5U0853685B',
        '908897200R',
        '8200522593',
        '2GP853689C2ZZ',
        '32328058',
        'GPKITEMBLEMASTCROSS',
        '31383031',
        '1295713300',
        '7535102090',
        '5U0853601D2ZZ',
        '31214625',
        '628904053R',
        '908923BA0A',
        '908891552R',
        '1S6853601B739',
        '51148132375',
        '5NA853688BDPJ',
        '8200209130',
        '5U7853687',
        '26224719',
        '86300R1000',
        '7544402850',
        '51974549',
        '848969KE1A',
        '8200209149',
        '2G5853688AFOD',
        '52005058',
        '51714006',
        '8200057918',
        '90891EB700',
        '100272258',
        '100249114',
        '100265040',
        '8200484897',
        '848969AA0A',
        'K68305596AA',
        '100257275',
        '908927814R',
        '908895736R',
        'CA511204',
        '51945122',
        '7090718',
        '7543142100',
        '6001549983',
        '5C6853601ULM',
        '9097502136',
        '628958907R',
        '7544402F40',
        '5U0853630AOLP',
        '52062898',
        '7543102100',
        '7415A111',
        '848103907R',
        '50300126A',
        '1ST20BR008',
        'BRPRT11343',
        '998102668R',
        '31470211',
        '32347041',
        '8W1061501041',
        '51472450516',
        'PV2130D013',
        '8V5061180',
        '4M1061221C041',
        '100215617',
        '7711733429',
        '80A061511A041',
        '31659471',
        '2GP061325',
        '31470948',
        '51472295245',
        '51472458555',
        '6001548235',
        '848906893R',
        '628902379R',
        '5Z6853675GQF',
        '26306972',
        '100205093',
        '5G0853688LJZQ',
        '6EA853675H2ZZ',
        '52036057',
        '6EA853688CFOD',
        '908899252R',
        'GPKIT5Z0853601CFDY5Z0853343',
        '8200029173',
        '100192450',
        '6EA853675D2ZZ',
        '90892ZT50B',
        '754470D060',
        '51966261',
        '848903AW0A',
        '62890EB710',
        '98064511VD',
        '100267213',
        '100241543',
        '100249116',
        '100241681',
        '2H5853630AULM',
        'GPKITEMBLTCROSS200',
        '46523777',
        '52005057',
        'CA510864',
        '754420D120',
        '2G5853675A041',
        '94734335',
        '52040549',
        '51147463684',
        '7700434725',
        '8200953046',
        '75700TBAA00',
        '934952TB0C',
        '98154156VD',
        'CA511544',
        '53331390',
        '26225774',
        'K68243732AA',
        '7415A401',
        '6001550576',
        '100192454',
        'K68243730AA',
        '36136783536',
        '2G08536872ZZ',
        '628906Z500',
        '908901HM0A',
        '51816952',
        '5U7853050DMX1',
        '31213612',
        '863411S000',
        '31333640',
        '908923851R',
        '93343850',
        '8200040500',
        '100241530',
        '99099JT31C',
        '32251529',
        'N10592102',
        '77522414855',
        '281156900R',
        '32389379',
        '96120M0100',
        'V04010030A',
        '65147724048',
        '6316081',
        'BRPRT11341',
        '98550659',
        '61122458609',
        '69201149',
        '2G0035954',
        '5G0035727',
        'V04011010C',
        '17A035222',
        'V04010023J',
        '848969LH1A',
        '52036061',
        '100250707',
        '8200068691',
        '822837467R',
        '7702270442',
        '80816EL000',
        '53386954',
        '7711732237',
        '848100208R',
        '53331386',
        '802854222R',
        '100257313',
        '5U7853050MY2',
        '8200209131',
        '2H5853687739',
        '5U4853414F61V',
        '5Z0853687H9B9',
        '6001549910',
        '802852648R',
        '80819AZ60A',
        '7N0955609',
        '990457356R',
        '822825139R',
        '808928808R',
        '8200435217',
        '5Z4853421JLXC',
        '51792233',
        '8200699745',
        '100257312',
        '808163SG1A',
        '848969LH2A',
        '8200699734',
        '822829290R',
        '828183SG1A',
        '990451025R',
        '7702270505',
        '908921HM0A',
        '822834102R',
        '100270709',
        '46547684228',
        '990455430R',
        '2GP853279',
        'M1PZ17B807A',
        '90892EM30D',
        '5U7853050B61V',
        '808173SG1A',
        '100263930',
        '51827522',
        'BRPRT10925',
        '2G5853675C2ZZ',
        '98122596XT',
        '828173SG1A',
        '51883307',
        '8200699754',
        '31454717',
        'BRPRT10807',
        '31463591',
        '80010619',
        '32270141',
        '735365834',
        '738216665R',
        '1635025880',
        '31463590',
        '82712472916',
        '8U08600221P9',
        '51137230207',
        '7661A179',
        '5U7860146AKN2',
        '1608474680',
        '5U7860148KN2',
        '7711732332',
        '7711732253',
        'GPKIT5U7860145AKN246',
        '32296508',
        '46548520067',
        '5U7860145AKN2',
        '82712444056',
        '31454711',
        '5295014140N',
        '1ST20BR017',
        '7090706',
        '1ST20BR015',
        '5Q0951172',
        '1ST20BR003',
        '5Z0951171D',
        '285953DN1A',
        'PV4580D001',
        '1ST20BR010',
        '50902050',
        'BRPRT10373',
        '7700315139',
        '8637A822',
        '100196293',
        'CAPA4090',
        'V04010088A',
        '8E0919279',
        '50901734',
        '51472219800',
        '31659557',
        '51472219803',
        'GPKIT2GP0615002GP061325',
        '51472458442',
        '51472458439',
        '8200433441',
        '4M0061182',
        '4G1061501041',
        '8W0061511041',
        '8Y4061511041',
        '51475A62B50',
        '51472469121',
        '80A061182A',
        '08U45T14800',
        '4KE061511041',
        '31470992',
        '80A061182',
        '8W5061180',
        'BRPRT10321',
        '51472350421',
        '51472358056',
        '5U70615001NN',
        '51472288260',
        '8V1061271EMNO',
        '51472407303',
        '51475A3E593',
        '4M1061512A041',
        '8201605708',
        '4M8061180',
        '51472286007',
        '8W8061180',
        '51472458440',
        '32347055',
        '4M1061512041',
        '08U453M6800',
        '83A061511041',
        '6EA061163',
        '8V5061502041',
        '51472231961',
        '7094870',
        '8201562892C',
        '7702270974',
        '8201562902',
        '51472414224',
        '51472473482',
        '8200641468',
        '32216556',
        'K82215755AC',
        '32394445',
        '32353878',
        '7093051',
        'GPKIT2GP061270',
        '1616435080',
        '749025RA0A',
        '1S1061445AQI3',
        '31664207',
        '7702271251',
        '7711732224',
        '51472458558',
        '51472447613',
        '5C7061404041',
        '4KL061501041',
        '6EC863463B1BS',
        'KE7455S00B',
        '51472409932',
        '5G106150082V',
        '908969CA2A',
        '31662036',
        '628909470R',
        '52154567',
        '5U0853601B2ZZ',
        '51974466',
        '3CM853630DPJ',
        '908926485R',
        '908922537R',
        '53386956',
        '51142755618',
        '908903707R',
        '1334537080',
        '5U0853601ULM',
        '63890CD10A',
        '75723SNJK11',
        '6EC8536872ZZ',
        '8200717146',
        '990444805R',
        '8U08537412ZZ',
        '6EC853688CFOD',
        '46792657',
        '7415A358',
        '8U08537422ZZ',
        '61300928',
        '7544202240',
        '30747464',
        '848963VY0A',
        '1271232300',
        '908902202R',
        '98312220DX',
        '90890EM30A',
        '863102E000',
        '7701474477',
        '4F08537412ZZ',
        '46454639',
        '6EC853687A2ZZ',
        '52005060',
        '100259717',
        '75723TT4P01',
        '6130809A',
        '100256693',
        '94747905',
        '32337964',
        '100241544',
        '628901HM0A',
        '6001550574',
        '8T08537412ZZ',
        'CA510273',
        '52157833',
        '53386951',
        '75711SEMK01',
        '90892CJ400',
        '7700352126',
        '31213967',
        'PZV0114170A0',
        '848903005R',
        '2G5853689CDPP',
        '26294290',
        '1288574700',
        '6QD853601AULM',
        '5NA8536872ZZ',
        '31457467',
        '1323234300',
        '8200752893',
        '75725TR8M00',
        '52017091',
        '26226059',
        'GPKIT2H0853675F2ZZ2H6853948DIDJ',
        '51974537',
        '2G5853675C041',
        '754440D220',
        '908921CS4A',
        '53386952',
        '7700437773',
        '31391835',
        '2GP853688C2ZZ',
        '9097502064',
        '51147463715',
        '7700422227',
        '5G0853675APCWB',
        '7544102180',
        '8200637308',
        '7700849001',
        '908895355R',
        '9097502062',
        '2GP853689DFOD',
        '8200717145',
        '8200717149',
        '51148219237',
        '848955EE0B',
        '2GM853687A2ZZ',
        '51960168',
        '7810V7',
        '36122455268',
        '7700422217',
        '90891EB300',
        '51953600',
        '377853687BC',
        '2G5853689AFOD',
        '100266023',
        '94747899',
        '51147362473',
        '7701208978',
        '51149447805',
        '908929219R',
        '8200717150',
        '6EC853630FOD',
        '6EA853687A2ZZ',
        '8200830438',
        '6001550572',
        '86330M4000',
        '8200120861',
        '100265039',
        '9766621VX',
        '990427604R',
        '8H08537372ZZ',
        '96772205VD',
        '51147494319',
        '75731SNBK41',
        '2GP853675G2ZZ',
        '8T0853742B2ZZ',
        '2GK853688B2ZZ',
        '8200073444',
        '1331636200',
        '8200120862',
        '6EA853675J2ZZ',
        '93496JS00A',
        '7700845989',
        '32328068',
        '90892EM40A',
        '7700432874',
        '8200209148',
        '664004F011',
        '8200754139',
        '30796589',
        '2G5853675A2ZZ',
        '84690299',
        '98335584VD',
        '908961JD0A',
        '5G08536012ZZ',
        '90892AZ62A',
        'JB3Z41290D12AB',
        '8L08537352ZZ',
        '26224695',
        '90890CJ01A',
        'R1R32MB046SE8',
        '52049813',
        '52049533',
        'K68267206AA',
        '8P08537422ZZ',
        '86311D3000',
        '863004A900',
        '51148058881',
        '90897ZR80A',
        '51148737319',
        '100267423',
        'K68305592AA',
    ],
    Roupas: [
        '41243052M',
        '41243052P',
        '9816221VM000L',
        'V04010062C002',
        '9811116VM000M',
        '9823518VM000L',
        '9811116VM022L',
        '9827814VM000M',
        '76135A80CC8',
        '9855714VW002L',
        '9908020VM002L',
        '9801321VW022L',
        '9801321VW000L',
        '9816321VM000M',
        '9800821VW000M',
        '9638823VM000M',
        '32251583',
        '9830414VM000M',
        '9811116VM000S',
        'V04010062C001',
        '9800521VM000L',
        '9801321VW002L',
        '32251582',
        '9800721VW000L',
        '9801221VM000S',
        '9815521VM000M',
        '9828719VW000L',
        '9907021VM002L',
        '9855714VW000L',
        '9817208VM000L',
        '9800721VW002L',
        '9715322VM000M',
        '9916619VM002L',
        '9800521VM000M',
        '76137913261',
        '9809015VM000L',
        '9827714VM000L',
        '9816018VM000S',
        '9800321VM000M',
        '32251584',
        '9800623VW000S',
        '9833615VM022L',
        '9823518VM042L',
        '9908020VM000S',
        '9703423VM000M',
        '9827814VM000L',
        '9801221VM000M',
        '9819118V',
        '9855714VW022L',
        '9621324VM022L',
        'V04010062C005',
        '76137922956',
        '9905723VW000M',
        '9823518VM002L',
        '9944910VW',
        '9638823VM002L',
        '9816321VM002L',
        '9806619VW000L',
        '76137913262',
        '9810321VM000S',
        '9812622VM002L',
        '9800321VM000L',
        '9801221VM000L',
        '9804519VM000S',
        '9703323VM002L',
        '9812622VM000M',
        '9800521VM000S',
        '9715322VM002L',
        '9810321VM022L',
        '9809015VM000M',
        '9744823VW002L',
        '120A17400R',
        'V04010062C003',
        '9830414VM000L',
        '9705023VM000M',
        '9804519VM000M',
        '9800321VM002L',
        '9800721VW000S',
        '9705023VM000L',
        '9810321VM000M',
        '9719223VM000M',
        '9828719VW000M',
        '9840422VM000M',
        '9840422VM000L',
        '76128504858',
        '76128504857',
        '32220653',
        '9806619VW000M',
        '9800521VM002L',
        '9807114VW000S',
        '9831614VW000M',
        '9833019VW000M',
        '9715322VM022L',
        '60090M',
        '9800623VW000M',
        '9819018V',
        '76617109836',
        '9719223VM002L',
        '9816921VW000S',
        '60090G',
        '9715322VM000L',
        '9827714VM002L',
        '9827714VM000S',
        '9621324VM002L',
        '80162864390',
        '60455024',
        '9817921VM000L',
        '60091GG',
        '9809015VM002L',
        '9800820VW000L',
        '9651322VM002L',
        '9804519VM000L',
        '9800821VW000L',
        '9716922VM000M',
        '80142864316',
        '9818022VW000M',
        '9827714VM000M',
        '9629222VM002L',
        '9800821VW000S',
        '80142864291',
        '9715322VM052L',
        '76148504911',
        '9621424VM000L',
        '9840122VM000L',
        '9621424VM002L',
        '76617914723',
        '60435097GG',
        '60435097G',
        '76237105779',
        '60330075',
        '9818299VM002L',
        '9702723VW000M',
        '60324075M',
        '9651823VM000S',
        '9801121VW000M',
        '9652822VM000M',
        '9703423VM000L',
        '60324075P',
        '9816921VW000M',
        '60324096P',
        '76148568099',
        '9703423VM022L',
        '9744923VW000S',
        '60377097M',
        '9814423VW000M',
        '9814423VW002L',
        '41271M',
        '9719023VM002L',
        '9800820VW000S',
        '9744923VW000L',
        '9638823VM000L',
        '9656923VM002L',
        '9904023VW000M',
        '9606423VM000L',
        '9817021VW000L',
        '9915122VW002S',
        '9814822VM002L',
        '9606423VM000M',
        '9807114VW000M',
        '9643123VW000L',
        '9719023VM000M',
        '9636523VM002L',
        '9663223VM002L',
        '80145A21690',
        '9669923VW000L',
        '9805319VM022L',
        '9810619VM000L',
        '9702723VW000L',
        '76117922941',
        '9606523VM000M',
        '9643123VW000M',
        '9621123VW002S',
        '9656923VM000M',
        '9815521VM000L',
        '9643123VW002S',
        '9656923VM000S',
        '60384097G',
        '9817921VM000S',
        '9643123VW000S',
        '9606623VM000M',
        '9908320VM000L',
        '9801121VW000S',
        '9719123VM002L',
        '9908320VM000M',
        '9656923VM000L',
        '9719023VM022L',
        '9761923VM000M',
        '9658122VM002L',
        '9817021VW000M',
        'APR057004MR',
        '9811216VM000L',
        '60324096M',
        '9827714VM222L',
        '9718923VM000L',
        '60384097GG',
        '9606423VM022L',
        '9651322VM000M',
        '9800820VW000M',
        '9818299VM000M',
        '9807114VW000L',
        '9817021VW002S',
        '80142864285',
        '80142864129',
        '9817021VW002L',
        '9719023VM000L',
        '76217922681',
        '80142864303',
        '9636523VM000L',
        '9719223VM000L',
        '9703923VW000L',
        '9621123VW000S',
        '9718923VM002L',
        '9811621VM000S',
        '76217922675',
        '76117913220',
        '9703423VM002L',
        '9636523VM000M',
        '9621424VM022L',
        '76617914722',
        '9621324VM000L',
        '9742922VM000S',
        '9621424VM000M',
        '76617109944',
        '41252075G',
        '8201627658',
        '80142864122',
        '80142864125',
        '76617109852',
        '76617109755',
        '7701477101',
        '76618536687',
        '76618536424',
        '76617109763',
        '41252075XG',
        '0111AT',
        '9819218V',
        '76617109835',
        '32220652',
        '76618542488',
        '9949117VM',
        '32220654',
        '9774123VM000M',
        '80162864388',
    ],
    Freios: [
        '5Z086338771N',
        '472106141R',
        'D60101HK0B',
        '100170716',
        '51836920',
        'D10605RB0B',
        '5U7612041D',
        '51855421',
        '46349214',
        '517123K050',
        '8660089816',
        '265907079R',
        'BRPRT20105',
        '402065345R',
        '46433243',
        '52134378',
        'MB2A1125AA',
        '432064KH0A',
        'G3MJ1125BA',
        'BK3Z2001E',
        '0K63B33251A',
        '334909',
        '51749124',
        'MB8A1125AA',
        '597104E000',
        '7094386',
        '5Z0945097B',
        '7701059704',
        '51897245',
        '1SO18BR003',
        '52137653',
        'K04549626LA',
        '52134379',
        '7701208332',
        '100194413',
        '472104244R',
        '1S0615301E',
        '7085012',
        '46834519',
        'J2MJ1125AA',
        '7711172381',
        '5U1612041R',
        '04465421808A',
        '044650D101',
        '8660089691',
        '432067943R',
        '328002P120',
        '5Z0698545B',
        'K04549625LA',
        '100215053',
        '7093123',
        '5W0615105A',
        '1608515380',
        '1681904480',
        '58101B2A00',
        '25320AX10A',
        '5X0614105',
        '453440',
        'JZZ698302A',
        '7701204286',
        'NZ6Z2001A',
        'JB3Z2C190A',
        '53479013',
        '8660089545',
        'CAPA1632',
        '4605B628',
        '52004363',
        '464002332R',
        '7701206313',
        '58101R1A30',
        'JZZ698151AE',
        '7568442',
        '58510R1100QQH',
        '32721539562',
        '58980C1100',
        '51924916',
        '52137652',
        '53475747',
        '0446502520',
        '84277409',
        '044650K280',
        '7094652',
        '7090112',
        '5Z1614105H',
        '58101D7A10BI',
        '402060010R',
        '4615A223',
        '7701208339',
        'H2MJ2V001FA',
        '265908613R',
        'GPKIT2X402065RA2A',
        '46010JR80B',
        '52066791',
        '1609000980',
        '46745558',
        'TOTALHBF4',
        '402063542R',
        'EJS12010CA',
        '0K60A33251A',
        '938104DR0AQQK',
        '360108002R',
        '0446502420',
        '100198943',
        '410680001R',
        '8660089589',
        'GPKIT2XJ2MJ1125AA',
        'MB1A2M008AA',
        '34218569412',
        '265905RA0A',
        '1686754980',
        '9805083380',
        '208364',
        '410608481R',
        '100203843',
        '52134377',
        '0446602300',
        '581011SA30BI',
        '7089584',
        '7701067629',
        'J2MJ1125CA',
        'AB312L361AB',
        '34206888835',
        '58101B2A00BI',
        'MB1A2K021HB',
        '265908Z300',
        '31471410',
        'D10601HL0B',
        '8200688880',
        '5U6945097A',
        '34106860019',
        '8660089582',
        '1613704080',
        '597603W200',
        '435120K280',
        'J2MJ1125BA',
        '2QB698151C',
        '8660089542',
        '46445004',
        '460110032R',
        '19283184',
        '8660089541',
        '52137654',
        '7094651',
        'KK3Z1125B',
        'JZZ698151AK',
        '402065RA2A',
        '402064KH1B',
        '2376125031',
        '0882380021BR',
        '432007595R',
        'MB6A2K021AA',
        '8660089543',
        '100174815',
        '425475',
        '583301G000',
        '581011SA00BI',
        '2Q0698151R',
        '22895243',
        'CAPA3205',
        'GPKIT2X9827816880',
        '32300703',
        '7094916',
        'GPKIT86600895378660089774',
        '52144314',
        'BK2Z2200D',
        '7700311217',
        '100221063',
        '5U1614105G',
        '7098038',
        'DB392C205BD',
        '46419204',
        '5U0711303P9B9',
        '52162684',
        '989757',
        'MB6T2K021AA',
        '4545J9',
        '1678168480',
        '32287446',
        '517123E000',
        '7088534',
        '460111659R',
        '34117705216',
        '90497879',
        '32373348',
        'JX6Z2200E',
        'BK312A315AB',
        'K68412364AC',
        '19283185',
        '7089968',
        'JZW698151AE',
        '84786459',
        '410602192R',
        '2020A043',
        '583021DA01BI',
        'JZW698302A',
        '52144315',
        '440A04494R',
        '34201543683',
        '31423722',
        '1647874380',
        'K68052369AA',
        '25320AX00C',
        '1647862780',
        '1678168280',
        '440604694R',
        '51967259',
        '0446647101',
        '472106935R',
        '5Z086377971N',
        '8201695231',
        '583022KA10',
        '441006424R',
        'K68430134AA',
        '7701209380',
        '43206EB70B',
        '1638498080',
        'EB3Z2078F',
        '77367214',
        'GPKIT2X1643172880',
        '8660089530',
        '583021DA01',
        '04466420608A',
        '581012TA20',
        '460115822R',
        '5U7611764B',
        'EB3Z2078E',
        '7088828',
        '1668247480',
        '4351202290',
        '440002509R',
        'GPKIT2X46455892',
        'CAPA3108',
        '7094384',
        '7094530',
        'JZZ698302B',
        '95671D3000',
        'K04779783AB',
        '1636504080',
        '895450K070',
        '7701207339',
        '581444F000',
        '5Z1614106P',
        '31471034',
        '51967260',
        'GPKIT5Z0698151B',
        '410016542R',
        '58510M0200QQH',
        '460114996R',
        '477810K183',
        '9827816880',
        'CAPA3201',
        '04466601408A',
        '7098033',
        '265905RA1A',
        'CDPA0251',
        '938103K000',
        '19349213',
        '8660089544',
        '4249J8',
        'K68035012AD',
        '7098048',
        '7089967',
        '410151697R',
        '402069LH0A',
        '265906642R',
        '5Q0698151AH',
        '31665446',
        '7082147',
        'JZZ698151AF',
        '80A615121A',
        '7088777',
        '363722326R',
        '581011FA50',
        '4545K3',
        '93600D9040WK',
        '463105JT0A',
        '7087589',
        '7202351E',
        '424310K120',
        '410A12582R',
        '7701205742',
        'K68020494AB',
        '581012SA70',
        '1S0615301C',
        'MB0A2K021EB',
        '410603847R',
        '31687441',
        '7098020',
        '0446602450',
        '94741181',
        '1643075880',
        '1636508280',
        '19314697',
        '583021MA40',
        '365306S302',
        'KK3Z2C026C',
        'CAPA1000',
        'CA350240',
        '440110675R',
        '2H0612105A',
        '2Q0614517AEBEF',
        '32276933',
        '9842490180',
        '597003W100',
        '440603321R',
        'CAPA3106',
        '9805066080',
        '58101M4A10BI',
        'BJZZ700Q2',
        '34106884492',
        '440670001R',
        '402065054R',
        '432005338R',
        'D1060EM30C',
        '52129019',
        '410600222R',
        '7701206685',
        '51964082',
        'GPKIT2X1636504080',
        'CDPA0257',
        '4283104A',
        '424966',
        '927001D000',
        '1610984380',
        '2H6698151A',
        '8200874407',
        '7091129',
        '1635834980',
        '1673603880',
        '26272686',
        '5Z0612151F',
        '7074027',
        '30793857',
        '1S0615301F',
        '5Z6945097Y20',
        '52066792',
        '30793943',
        '410143577R',
        'WHT010085',
        '19349229',
        'D10603TA0A',
        '462521815R',
        '19380533',
        '474013766R',
        'CAPA3206',
        '7088138',
        'MN102416',
        '34216887410',
        '5U7927807',
        '589201F100',
        '51937304',
        '7098032',
        '5QN615301A',
        '98068811ZD',
        '31471033',
        '7093090',
        '479504412R',
        '8660089774',
        '32300122',
        '2Q0698451C',
        '581013RA05',
        '5810107A00BI',
        '93249824',
        '7091280',
        '7701049351',
        '360101721R',
        '7094565',
        'JB3Z2C190B',
        '100182150',
        'D10604KH0C',
        'MB8A1125CA',
        '3282527070',
        '8660089529',
        'BK2Z1125N',
        '4249A5',
        'JZW698451C',
        '7098047',
        '58510R1200QQH',
        '100258950',
        '410016657R',
        '1BP30468AA',
        '7094458',
        '472102327R',
        '7092538',
        '45022TEMM12',
        '938104DRWBQQK',
        '6Q0945511',
        '2H0615125A',
        '19380536',
        'N0177322',
        '425380',
        '34116777772',
        '1BP30461AA',
        '1613703980',
        '364028595R',
        '1647863680',
        '0K68C26251B',
        '6EA615123A',
        '410011040R',
        '424310A040',
        'MB8A1125BA',
        '31499905',
        'FCSD3890750004',
        'JZZ698151AJ',
        '581011MA01',
        '5K0698451E',
        '58101D3A11BI',
        '34218569413',
        '583023QA10',
        '6001549211',
        'B000750Q2',
        'JZW698451D',
        '26283303',
        'JZW698151AG',
        '440104968R',
        '8660089588',
        'CD120054',
        '4243160311',
        '31423305',
        '5Q0698151AJ',
        '6000627411',
        '7701208333',
        '8671018195',
        '517121M500',
        '7098028',
        '50512681',
        '2QB615301D',
        '4351206180',
        'CAPA3109',
        '597104D000',
        'K68102483AB',
        '410147163R',
        '4461009890',
        '581013JA50BI',
        '58101R1A00',
        '1JE698649A',
        '044650K600',
        '7092802',
        '19380535',
        '1636507480',
        'CAPA1591',
        '5961814',
        '927502P000',
        'E3B12001AB',
        '0446502300',
        '4670A597',
        '440002281R',
        'GPKIT2X1647883380',
        'K68334862AB',
        '34208844393',
        '59810A7300',
        'N0177382',
        '8200694128',
        '919202W100',
        '7089582',
        'K04721996AB',
        '1BP31109AA',
        '4605B958',
        '7091281',
        '2QB698151',
        '1612434080',
        '46788929',
        '8660089814',
        '581011FA50BI',
        '410602889R',
        '58302D3A00',
        '1616394580',
        '8660089678',
        '30671574',
        '7701208252',
        '6N0698151C',
        '460915JW0A',
        '441004731R',
        '5QX615601D',
        '583022JA00',
        '4M0698451N',
        '1686755080',
        '5Q0698451AF',
        '517122G000',
        '31400779',
        'WHT003861B',
        '8V0698151S',
        '7092247',
        '52273000',
        '402064KJ1B',
        '2H0698295A',
        '477300K340',
        '34216867175',
        '4243102270',
        '7098042',
        '40206ET02C',
        '2H6945097N',
        '7082459',
        '4806L9',
        '0446602181',
        'JZZ698302',
        '155255887',
        '8R0698151AA',
        '440605713R',
        '583294E000',
        '6001551314',
        '8201703980',
        '927002P000',
        '460119216R',
        '7088134',
        '34118405104',
        '9818232480',
        'B000750M1',
        '7090137',
        '8200784391',
        '440605839R',
        'CAPA3082',
        '402069518R',
        '1615046780',
        '583022WA00BI',
        'LBRW005931',
        '735653339',
        '584113A300',
        '517121F000',
        '93356408',
        '583022PA70BI',
        '51712A9000',
        '52129017',
        '7701208142',
        '8200835832',
        'JZW698451',
        'JZW615301',
        '435120K150',
        '0446502330',
        'JZW615301A',
        '4351242050',
        'MB9A2K021CB',
        '3Q0615601A',
        'BH5ADOT4AA500',
        '435120K080',
        'K68068762AA',
        '40206VJ200',
        '46463911',
        '581012TA21BI',
        '8V1612107A',
        '34116780711',
        '581802KA10',
        '34116890553',
        '328024E001',
        'GPKIT5U7609721B',
        '410604076R',
        '1635835180',
        '46455892',
        '32276932',
        '583023LA20',
        '34116889570',
        '476605707R',
        '0K60B3328Z',
        '4605A197',
        '31471752',
        '51924919',
        '462423BA1A',
        '411200530R',
        '5U0615301C',
        '1316704400',
        '19349225',
        '9837688580',
        '479109155R',
        '4670B007',
        '735533348',
        '599114E100',
        '440600295R',
        '9819544180',
        '402061HL0C',
        '2Q0698451B',
        '7090133',
        '440604591R',
        '402064392R',
        '735365337',
        '31445796',
        '1647860380',
        'GPKIT2X8660089538',
        '32287447',
        'D4M609N00B',
        '1608681680',
        '440A05169R',
        '3Q0698451F',
        '31400818',
        '71500118',
        '2QB615601B',
        '581012PA70BI',
        '30793941',
        '8200673552',
        '454597',
        '5U7609722B',
        '1686755280',
        'JV6Z2C190A',
        '7700844706',
        'F2GZ1125D',
        '477500K340',
        '425237',
        'D1060ZP00C',
        '30793539',
        '4806L8',
        'JZW698451A',
        'JB3Z2C190C',
        '584112J000',
        '41060AX085',
        '581013CA70',
        'CAPA1630',
        '424310K140',
        '440101467R',
        '34216776937',
        'CA270021',
        'JZZ698451AA',
        '41100061B',
        '92700D9000',
        '7700308721',
        '51918676',
        'GPKIT2X5Q0615301F',
        '581012PA70',
        '1686755480',
        '8660089528',
        '2H6698451A',
        '1354178000',
        '31423721',
        '364005982R',
        '2QB615301E',
        'JZW698151C',
        '2H0609617',
        '4249K1',
        '5Q0698451N',
        '34116888457',
        '32276931',
        '435120K110',
        '265902759R',
        '19349228',
        '583022SA30',
        'H2MJ2C453BA',
        '1BP31001AA',
        '7729634',
        '34106898192',
        'CAPA1590',
        '1BP31100AA',
        '1BP31085AA',
        'GPKIT2X8660089537',
        'MB1A2K021BB',
        'AB311125AC',
        '32279594',
        '5QN615601A',
        '77366679',
        '5U0615301E',
        '1BP31098AA',
        '46401356',
        'WHT003862B',
        '7701206726',
        '58302D3A00BI',
        '435120K320',
        '7X0698471B',
        '32379535',
        '581013FA01',
        'JZZ698302G',
        '581443K000',
        '155254427',
        '1686754680',
        '51924918',
        '8V0698151G',
        '1686755380',
        '52128491',
        '583021FE00',
        '402068234R',
        '442086744R',
        'WHT003863B',
        '8671016186',
        'XM341126BC',
        'WHT003857D',
        '34106874034',
        '1610725580',
        '583023EE00',
        'WHT003862C',
        '32300257',
        '31341382',
        '5Z0615301B',
        '43022TBAA03',
        'MB9A2K021AB',
        '5U7927808',
        '32373125',
        '31687104',
        '98550735',
        '1BP30399AA',
        'JZZ698151AB',
        '19349290',
        '581014EA00',
        '77364860',
        'CN152K021CA',
        '46545TJ0M81',
        '3M512M008AC',
        '52146140',
        '93205290',
        'K68003701AB',
        '3Q0698451J',
        'JZZ698151AC',
        '8201703977',
        '5Q0615301F',
        'MN102577',
        'MB0A2M008BA',
        '4636311',
        '34216890353',
        '19380534',
        '479501102R',
        'K68428455AA',
        '1JE615301',
        '30748149',
        'K82214269',
        '462426064R',
        '7700314064',
        '581013SA25',
        '06435MGZJ02',
        '479105FA0A',
        '34216788183',
        'GPKIT2X1643172780',
        '2H0698151A',
        '581012FA10',
        'AE812001DA',
        '5U0711461C1WB',
        '440661332R',
        '581011FE01',
        '5Q0698451AK',
        '19380537',
        '8R0698151',
        '5U0615301D',
        '7079656',
        '1647874180',
        '364001629R',
        '34116852253',
        '7701207897',
        '52128493',
        '155253914',
        '581011YA00',
        '599102G100',
        '440013972R',
        '8200245034',
        '46410523',
        '402063149R',
        '4806A1',
        '5Q0698451M',
        '435120D170',
        '40206JR70C',
        '432007885R',
        'CAPA3085',
        '1612599380',
        '31400426',
        '26344408',
        '1613236880',
        '1394417500',
        '956813E310',
        '581012SA70BI',
        '360102324R',
        '31317482',
        '7701206763',
        'GPKIT2X8660089544',
        '52030536',
        '34118534183',
        '52131193',
        'K68414882AB',
        '581012SA30',
        '410119926R',
        '5Q0698451AA',
        '7L6945511',
        '581012JA20BI',
        '1475331100',
        '7700314107',
        '441009705R',
        'K68144163AF',
        '1636503980',
        '4402E7',
        '77364839',
        'GPKIT5Z086377971N',
        '044650K401',
        '51918288',
        '0446602250',
        '365316S302',
        '42812214',
        'WHT003860A',
        'GPKIT2X8660089539',
        '8W0698451BG',
        '4254C2',
        '1342326300',
        '52065441',
        '2H0698545B',
        'K04779884AC',
        '98551028',
        '1612036780',
        '06455K21922',
        'GPKIT2Q0698151RJZW698451',
        'CAPA0346',
        '1613328880',
        '8200186294',
        '7701206725',
        '31445985',
        '5U7609722A',
        '1J0698151J',
        '41300227',
        '1432905100',
        '9837812580',
        'JZZ698151AA',
        '31471746',
        '31423573',
        'JZZ698151AD',
        '45251TEMM00',
        '1C0945511ARDW',
        '77367417',
        'GPKIT2Q0698151R2QB615301E',
        '34216774692',
        '479007380R',
        '89047744',
        '4720109680',
        '5Q0698151AF',
        '8U0698451E',
        'CAPA3083',
        'WHT003861A',
        '583022SA10BI',
        '51918228',
        '52093015',
        '31471816',
        '19380538',
        '517123E500',
        'DB392C204BD',
        '34216796741',
        '32300123',
        '77368510',
        '30793540',
        '06455MGSD32',
        '31341327',
        'JZW698151AT',
        'GPKIT2X7X0721365',
        '41060EB325',
        '34116778320',
        '4185408',
        '155253709',
        '1BP30892AA',
        '1631831780',
        '5Q0615601D',
        '479109303R',
        '6EA711301LDAF',
        '08233LUB020',
        '41300102',
        '432000787R',
        '479101292R',
        '52093016',
        '8W0698451S',
        '517121M000N',
        '34218569415',
        '2K5698451B',
        '5U0927753',
        '479106473R',
        '7702295650',
        'JZZ698151AH',
        '51918282',
        '1K60A44150',
        'WHT003860B',
        '479104822R',
        '581011MA01BI',
        '597601F000DS',
        'DG9Z2200F',
        'WHT003862A',
        'G3MJ2A315AA',
        '4720109240',
        '430432163R',
        '479501850R',
        '410600756R',
        '581013FA00',
        '5K0698151',
        '51918284',
        '51957509',
        '1BP31011AA',
        'H0645GCS305',
        '584111S000',
        '2H0698545',
        'K68412557AE',
        '425440',
        '8W0698151AG',
        '1673370680',
        '39103796',
        '583112SA30',
        '6001551097',
        '77367084',
        '364024124R',
        'GPKIT2X2QB615301E',
        '46833807',
        '584113X300',
        'NR3Z2001A',
        '2QB609721AG',
        '8W0611775C',
        '2H0615301D',
        'GPKIT2X5Z0615301B',
        '4285006B',
        '34216885529',
        '19348422',
        '46814965',
        '7701049980',
        'GPKIT2X1636506280',
        '31341301',
        'GPKIT2X1S0615301C',
        '7093299',
        '7090444',
        'MB2A1125BA',
        '7090132',
        '5Q0698451C',
        '3Q0698451M',
        '1680791780',
        '34216887409',
        '30793093',
        '46831042',
        'JZW615301E',
        '927001Y300',
        'GPKIT2X2H0615301D',
        'JZZ698302D',
        '7090134',
        '2S6Z2A713CA',
        '58101A7A00N',
        '1J0609734',
        '410604979R',
        'EV612A315AA',
        '8W0698151BB',
        '583022SA10',
        '4M0698151T',
        '1S0615301L',
        '7087529',
        '3Q0698451S',
        '30742030',
        'GPKIT2X2QB615301D',
        '52065440',
        '7098021',
        '31341331',
        '22907156',
        'GPKIT2X52004363',
        '95459860',
        '7701069126',
        '5U0615301B',
        '51918271',
        '581012FA01',
        '26312031',
        '5U7615601',
        'H3BZ2M110B',
        '440015394R',
        '474013285R',
        '58101N7A20',
        '1613259380',
        '581012JA20',
        '30769113',
        '1612433780',
        'GL3Z13480A',
        '5Z0698151B',
        '5841139600',
        '1636411180',
        '32287431',
        'JZW615301M',
        '2QB711303A',
        '77364859',
        '32373126',
        'K68453097AB',
        '598302T500',
        '425234',
        '735447011',
        'JB3Z2078P',
        '46463910',
        '581013EE01',
        '517123K010',
        '52169435',
        '58101A5A00',
        '52145198',
        '5Q0615301H',
        '1K0698451G',
        '360109038R',
        '45022TJ0M01',
        '8W0698451BJ',
        '52169232',
        '5QN615301',
        '41300053',
        '1636487380',
        '7098015',
        '2H6615301',
        '7700429418',
        '1K0698151A',
        '410607115R',
        '8V0698647',
        '919203J100',
        '77368511',
        'D60105RB0A',
        '7701205740',
        '5Q0698451P',
        '4545L0',
        '7701205518',
        '4243102380',
        '425379',
        'CN1Z13A613E',
        '36012488',
        '2GX615301',
        '58101D3A11',
        '5U7609721B',
        '19349321',
        '4230707',
        '265900021R',
        '956713E310',
        'XS6C1125AA',
        '52059145',
        '26349884',
        'K68461656AC',
        '45251K31901',
        '7098036',
        '4545J0',
        '58302N7A10',
        '51872259',
        '7090175',
        'GPKIT2X5Z0615301BB',
        '7088952',
        '45022T2GA01',
        '4408208',
        '584112C000',
        '9805260180',
        '34116858910',
        '581013LA10',
        '93320433',
        '410605961R',
        'GPKITDISCOSDIANTBMW640',
        '1J0615601C',
        '2QB614105K',
        '425443',
        '1636506280',
        '365306778R',
        '8660089527',
        'JZZ698451AC',
        '1BP30888AA',
        'MB0A2K021FA',
        '4578P1',
        '3834522',
        '583022LA00',
        '517121S300',
        '1647873680',
        '34106887529',
        '4351202350',
        'WHT003858B',
        '1J0698151E',
        '34106889266',
        '435120D160',
        '45251KVSM20',
        '425484',
        '581012TA20BI',
        '6001551613',
        'CV6Z2200A',
        '581013VA70BI',
        '8200618712',
        'GPKIT2X5Q0615301H',
        '364028Z311',
        '34116865713',
        '34116792217',
        '34112284869',
        '581013VA50BI',
        '51712C1000',
        '2H0927807A',
        'BK3Z2282G',
        '2N152K021CA',
        '80A698451R',
        '5Z0615301D',
        '1643068980',
        '7701205596',
        '32373175',
        '440263346R',
        '8K0698151F',
        '34216768471',
        'K05154237AA',
        '4G0698451L',
        '58101B2A20BI',
        '2H6927803',
        '365310042R',
        '5Q0698151F',
        '6R0698151A',
        '583022EA00',
        '34216887576',
        '5L552001AA',
        '460114537R',
        '34218855013',
        '7P6945087B',
        'GPKITDISCOSDIANTBMWX5M',
        '479013DA0A',
        'J2GZ2078B',
        '58101A7A00',
        '364003137R',
        'JZW698151B',
        '1610918480',
        '410601061R',
        '19349196',
        '479005846R',
        '34216868940',
        'K68029263AA',
        '8K0698451A',
        '364007808R',
        '31471039',
        '31400569',
        '440603558R',
        '96FB1125AB',
        '581013SA20BI',
        '6001547907',
        '442088542R',
        'K05080868AC',
        '8200274801',
        '1431936500',
        '8201464598',
        '583021FA50',
        '3Q0698451C',
        '31476723',
        '35218392702',
        '98550706',
        'GPKIT2X5U0615301E',
        '432026294R',
        '441003419R',
        '5Q0698151B',
        '1643078380',
        '581012SA51',
        'GPKIT2X5Z0615301D',
        'WHT003863A',
        '432005347R',
        '5U0711303CQA4',
        '479011JY0A',
        '34216886479',
        '146504KV0A',
        '411619LH0B',
        '34116850568',
        '364005799R',
        '7700424929',
        '32373165',
        '7N0698451A',
        '15835854',
        '31499906',
        'JZZ698302C',
        'GPKIT2X5U0927753',
        '6001551030',
        '7701208037',
        'H0645KPP902',
        '462423SG0A',
        '1JE615601',
        '45235TR0E01',
        'GN152001BA',
        '8L552001AA',
        '8W0698451AP',
        '06455KCW425',
        'JZW615301D',
        '8U0698151J',
        '7701206328',
        '5WA698151Q',
        '100221834',
        '5Q0612041BB',
        '34216778168',
        '583023RA00',
        '93380715',
        'GPKIT2X2QB615601B',
        '8200416683',
        '155254430',
        '52148329',
        '06455KWT922',
        '7087875',
        '479503770R',
        '583022BA20',
        '1608179480',
        '34216859917',
        '1BP30898AA',
        'MB9A2K021FB',
        '583022FA00',
        '517122C000',
        '7700429113',
        '4N0698451J',
        '432009822R',
        'GPKIT2X34116860907',
        '03L145100H',
        '956702F000',
        '34106859181',
        '34106879122',
        '2Q0698151G',
        '919203J000',
        '1612435780',
        'BT4Z2001B',
        '956701Y300',
        '3C0698151J',
        'GPKIT2XZBC609657',
        '92245928',
        '4254C3',
        'J2GZ2078A',
        'GPKIT2X424966',
        '06455K31902',
        '585101S300',
        'GPKIT2X402069LH0A',
        '583022SA00BI',
        '06435K40F11',
        '47901EL00A',
        '4605B541',
        '7087659',
        'GPKIT2X2GX615301',
        '6Q0927808B',
        'K68052369AB',
        '7701209077',
        '7701207198',
        '584111H300',
        'FR3Z2455AF',
        '581012VA00',
        '92245929',
        '479006271R',
        '46554489',
        '1BP30855AA',
        '51712R1000',
        '58510A7100',
        '584112B000',
        '479103DA0A',
        '1K0615301M',
        '6001547168',
        '479005438R',
        '19314698',
        '364002234R',
        '30742029',
        '34112284902',
        '8200404460',
        '1S0698151D',
        '4S552K021AA',
        '479103706R',
        '1402812300',
        '52044147',
        '479006272R',
        '9819544080',
        '34219808172',
        '8671016747',
        'E3BZ2C405F',
        '77367145',
        '357609707A',
        '1574702700',
        'GPKIT2X1JE698649A',
        '45251TJ0M00',
        '7701207697',
        '4861C7',
        '479505302R',
        '31400526',
        '365303697R',
        '45022T7TM51',
        '581013LA11',
        '80A615301G',
        'GPKIT2Q0698451B51R',
        '34106859182',
        '06455KSSJ31',
        '1577134100',
        '30742031',
        '402067025R',
        '45022S5JM00',
        '31423572',
        '34216778327',
        '479006554R',
        '40206AZ61A',
        '7L0907637C',
        '479000527R',
        '34116785669',
        '7701207034',
        '31687502',
        '47900EL00A',
        '6001551410',
        '460111938R',
        '1315016900',
        '45251T14M01',
        '402066693R',
        '77367149',
        'MB1A2K021FB',
        '305611301B',
        '1J0858373E2QL',
        '363722952R',
        '440606011R',
        '479104225R',
        '517121S000',
        'K68120777AA',
        'D1MFM4KJ1J',
        '402066300R',
        '5Q0698451AG',
        '424946',
        '34216792227',
        '581102S550',
        '45022TEMM11',
        '479007700R',
        '34116860907',
        '34219811539',
        '32373149',
        '19349296',
        '52144934',
        '364009228R',
        '06435MEJ026',
        '8200735314',
        '7701206339',
        '5Q0612041BT',
        '583022SA70',
        '581902KA10',
        '06435K97N01',
        '956702W000',
        '34216873093',
        '460118760R',
        '25940447',
        '34206885600',
        'WHT003856C',
        '32300259',
        'JZW615301G',
        '1136091631',
        '4605B987',
        '440607960R',
        '6001550928',
        '8200015498',
        'K68052386AA',
        '4243102090',
        '5810107A00',
        '4S452K021AA',
        '410607716R',
        '583023SA20',
        '7701208036',
        '586104E001',
        '1342344500',
        '5Z0615106F',
        '8E0615301A',
        '34116851269',
        '1343650600',
        'K05105514AA',
        '34118569410',
        '410602596R',
        '4M0615301AN',
        '45022TEMM00',
        'NZ6Z2C026B',
        '5K0698451B',
        '51891388',
        'GPKIT2X1S0615301L',
        '32246008',
        '7700438266',
        '11667629612',
        '440016059R',
        '410608710R',
        '479505873R',
        '4605B481',
        '432001539R',
        '34218527518',
        '8V1927225',
        '7700433472',
        '7701207829',
        'GPKIT2X8660089543',
        '460114036R',
        'GPKIT2X211611483',
        '360107552R',
        'EJS12D250BA',
        '472103385R',
        '402065XA0A',
        '1553815700',
        '06435KCZ020',
        '06455K67951',
        '583023XA30',
        '06455K43911',
        '410015708R',
        'K68532949AB',
        '479503610R',
        '41300197',
        '34216885187',
        '8V0615423D',
        '34206898494',
        'D40609HM0B',
        '34116883469',
        '31423652',
        '440601186R',
        '2H6927804',
        '4351650010',
        '41300241',
        '6N0609589',
        '8200274800',
        '6Q0698151A',
        'K68192439AB',
        '581013LA11BI',
        '462101997R',
        '4249L2',
        '956813J100',
        '34116785670',
        '7701208176',
        '34106884263',
        '06455K20912',
        '441006176R',
        '474620',
        'K68225301AB',
        '4229808',
        '6001547169',
        '402066768R',
        '410604386R',
        'CA350313',
        '8200862228',
        '34116776785',
        '402064151R',
        '58101A7A01',
        'ML1Z1125D',
        '4273908',
        'K68035022AD',
        '4351250250',
        '7098043',
        '5Q0611701C',
        '31687654',
        '1266517700',
        '265904926R',
        '34116889585',
        'FS11125AA',
        '0K9A03329Z',
        '410018218R',
        'H0645KRM861',
        '8200247043',
        'GR3Z2001G',
        '4185208B',
        '402061200R',
        '8200694083',
        '04465470708A',
        '34211542640',
        '432004255R',
        '7701043913',
        '8200212695',
        '13511494',
        '440115485R',
        '1554106400',
        '8200694080',
        '1629060680',
        '11667551594',
        '5C0615601A',
        '58101A9A10BI',
        '479002290R',
        '432065XA0A',
        '5Q0612181C',
        '59810C5100',
        'K52109938AB',
        '34117845749',
        '581442C000',
        '06455KREK01',
        '8200694124',
        'WHT003856D',
        '3801815000',
        '1K0615425AC',
        '45262T0AA01',
        '597701F000DS',
        '34117728945',
        'KR3Z1125G',
        '463159290R',
        '7092542',
        '3C0698151L',
        '4098211',
        '9824805180',
        '11667611336',
        '63218355883',
        '77367719',
        '5U0698151D',
        '2H6698451',
        '410015070R',
        '166070500R',
        '8200292929',
        '410111495R',
        'K04766479AD',
        '6001551411',
        '42566753',
        '1393332000',
        '364022480R',
        '8200027455',
        '93BX1125CB',
        '34119444023',
        '58302B2A00BI',
        '06455KZL932',
        '432066LA0B',
        '583023JA00',
        '32373185',
        '7088950',
        '7701044850',
        '06455K40F12',
        '410601334R',
        '1342346700',
        '06435KREG32',
        '919211S000',
        '8200420641',
        '4M0698231A',
        '441008001R',
        '32246007',
        '4630A478',
        '94746016',
        '1341841100',
        '30645222',
        '7087742',
        '479101624R',
        '1431936600',
        '7700432012',
        '440603511R',
        '34112284901',
        '5U0611053C',
        '464005544R',
        'GPKIT2X5QX615601D',
        '7700424470',
        'K05105573AB',
        'MR3Z2001C',
        '06435KSPB02',
        '410601903R',
        '9823523780',
        '582511M300',
        '465107702R',
        '479003252R',
        'K05085823AD',
        '8200212698',
        '4404608',
        '5G0698459',
        '1692881580',
        '34356790304',
        '265900027R',
        '35356TBAA01',
        'K68159526AB',
        '1432904800',
        '6001551782',
        '7701205741',
        '7089554',
        '7088951',
        'GPKIT7089967',
        'GPKIT7088534',
        'GPKIT5961814',
        'GPKIT7088138',
        'GPKITJZZ698151AEDISCOS',
    ],
    Luzes: [
        'CA542480',
        '8201736280',
        '5U5945701',
        'CA542479',
        '51880942',
        '51855421',
        '46834519',
        '7090929',
        '265545106R',
        '265907079R',
        '260290441R',
        '51968073',
        '265507871R',
        '2GP949101',
        '9821352180',
        '53490290',
        '265558281R',
        '51842800',
        '9823950280',
        '265509719R',
        'N10251002',
        'AB3913404FA',
        '255409836R',
        '52182585',
        '7095336',
        '92402R1600',
        '5Z0945097B',
        '1JE945095F',
        '63217350696',
        '7084613',
        '8200418356',
        '7095337',
        '265554508R',
        '265556KG0A',
        '265540003R',
        '265101HM4B',
        '261603TH2A',
        '7090935',
        '52200729',
        '8200700047',
        '265101HA0A',
        '11587690',
        '7090345',
        '100180683',
        '264709249R',
        '11008790',
        'N10445602',
        '9821349980',
        '924021S500',
        '6EC945095B',
        '100215053',
        '8330B209',
        '7088932',
        '25320AX10A',
        '5Z9945095E',
        '453440',
        '100177560',
        '255409761R',
        '265553BG0A',
        '11062790',
        '26555JT30A',
        '92403A7310',
        '6000626719',
        '7090930',
        '925014E001',
        '5W6945102',
        '9838765580',
        '46742452',
        '812700K060',
        '7095338',
        '261608080R',
        '9824578480',
        '1JE945096E',
        '52137791',
        '6362N6',
        '265850007R',
        '261604623R',
        '1JE945096F',
        '84277409',
        '14151095',
        '51842799',
        '924021M020',
        '52049040',
        '265908613R',
        '10774590',
        'K05182419AH',
        '7090335',
        '46745558',
        '5U7945095F',
        '924022E020',
        '8200474327',
        '92403M4300',
        '8330B210',
        '30763954',
        '938104DR0AQQK',
        '2626189965',
        'KL0000D5S',
        '5Z6945095J',
        '7090933',
        '265905RA0A',
        '51779767',
        '100176278',
        '265109646R',
        '265503BG0A',
        '924014J000',
        '819360D010',
        '265908Z300',
        '52023546',
        '5U6945095E',
        '265552652R',
        '5U6945097A',
        '7711213335',
        '8330B213',
        '52154368',
        '262969B91D',
        'MN157914',
        '52147197',
        '100266212',
        '8330A943',
        '52023474',
        '7091017',
        '265559487R',
        '52168431',
        '265558195R',
        '265595761R',
        '52168432',
        '265501482R',
        '1635861680',
        '6EC945094B',
        '2H1945096P',
        '51798256',
        '5Z0947105EY20',
        '262920539R',
        '51793060',
        '261B00001R',
        '7701042832',
        'PC5230K00G',
        '265552939R',
        '781145587R',
        '52182584',
        '50161609',
        '50161608',
        '52154365',
        '52128029',
        '812700D080',
        'MN157913',
        '71500292',
        '5U6945096D',
        '2GP827577BFOD',
        '53492158',
        '8155102350',
        '2C5Z13N021AA',
        '989757',
        '90981WD015BR',
        '1BP31858AA',
        '71748261',
        '84786459',
        '3M7Z13466A',
        '265507684R',
        '31290593',
        'N10724401',
        'N10815201',
        '6001551365',
        '262997321R',
        '93345894',
        '52154366',
        '51964232',
        '265550674R',
        '52177776',
        '8200171472',
        '100256699',
        '51873495',
        '8155198060',
        'AB3913405FA',
        '51884712',
        '2GP827577FOD',
        '51968071',
        'N10130001',
        '1635861580',
        '6EA945095E',
        'MB606747',
        '6EC945095',
        '815910D560',
        'E3B513405AJ',
        '7090926',
        '31689680',
        'N0177192',
        '51842113',
        '52023547',
        '51797164',
        '265553SG0A',
        '265502140R',
        '94743726',
        'E4BZ13405M',
        '51850165',
        '51718013',
        '33500TR0E01',
        '46845736',
        '265555355R',
        '1864955009H',
        '7089934',
        'MU001751',
        '26344409',
        '265553100R',
        '265509LF0A',
        '8200917487',
        '26313441',
        '815610A110',
        '51980270',
        '815510A110',
        '9838766880',
        '815600K190',
        '265905RA1A',
        '938103K000',
        '5U7945105A',
        '265906642R',
        '928502E000EZ',
        '31446790',
        '94743727',
        '7090934',
        '8330B214',
        '51842802',
        '6EA945257A',
        '51766508',
        '815610K460',
        '51957750',
        '8155002350',
        '265550041R',
        'CA511938',
        '3BD9471052EN',
        '96BJ13009AA',
        '9098113043BR',
        '924012G610',
        '815610D650',
        '52005011',
        '52168433',
        '265506749R',
        '5W6945101',
        '265505EF0A',
        '6350KZ',
        '5Z6945701B',
        '6350CQ',
        'F1EB13A603AB',
        '5U6945096AF',
        '96830989',
        '31655916',
        'N10320101',
        'N10529701',
        '6EA945096D',
        '850422275R',
        '6350KY',
        '924101Y100',
        '8V5945069',
        '927001D000',
        '51884715',
        '5U7945095J',
        '5U7945096A',
        '265553665R',
        '6816704',
        '2GP945096',
        '269513S500',
        '6QE945096D',
        '815600K310',
        '815610K470',
        '2GP945095',
        '71718153',
        '2GJ945095A',
        '31655915',
        '621695',
        '5Z6945097Y20',
        'PZV7014211',
        '1BP31646AA',
        '31446791',
        '815510K460',
        'KL0007440NA',
        '52023468',
        '5U6945095BD',
        '265455EE0A',
        '5U5945095AC',
        '265500038R',
        '8330A944',
        '8200917488',
        'GPKIT2X2119452415',
        '52125023',
        '33550TR0E01',
        '1BP31857AA',
        '265509LF0B',
        '5U5945096B',
        '265501240R',
        '26555AZ60A',
        '815500K550',
        '7702266010',
        'LBRW2XPSX0',
        '1S7A13009DA',
        'N10529501',
        '1864765009S',
        '100182150',
        '51884710',
        '26313438',
        '265503YU0A',
        '265500023R',
        '265554694R',
        '52180866',
        '815510K110',
        '815610DB10',
        '265596S300',
        '8330B002',
        '52177774',
        'BK3113404AG',
        '100222003',
        '51718012',
        'E3B513404AJ',
        '51854694',
        '9808154980',
        '6Q6945095AB',
        '265552690R',
        '92405D9500',
        '100258950',
        'N10256401',
        '6Q0945511',
        'KL0PSX24W',
        'N0177322',
        '14144190',
        '7702266006',
        '9098113075BR',
        '6001551360',
        '25320AX00C',
        '9098113058BR',
        '50152815',
        '265555RF0A',
        '9674308980',
        '52087955',
        '265503SG0A',
        '1SB945095C',
        '1380673080',
        '6EC945093C',
        '8200700046',
        '7095199',
        '265505RF0A',
        '26344410',
        '2H1945095J',
        '7091016',
        '50152816',
        '92403C5110',
        '92401D9600',
        '265551026R',
        'GPKIT1JE945095F96F',
        '924603W220',
        '1358336300',
        '8336A073',
        '927502P000',
        '8499910530',
        'N0177382',
        '262711454R',
        '265509406R',
        '52128913',
        '52023470',
        '155257959',
        '265559LF0A',
        '51914848',
        '815600K271',
        '8200474328',
        '52023472',
        '1BP31850AA',
        '265506747R',
        '265553462R',
        '265559AG0A',
        '924502P600',
        '815500K070',
        '51873494',
        '924062S020',
        '265551483R',
        '5U6945096AG',
        '7095200',
        '5U5945095B',
        '924023S120',
        '8156102C20',
        '265550368R',
        '6EA945095',
        '52137181',
        '8W5945069A',
        '14144690',
        '26313437',
        '5U6945095D',
        '927002P000',
        '265504940R',
        '52180865',
        '265555JL0A',
        '52222971',
        '924011F580',
        '267175121R',
        '9098113046BR',
        'KL0007440',
        'KL0000H11',
        '2H6945097N',
        '9818232480',
        '96830990',
        '8200417349',
        '52168434',
        '6EC945093',
        '52195823',
        '8330B173',
        '9825776180',
        '51854695',
        '8200957874',
        '80A945069',
        '52137790',
        '815610K310',
        '265508391R',
        '5U7945096H',
        'GPKIT2X6Q5943021',
        '52134656',
        '32228914',
        '51835013',
        '265550023R',
        '8V4945095D',
        '265557337R',
        '92401B2020',
        '52087957',
        'E4BZ13404M',
        '265550100R',
        '46834518',
        '8K9945096B',
        '51822766',
        '8U0945094P',
        '1380672080',
        '8200417351',
        '52154367',
        '924202P600',
        '78140EL130',
        '95469056',
        '850425063R',
        '9240107620',
        '51789754',
        '52137180',
        '815500K261',
        '924052P100',
        '1359118000',
        '9802340380',
        '815610D780',
        '1BP31873AA',
        'PV59902001',
        '264305TA6A',
        'N10320102',
        '14148090',
        '1JE945095E',
        'GPKIT2X5X0943021',
        '6350GH',
        '5Z0945095C',
        '52195824',
        '51968072',
        '265507403R',
        '2G5945208A',
        '51798257',
        '52125022',
        '265555RA0A',
        '26550JT30B',
        '31689689',
        '17A945093C',
        '6EC945096',
        '265509162R',
        '5U5945095AB',
        '6351KZ',
        '7090675',
        '92402D9500',
        '6325G3',
        'K68346773AB',
        'CA541268',
        '52150628',
        '265559LF0B',
        '26510EB71A',
        '265504889R',
        '100256700',
        '51858823',
        '26555JT30B',
        '9841899480',
        '8159102D40',
        'K68517552AA',
        '265557849R',
        'GPKIT5U7945095G',
        'K68517557AA',
        '8R0052001D',
        '265501HL0A',
        '52222970',
        '5U6945096P',
        '52151812',
        '2119452415',
        '265501249R',
        '5Z6807591C',
        '6EA945096',
        '781D09410R',
        '2H1945095P',
        '33500T9AT01',
        '6350GJ',
        '6EA945095C',
        '0K2A151150',
        '52147198',
        '8200003263',
        '7700844706',
        '6R5953507B9B9',
        '7700410754',
        '92700D9000',
        '7700308721',
        '1354178000',
        '14148190',
        '265902759R',
        '9913221210BR',
        'N0177234',
        'N0177637',
        '10781890',
        '9808158480',
        '5U7945095K',
        '92401D9500',
        '51914847',
        '51797165',
        '265555726R',
        '265500292R',
        '8330A565',
        '92402A9530',
        '9823216780',
        '31689690',
        '5U5813319AGRU',
        '8159102B30',
        '92404C5110',
        '924011S800',
        '265500504R',
        '1JE945093F',
        '815810D461',
        '924102K010',
        'GPKITLANTERNASKOMBI',
        '815910A080',
        '8R0945093A',
        '265550264R',
        '51997841',
        'GPKITLANTERNASSANDERO',
        '261353TA0A',
        '8156142171',
        '815500K190',
        '8330A566',
        '924021F011',
        '5U6945095BB',
        '924021F530',
        '8155102800',
        '96831068',
        '26344411',
        'G81409KEMB',
        '2GJ945096A',
        '26550JT30A',
        '53498119',
        '52159140',
        '814560K040',
        '52134657',
        '261659450R',
        '17A945095A',
        '265505271R',
        '924022P640',
        '26344408',
        '5U7945096G',
        '5GM945096',
        '924063W010',
        '261605RE0A',
        '8W9945076',
        '1354178700',
        '52177775',
        '5Z9945093L',
        '52038977',
        '265505RA0A',
        '8155102C20',
        '52128914',
        '8200150625',
        '22842244',
        '265551016R',
        '8159102650',
        '6001550080',
        '51835011',
        '52147829',
        '265506738R',
        '3M7Z13466B',
        'K55112684AA',
        '5NA945207F',
        '92800C5110WK',
        '32228915',
        '265505530R',
        '265555R01A',
        '265555RF0B',
        '5C6945095E',
        '7700428321',
        '1S0945095G',
        '96252CJ00A',
        '92402D9010',
        'GPKIT5U7945095J96G',
        '26550AZ60A',
        '1C0945511ARDW',
        '52005016',
        '6EA945096C',
        'N91008001',
        '53350311',
        '52149191',
        '14152090',
        '31217288',
        '6216E2',
        '71777147',
        '63117359245',
        '10857790',
        '7701038624',
        '71749250',
        'N0177386',
        '63217160897',
        '9098113102',
        'KL000921E',
        '68000056',
        '52100272',
        '7701049263',
        '1864310009N',
        '7L6945511',
        '9098115021BR',
        '4G0907697F',
        'MF820513',
        '636500K771B0',
        '989842',
        '6001551237',
        '924062P100',
        '51789756',
        '1JE945094D',
        '31323034',
        '5GM945095',
        '94707065',
        '9841899080',
        'CA541269',
        '80A945069B',
        '924024F000',
        '265508059R',
        '850429925R',
        '32228827',
        '265508073R',
        '2G5945307A',
        '9808127580',
        '51718011',
        '1328427080',
        '265504304R',
        '8155102810',
        '53498120',
        '812700K010',
        'K05182418AH',
        '265559212R',
        '6001550843',
        '8158102650',
        '815810A070',
        '781D12791R',
        '815510DA10',
        '52147828',
        'GPKIT5U794509596',
        '9811028080',
        '265505JL0A',
        '924103W000',
        '1328428080',
        '265500033R',
        '5U5945096Q',
        '51953205',
        '6001550040',
        '815510K300',
        '8331A161',
        '9811838280',
        '5U7945096F',
        'GPKIT5U7945095F96F',
        '924102P640',
        '8330B001',
        '7091597',
        '8331A180',
        'PC4100K001',
        '924011Y100',
        '924012E020',
        '815500K320',
        '92405D9600',
        '5C6945093A',
        '265505617R',
        '5U0945404',
        '924032T120',
        '52109464',
        '735417807',
        '924011Y310',
        '8K5945095B',
        '51931735',
        '924052P020',
        '265405EE0A',
        '93311059',
        '924021Y100',
        '1S0945096G',
        '265500152R',
        '92401C5600',
        '94740317',
        '52178151',
        '8156102D80',
        '9808243180',
        '5X0943021',
        '8201070518',
        '52151811',
        '8K5945093AC',
        '8159142062',
        '33500TEKM01',
        '33550T9AT01',
        '5U7945095',
        '1361122500',
        '924201Y100',
        '265557086R',
        '267177Z001',
        '9808127680',
        '2H1945096J',
        '52178150',
        '924021M520',
        '33500TJ0M01',
        '51789757',
        '53483559',
        '51953113',
        '1354179000',
        '92402D9110',
        '8158102B30',
        '5C6945207E',
        '850422056R',
        '924022G610',
        '34155TEKM01',
        '63216937458',
        '33500T00T01',
        '8200194580',
        '52066813',
        '2119452311',
        '5U6945096Q',
        '5U7945095G',
        '26393730',
        '5U5945093F',
        'BK3Z13405B',
        '6350Q3',
        '815510DA00',
        '6QD945093A',
        '52059145',
        '9240207620',
        '924031S100',
        '31395844',
        '8200171478',
        '6EA945095B',
        '265554187R',
        '9240207000',
        '265552477R',
        '262925502R',
        'N01775311',
        '267179B90E',
        '4G0052133J',
        '30640997',
        'N0177612',
        'N0177632',
        '928702F00087',
        '14150190',
        '7701066603',
        '31346791',
        '11028490',
        '02T945415P',
        '9098113090',
        'B629689947P',
        '7702266014',
        '50512681',
        '4G0052130G',
        '13503380',
        '927001Y300',
        'GL3Z13480A',
        '261655RE0A',
        'GPKIT2XN10320101',
        '53350310',
        '6777410',
        'KL09005HL',
        'KL000WY5W',
        '1315199000',
        'N0177535',
        '7700429418',
        '51953114',
        '13503397',
        'LBRW2XH700',
        '7082316',
        'CN1Z13A613E',
        '265900021R',
        '908105RC0B',
        '3B0947415B',
        '1864761566N',
        '928704D500TW',
        'GPKIT6EA945095B96B',
        '31323035',
        '80A945076B',
        '52146735',
        'DS7Z13404B',
        'C1BB13404BC',
        'G8141ET0MA',
        '1K5945094G',
        '924031S000',
        '5X7945111E',
        '51728393',
        '924011F011',
        '8156102810',
        '8155142202',
        '5U6945096',
        '52088684',
        '52060581',
        '265108991C',
        '52146736',
        '51884709',
        '51980269',
        'K68517553AA',
        '80A945094B',
        '5Z0945094C',
        '8K5945093K',
        '924033L530',
        'GPKIT2X1JE943021',
        '815610DA00',
        '51850164',
        '265551HL0A',
        '265505730R',
        '265552437R',
        '6362Q0',
        '6Q6945096AB',
        '93311058',
        '1BP31648AA',
        '261603141R',
        '781418475R',
        '5U5945095A',
        '809016704R',
        '815610K371',
        '6EC945258',
        '924011F530',
        'K55112684AB',
        '52150627',
        '5N0945112D',
        '52088685',
        '93311064',
        '925011F000',
        '924041S800',
        '265505RA2B',
        '8P4945096E',
        '5X7945112E',
        'DS7A13465AA',
        '5G0945308F',
        '3B0947291BY20',
        '52119670',
        '7701040712',
        '1BP31651AA',
        '7700822136',
        '924102E100',
        '7700308717',
        '781D01320R',
        '63132462095',
        '42750548',
        '8200526426',
        '6EC945094',
        '5Z0945095B',
        '6350T2',
        '6001550039',
        '52150625',
        '51931738',
        '5Z6945095H',
        '6EC945257',
        '5U6945095',
        '26699754',
        '100196293',
        '8200150620',
        '6001551235',
        '3C0953041L',
        '1SB945095',
        '31395845',
        '7700806228',
        '2H1945095N',
        '34150T9AT01',
        'K55112683AD',
        '924062P020',
        '5244195',
        '7701040713',
        '26555EM00A',
        '6351GJ',
        '80A945075B',
        '2H5813320GRU',
        'FT4Z13404D',
        '815910D550',
        '1BP31653AA',
        '80A945069D',
        '1BP31650AA',
        'C1BB13405BC',
        '1J6945096R',
        '100222002',
        '924021S100',
        '7700353025',
        '92403B0730',
        '5U6945096F',
        '31689679',
        '924012P640',
        '2G0945208B',
        '265508750R',
        '92402D9600',
        '7700428055',
        'GPKITMOLDURASLANTERNATRASGOL',
        '51997843',
        '7700352700',
        '6351JC',
        '9672294080',
        '33550TEKM01',
        '1JE945096B',
        'GPKIT2X21194523112119452851',
        '5G0945207',
        '93353JR030',
        '5U7945095C',
        '6000626717',
        '8156002350',
        '52119671',
        '1SB945257',
        '8336A102',
        '8200103292',
        '8W5945070A',
        '8159060141',
        '8200150621',
        '6001550347',
        '26699753',
        '5G0945307P',
        '52147196',
        '2H1945096C',
        '92406D9600',
        '1K5945093G',
        '8V0949102',
        '5NL945095A',
        '8200142683',
        '7700433744',
        '92401D9010',
        '17A945096A',
        '768F20007R',
        '924023E530',
        '34150TEKM01',
        '265500140R',
        '8156142120',
        '7700428054',
        '5Z6945096D',
        '63217376473',
        '925012E000',
        '1194060800',
        '8200150619',
        '8200150623',
        'D2BZ13404D',
        '5U5945096P',
        '33550T5NM01',
        '1315199200',
        'AR3Z13404B',
        '52065117',
        '265509696R',
        '815900K091',
        '5U5945093G',
        '781405101R',
        'BK3Z15442G',
        '8V4945075',
        '7700353024',
        '92402C5600',
        '781416892R',
        '3CN947105AWHI',
        '31395929',
        '924A3R2000',
        '7700433751',
        '2G5945207A',
        '8330A623',
        'K68211290AB',
        '335503P0T01',
        '7701478476',
        '1325242700',
        '26550ZT50B',
        '1K8972928',
        '989777',
        '1361122600',
        '95730BX000',
        '6001550842',
        '52159139',
        '8330B102',
        '925011F100',
        '63215A11EC3',
        '2G0945207C',
        '924022W135',
        '2GJ945094',
        '924023X010',
        '8200526427',
        'F1EB13A602AB',
        '26550EB71A',
        '815810A090',
        '7090676',
        '8200633416',
        '5U0945403',
        'D2BZ13405D',
        '815800K030',
        '80A945094',
        '8331A162',
        '8V5945093H',
        '8200633417',
        '94707066',
        '5U5945094C',
        '34150T00T01',
        '6EA945096B',
        '94740318',
        '5U7945095B',
        '92406D9500',
        '31108932',
        '265555RA2B',
        '94740320',
        '924022B020',
        '6362P9',
        'BK3Z15442E',
        '265503YU5A',
        '1SB945096',
        '926013F000',
        '5U7945096',
        '92850A9100GBU',
        'GPKIT2X2119452311',
        '5NA945208F',
        '265503724R',
        '768F30007R',
        '7700410518',
        '92402B2120',
        '850425632R',
        '2119452851',
        '5NA945307D',
        '3AE945095F',
        '7703097171',
        '4G0052133G',
        '261342255R',
        '71750483',
        '32392104',
        '7P6945087B',
        'N0177186',
        '1286317',
        '8200699691',
        '63172751293',
        '8167176010',
        '364005799R',
        'GPKIT2X1SB943021',
        '63148091922',
        '7701048957',
        '260103433R',
        '1BP31870AA',
        'KL002825W5W',
        '5N2Z13466A',
        '63319365481',
        '938104DRWBQQK',
        'N0177326',
        '7703097176',
        '262991837R',
        '14145090',
        '989829',
        '02K945415K',
        '2GA947105TTCJ',
        '265550016R',
        '5G1947106ADYS',
        '67800605',
        '31443745',
        '1325242900',
        '7701041110',
        '7702266071',
        'N0177636',
        'CA541688',
        'GPKIT2X6340A3',
        'GPKIT2XN0177612',
        '1864505009N',
        '63217456519',
        '3C8953042',
        '1864935009NN',
        '77515A1A1B2',
        '7701040849',
        'N10721805',
        '4G0052133K',
        '63217313043',
        '4L0959121',
        '34906SMLM01',
        '9098113091',
        'AX549810',
        '1864221007NN',
        '51167324151',
        '1864727009N',
        'BE8Z13N021A',
        '1266517700',
        '265904926R',
        '000971648A',
        '33115SNJM01',
        '2GA947105STCK',
        '2GA947105ACTCJ',
        '7702266011',
        '63217160912',
        'N10320103',
        '5G0941661G',
        '1864421058LN',
        '1SB943021',
        'DJ5313N021AA',
        '5GM947105BWHI',
        'MU001827',
        '63218355883',
        '1674018680',
        '6216H0',
        '90981WF011',
        '8200027455',
        '260608210R',
        'K55112720AB',
        '264302677R',
        '33303TP6A01',
        '9913213050',
        '265590003R',
        'CAPA2460',
        '9098115009BR',
        '3B094710971N',
        '6EA941531BTKK',
        'LBRW2XH100',
        '1864755007LNN',
        'N0177328',
        '9098111062',
        'V04010037E',
        '5G0698459',
        '620724471R',
        '265900027R',
        '17A947105WHI',
        '93BG13009BA',
        '6400G122',
    ],
    Pneus: [
        '2QB601307ADRGY',
        '4740168',
        '076522',
        '4265260102',
        '091083',
        '7711733761',
        '091084',
        '2QB601307SRGY',
        '7702271216',
        'R427BTEMM13',
        '091082',
        '094448',
        'V04011009MPRGY',
        '2QB601307ACRGY',
        'BRPNEU0195',
        '076614',
        '094446',
        '36122465718',
        'APR601307BERBS',
        '7702267145',
        '7092699',
        '7702267208',
        '7702267140',
        'APR601307BGRPI',
        '7702267136',
        'PN40135',
        '1388137080',
        '8E0601313MRCO',
        '4265293002',
        'APR601307BQRPI',
        '2QB601307ADRPI',
        '5N0601307FRCO',
        '7702267137',
        '7702266752',
        'APR601301ANRCO',
        '2QB601307AJRPI',
        '027901',
        'B6125A196D6',
        'APR601307AJRBS',
        '36125A88BB9',
        '5QF601307ADRCO',
        'V04011009HRPI',
        '36122459522',
        'APR601307ZRGY',
        '286010',
        '36122459523',
        'BRPNEU0213',
        'V04011009MGRPI',
        '42751T14P13',
        'APR601307DERPI',
        '7702267203',
        'APR601307TRGY',
        '7092272',
        '36125A11D57',
        '427513M6M03',
        '42751TEMM12',
        '7702267211',
        '7091059',
        '43100042',
        '36122449750',
        'BRPNEU0260',
        '5QF601307ABRCO',
        'V04011009MHRGY',
        'B6122462805',
        '5N0601307DRCO',
        '076526',
        'BRPNEU0252',
        'V04011009LQRLL',
        '36125A11D65',
        'V04011009LPRLL',
        'APR601307GRPI',
        'APR601307AERPI',
        '7094542',
        '7702267141',
        '2H0601307JRCO',
        '7702267215',
        '7089770',
        '7094817',
        'BRPNEU0328',
        'BRPNEU0220',
        'R427BT7TP04',
        '03117550000',
        '076617',
        '4740169',
    ],
    Kia: [
        '877212K000',
        '865901M000',
        '311202K760QQK',
        '976743T100QQK',
        '877122K000',
        '311202S960QQK',
        '93490A4110',
        '0K63B33251A',
        '415101Y000',
        '877112K000',
        '529601M300',
        '597104E000',
        '29110A7500',
        '877222K000',
        '865612K000',
        '85715R2000N',
        '56500A7500',
        '868214E001',
        '863531M010',
        '529102K200',
        '548104T000',
        '868214E021',
        '328002P120',
        '845011F004WKDS',
        'K9965113520',
        '876161M005',
        '54661A7100BI',
        '2521204011',
        '92403A7310',
        '52910M7300',
        '2312402210',
        '555103W000SJ',
        '876261M000',
        '555133N300',
        '311522P000',
        '845601F100EZ',
        '9446007101',
        '58980C1100',
        '548132F000',
        '311202S950QQK',
        '546102T000',
        '546511M310BI',
        '565211H000',
        '319112K700N',
        '976061Y001',
        '252123A500',
        '529103W710',
        '0K71E33047',
        '924021M020',
        '546512G300BI',
        '5631007505',
        '865902T000',
        '553261M000',
        '0K60A33251A',
        '938104DR0AQQK',
        '529101Y350',
        '4379407500',
        '976412S000QQK',
        '432404Y000',
        '934102M611',
        '986103W500',
        '821402K000',
        '628003E200',
        '4311928080',
        '865244E000',
        '52910D9470',
        '924014J000',
        '876211M000',
        'TOTAL5W30',
        '291103W610',
        '597603W200',
        '517123K050',
        '253333W000',
        '52910D9420',
        '252374A100',
        '2304004100',
        '962103W9009P',
        '858714E000GW',
        '264102G000',
        '922011M000',
        '934902P300',
        '551602K301',
        '331003A000',
        '92101A7071',
        '794902P000',
        '367204A001',
        '865512K500',
        '0K63A33061',
        '545803E100',
        '824602P010',
        '555133N200',
        '868113W500',
        '845011F004EZDS',
        '546612F200',
        '92102C5200',
        '3531004090',
        '7949007000',
        '85220D9210ED',
        '824501Y010',
        '921011M510',
        '55274D3000',
        '546611M310BI',
        '249003CBE2',
        '2455202200',
        '214404X020',
        '545841Y000',
        '2651038000',
        '972503W051',
        '263203A100',
        '255104A700',
        '517123E000',
        '865603W000',
        '571001M000',
        '86812B2000',
        '319222J000',
        '311121R000',
        '865133W000',
        '529102P180',
        '4959107010',
        '55300A7200BI',
        '0K79A34011A',
        '8631807000',
        '2531007500',
        '319112G000',
        '9186007200QQK',
        '2630035505',
        '4335032020',
        '367104A710',
        '2521204100',
        '583021DA01BI',
        '81320A7021',
        '2244102230',
        'GPKIT881874E300GW',
        '961603W340WK',
        '86612D9100',
        '264104E400',
        'GPKIT882864E300GW',
        '223114A000',
        '882834E300GW',
        '922021M000',
        '872103W000',
        'GPKIT4VELASIX35GAS',
        '863531F010',
        '215102G050',
        '986202P710',
        '986101Y100',
        '4142639260',
        '571003E050',
        '373402G400',
        '253504E200',
        '52910B2300',
        '663211Y330',
        '961401M110WK',
        '545224E000',
        '824011Y010',
        '868113W000',
        '311123R000',
        '58101D7A10BI',
        '2447025052',
        '8651607500',
        '517154E103',
        '583021DA01',
        '291103W500',
        '863501M010',
        '529102T525',
        '86611D9500',
        '529103W310',
        '546612T232BI',
        '548303E020',
        '944602S900',
        '0K60A32270A',
        '882864E300GW',
        '214434X000',
        '82460D9000',
        '391282BAB9',
        '319114D500',
        '865143W000',
        '2630002503',
        '921012G510',
        '82667D9000',
        '2730102700',
        '553112S011BI',
        '95671D3000',
        '86511B2500',
        'GPKIT5XGJZZ530R2BRA',
        '31120G6700BI',
        '546502P700BI',
        '0K79A16410',
        '54610C5000',
        '55530D3000',
        '86671D9500',
        '87741D9000',
        '0K60D13363',
        '852011D026RU',
        '2521225010',
        'GPKITEMBKIAHYUND10',
        '319224H900N',
        '367103A000',
        '924522K500',
        '577304E050BI',
        '495001Y160',
        '467201M200CR',
        '2731026600',
        '328022K160',
        '957602K000Y1',
        '338134A100',
        '230604A942BI',
        '82130D9000',
        '824813W000',
        '263304A001',
        '311102K700',
        '928502E000EZ',
        '4379407510',
        '814202K030',
        '252122MHB0',
        '311502S100',
        '95720A7501ABP',
        '921012K700',
        '865131M000',
        '93600D9040WK',
        '555103E100',
        '3111107600BI',
        '338003A000',
        '4270023000',
        '866122K050',
        '2142103001',
        '2151323001NN',
        '5835007A01BI',
        '86821B2500',
        '319254E100',
        '0K05418140',
        '2244104050',
        '976412S500N',
        '924012G610',
        '865181Y000',
        '921013R310',
        '581012SA70',
        '967002P000CA',
        '583021MA40',
        '986332J000',
        '234102G010BI',
        '921024D050',
        '876201M000',
        '816672P000H9',
        '546501Y010BI',
        '86511B2000',
        'GPKIT4X1885308060',
        '881874E300GW',
        '597003W100',
        '866112K500',
        '2304104422',
        '861322P020',
        '319222B900',
        'N9965015040',
        '0K60A41660J',
        '876101Y310',
        'GPKIT6X2741023700',
        '924101Y100',
        '770041M011',
        '824603E000',
        '66321M7000',
        '927001D000',
        '954803W202',
        '866111F000',
        '529101M100',
        '86610B0010',
        '8658507750',
        '3918023500',
        '552533W100',
        '555102W000',
        '392103C100',
        '555133N500',
        '92201B2020',
        '976194E000',
        '263203C30A',
        '28113D3300',
        '0K08137180A',
        '865112K100',
        '427003B700',
        '8240207011',
        '361002B100',
        '252122B020N',
        '2521204050',
        '865201Y010',
        '760041M011',
        '253353E000',
        '273003F100',
        '81310C5010',
        '589201F100',
        '981104E002',
        '86613A7001',
        '548131W000',
        '545840Q000',
        '273002E000',
        '87610D9050',
        '2521202552',
        '8636007050',
        '273012B000',
        '838301F001',
        '5810107A00BI',
        '495012P201',
        '581012TA20',
        '866813R110',
        '215104A401',
        '868314D001',
        '545004D002',
        '577002P100',
        '561103W060WK',
        '319112E000',
        '310902E000',
        '28113F2000',
        '977011Y000',
        '922022J006',
        '2244142910',
        '86111D9010N',
        '214434A001',
        '641003E310',
        '81130A7010',
        '338004A710',
        '51715A7000',
        '865112P000',
        '938104DRWBQQK',
        '938103K000',
        '565211M001',
        '92102A7071',
        '1885410080',
        '529504D100',
        '65910B2300',
        '568202K000',
        '820733R',
        '824502P000',
        '0K68C26251B',
        '86614A7000',
        '219102B200',
        '5510007251',
        '793102H000',
        '546262E000',
        '553512E501',
        '934903V110',
        '581011MA01',
        '868114E060',
        '223112E080',
        '92405D9500',
        '87620D9061',
        '319102H000',
        '319223E10A',
        '282104E510',
        '281131Y100',
        '281133E000',
        '281132S000N',
        '944603R000',
        '548302W000',
        'B319234H918',
        '7938007000',
        '821403W000',
        '437941Y500',
        '546201D000',
        '391552ESF0',
        '247003CBE1',
        '919501Y553',
        '7098028',
        '223212B000',
        '92101D9210AS',
        '597104D000',
        '0K41310136A',
        '544104E000',
        '865191M010',
        '927502P000',
        '92403C5110',
        '92401D9600',
        'GPKIT4X1882308101',
        '311112K701N',
        '847402P100CA',
        '868221F000',
        '69100A7500',
        '95400D9FG1',
        '59810A7300',
        '924603W220',
        '1884908085',
        '924062P300',
        '919202W100',
        '553102P100',
        '555402W100',
        '824713W000',
        '230603C712',
        '922012F100',
        '495761M600',
        '281133X000',
        'GPKIT4XGJZZ530R2BRA',
        '83541A7000',
        '868122G001',
        '918803W040',
        '548133F010',
        '553612E501BI',
        '922022P610',
        '641012F000',
        '52936D9100',
        '394613C100',
        'GPKIT4X1885508080',
        '64101D9000',
        '865601Y500',
        '517200Q000',
        '863503W510',
        '214904A000',
        '583022KA10',
        '2453142501',
        '583022JA00',
        '866111F050',
        '924502P600',
        '974902P000CA',
        '986823R000',
        '546512F200BI',
        '553302F140DS',
        '209204AU00',
        '529101M200',
        '54651C5BA0BI',
        '876102P150',
        '86665D9050',
        '821303W000',
        '569003W000WK',
        '861112P000BI',
        '561101M670DAJ',
        '252122B000',
        '8761007068',
        '577101M000',
        '243553CAB2',
        '2132142021',
        '838401F001',
        '583294E000',
        '921014D520',
        '2730104000',
        '394613C500',
        '927002P000',
        '552803E002',
        '851011U000',
        '218302F310',
        '97250R0300QQK',
        '221102E502',
        '924011F580',
        '921012F010',
        '263203CAA0',
        '4632123000',
        '319801Y700N',
        '28113A9100',
        '353102B000',
        '2431742020',
        '548303Q000',
        '868114E001',
        '664002F010',
        '861101F000',
        '252122E820',
        '58101B2A00BI',
        '86522D9520',
        '2241004010',
        '273002B010',
        '82850D9010',
        '584113A300',
        '553112G600BI',
        '922022F000',
        '416103E000',
        '2452942501',
        '552803E100',
        'GPKIT3VELASHB202015',
        '977751M001',
        '253101M300',
        '826613W010',
        '865111F510',
        '9574607010',
        '51712A9000',
        '2431226050',
        '252122B120',
        '873111M020',
        '701001MA30',
        '821103W000',
        '0K63B33196',
        '876202K151',
        '814102J010',
        'OL001',
        '284814A800',
        '327002P210',
        '551513E001',
        '92401B2020',
        '986104E500',
        '692001M210',
        '311113K000BI',
        '545842F000',
        '581802KA10',
        '663213E121',
        '0K08133066B',
        '87613D9100',
        '548402B200',
        '817503W000WK',
        '9713307010N',
        '924202P600',
        '8761407003',
        '812301M060',
        '328024E001',
        '865522K500',
        '86560C5010',
        '4335047000QQK',
        '876104D920',
        '2528137120',
        '254602G200',
        '5Z4853047AAA',
        '865813W000',
        '0K60B3328Z',
        '242003Y010',
        '55275D3000',
        '0K75A10110A',
        '876013E300',
        '822104E000',
        '583023QA10',
        '361003C150',
        '9240107620',
        '861502K100',
        '819002M710',
        '87620D9050',
        '770042K010',
        '924052P100',
        '554562E000',
        '847102K150WK',
        '451003BHE0',
        '273013C000',
        '517201D000',
        '243354A000',
        '2231123700',
        '8765007000',
        '918602F040QQK',
        '392102B320',
        '884004E510BC4',
        '664002P000',
        '218302F010',
        '599114E100',
        '921013W410',
        '319112K000',
        '971332H001',
        '873212J000',
        '223114A100',
        '2562023640',
        '97133B2000',
        '281132K000',
        '1885510060',
        '865782P000',
        '581011FA50',
        '553612F401',
        '496002J300',
        '9760607500',
        '55311D9230BI',
        '97775D9801',
        '826512JD003D',
        '545011D000',
        '54661C5BA0BI',
        '581012PA70BI',
        '922012K000',
        '824601M000',
        '66400B2000',
        '86642B2000',
        '977132D510N',
        '548302S500',
        '273012B010',
        '230412B500',
        '92402D9500',
        '253102P800',
        '86553B2000',
        '877112F300',
        '877121F000',
        '5172002000',
        '833012K210ARSAS',
        '1885508080',
        '73700B2000',
        '584112J000',
        '581013CA70',
        '55510D3500',
        '812301H000',
        '641012K000',
        '252123LAA0',
        '944602J001',
        '893602P540NXA',
        '92700D9000',
        '912112P583',
        '2538007550',
        '988503W100',
        '8652007550',
        'GPKIT6XGJZZ530R2BRA',
        '87712C5000',
        '544803E100',
        '281102P200',
        '546102E200',
        '976063E901',
        '495012K000',
        '545300U000',
        '882002PGF0NXB',
        '553001M300',
        '873601F000',
        '86350B0010',
        '517122G000',
        '825402P000',
        '495012J000',
        '541204E100',
        '254124E200',
        '86812B2100',
        '0K9A418170',
        '281122S110',
        '876141D900',
        '760032K000',
        '889014D051KS2',
        '826512P710',
        '865234E001',
        '959302P000',
        '2210042402',
        '583022SA30',
        '5172038110',
        '935732P000',
        'GPKIT6X1884611060',
        '0VS0118400A',
        '95720C5050SL2',
        'GPKIT4VELAS1881411051',
        '663111Y330',
        '52910C1930',
        '311102W200BI',
        '5450007100',
        '253502P500',
        'B971332E210',
        '888201M300WK',
        '877303E500',
        '971331S000',
        '8651507000',
        '281134E500',
        '858724E000GW',
        '546232P000',
        '664002K510',
        '224412G000',
        '583022PA70BI',
        '2311023780',
        '976062P500',
        '918602F023QQK',
        '977012P400',
        '5450107350',
        '866111M000',
        '82402A7001',
        '76003A7001',
        '0K65A11301J',
        '816471F000',
        '921023E651',
        '963332K410',
        '1884111051',
        '0K2A151150',
        '4335032110',
        '581444F000',
        '4632126000',
        '319112K700',
        '1898035925',
        '1884611070',
        '545512S000',
        '971332K000N',
        '4335032010',
        '826512P810',
        '222242G000',
        '8230207220EQ',
        '3610003402',
        '866951M000',
        '561102K070ALK',
        '92401D9500',
        '826513W710',
        '95430D9200',
        '921011Y530',
        '367104A000',
        '924522K500N',
        '5484007000N',
        '924512K500',
        '2551042100',
        '583023EE00',
        '77004R2000',
        '2521237182',
        '921012F511',
        '58101D3A11BI',
        '5610007500HU',
        '847103W050WK',
        '581014EA00',
        '92402A9530',
        '922021M300',
        '986342J000',
        '1885308060',
        '957602K210I7',
        '5463434000',
        '548302E200',
        '3191109000',
        '921014E042',
        '373002E410',
        '82657D9000',
        '1882308101',
        '283103CAB0',
        '252122G760',
        '760044D010',
        '92404C5110',
        '98850A4000',
        '2330025230',
        '954301Y301',
        '924102K010',
        '86811B2100',
        '83450D9000',
        '571003E101',
        '581012FA10',
        '55311D9230',
        '545302K000',
        '31112C9000',
        '988501F000',
        '281132H000',
        '924021F011',
        '527103W800',
        '868211F510',
        '2521223721',
        '876202K171',
        '3730004620',
        '46700A7110',
        '56390B2000',
        '252122G750',
        '957602P111',
        '848303W110GA7',
        '213204A000',
        '924021F530',
        '314532W100',
        '87614M7001',
        '252812B010',
        '760032K010',
        '0R20124460A',
        '548301S000',
        '97133B2000N',
        '581011YA00',
        '599102G100',
        '865542K000',
        '2432104003',
        '571702D101',
        '243864A100',
        '86688D9500',
        '0K72A27261',
        '253101M100',
        '971332F010N',
        '527501Y000',
        '253104E400',
        '545012G001',
        '54660B2070BI',
        '581012TA21BI',
        '28113N9000',
        '876203W132',
        '924022P640',
        '55540D3000',
        '581443K000',
        '919503W010',
        '2091023A00A',
        '956813E310',
        '552572P000',
        '954002K302',
        '924063W010',
        '319113E200',
        '957201M100',
        '210204A932',
        '252123C100',
        '876102F450',
        '2816437200',
        '253101Y500',
        '311112S000BI',
        '281133S800',
        '3671042021',
        '552272F000',
        '243884A100',
        '737002K030',
        '921011F021',
        '581012JA20BI',
        '510103E100',
        '311123Q500',
        'GPKIT6VELAS274103C000',
        '281133E500',
        '868223W000',
        '252123C200',
        'GPKIT3VELASHB202016',
        '319254E110',
        '921013W200',
        '877413W000',
        '219502T000',
        'GPKIT10X2151323001',
        '553001M300BI',
        '252122B000N',
        '223212E000',
        '553321J000',
        '866782P000',
        '0K60A18400',
        'B319224H900',
        '0K71E23570A',
        '921022P310',
        '86150B0000',
        '868313W001',
        '31112C9100',
        '861531M050',
        '218302P900',
        '581011FA50BI',
        '2244127001',
        '0K7CC58310',
        '918602F010QQK',
        '92800C5110WK',
        '921021F021',
        '2244123800',
        '971332H001N',
        '555103S000SJ',
        '548302V000',
        '944602K000',
        '0K95K15909',
        '5531007101',
        '545302P100',
        '9210107070',
        '561103W550WK',
        '252122E820N',
        '254114E200',
        '0K63B32611A',
        '863501Y301',
        '663211F110',
        '281132F800',
        '251004A855',
        '922022K000',
        '641013T000',
        '252123F310',
        '517121F000',
        '92402D9010',
        '92202D9000',
        '876241M000',
        '223214A000',
        '31120A7760QQK',
        '92102D9521',
        '84611A7000WK',
        '924032T120',
        '4328032030',
        '553484D000',
        '814202J010',
        '311201Y750QQK',
        '19347728',
        '971332B010',
        '255002B000',
        '944602K700',
        '2304042510',
        '922012F000',
        '548404D000',
        '583022SA10BI',
        '865112P010',
        '924062P100',
        '8611007161',
        '583021FE00',
        '517123E500',
        '866112P010',
        '54660B2080BI',
        '914103R511',
        '553612E501',
        '0K95412440',
        '2521225000',
        '3921002950',
        '852021F041LX',
        '883001M380AF1',
        '433714A010',
        '281132W100',
        '427003B010',
        '583022WA00BI',
        '292403C220',
        '552152E500',
        '517121M000N',
        '2741023700',
        '865302P700',
        '577103E200',
        '2335642500',
        '252124A401',
        '1K60A44150',
        '82651M6210',
        '97133R0000',
        '551303U000',
        '552303E400',
        '921023W011',
        '263203C100',
        '1884611060',
        '92101D9051',
        '581011MA01BI',
        '545841D000',
        '252123C321',
        '760042K010',
        '0K55818110',
        '548102S000',
        '581013FA00',
        '87610A9540',
        '252124A750',
        '281303E710',
        '924103W000',
        '928702F00087',
        '877422P000',
        '583112SA30',
        '865221M000',
        '64900C5020',
        '28113B2000',
        '8762007248',
        '863502P500',
        '987001Y000',
        '816503W000',
        '254724A830',
        '584113X300',
        '219302F500',
        '86665B2600',
        '91880D9030',
        '2852137104',
        '287002J050',
        '924102P640',
        '865303R050',
        '091492K200',
        '281302W250',
        '834111M010',
        '882022T081AN7',
        '826502K040',
        '924011Y100',
        '97133D1000',
        '263203N000',
        '223113CAD0',
        '86525C5520',
        '92405D9600',
        '971332E910',
        '921024E500',
        '060926',
        '58101B2A00',
        '940521M140',
        '868102P000',
        '86650B2100',
        '19349290',
        '737002J021',
        '927001Y300',
        '311503E300',
        '876102J221',
        '876202P150',
        '924011Y310',
        '817501Y010EQ',
        '865691Y000',
        '553002K820',
        '924052P020',
        'GPKIT6VELAS1884611070',
        '924021Y100',
        '972052P305',
        '92401C5600',
        '86510D9010',
        '210204A932BI',
        '846701F000WK',
        '3151042850',
        '4735239300',
        '866112J010',
        '865111M000',
        '583022SA10',
        '921012P310',
        '8766007000',
        '863501F031',
        '77003G6000',
        '972501D201',
        '4130002875',
        '940231Y570',
        '86511D9500',
        '581012FA01',
        '402Z42GH00',
        '2816342762',
        '0K05S52221A',
        '253102S500',
        '82210D9000',
        '517121M500',
        '561101M670DAM',
        '581012JA20',
        '881102P090',
        '81646D9100WK',
        '865622P500',
        '760032F040',
        '957503W120',
        '977743V600',
        '924201Y100',
        '86812A9000',
        '76004M7000',
        '5511617000',
        '527501C200',
        '842601M020WK',
        '0K6B023603AS',
        '243502B000',
        '865244E001',
        '922013R510',
        '959102K210',
        '583023LA20',
        '877513W500',
        '0K95B27121',
        '3610022805',
        '865171M000',
        '598302T500',
        '546332M000',
        '81666D9000WK',
        '986723R000',
        '924021M520',
        '823021M610DAG',
        '86356A7000',
        '581013EE01',
        '865302K000',
        '977012P560',
        '495361M600',
        '816462P000H9',
        '529332J100',
        '252124A600',
        '281132P100',
        '546512F400',
        '97674D3000QQK',
        '965664D520',
        '56310D9010',
        '663212F010',
        '876203W211',
        '0K6B023603',
        '92402D9110',
        '577004D805',
        '646023W000',
        '569003W001WK',
        '91230A7300',
        '881001Y010DGB',
        '97139C5000',
        '924022G610',
        '976061M000',
        '517202D100',
        '7600307010',
        '921023W410',
        '919203J100',
        '0K60C51030B',
        '517151Y010',
        '281133S100',
        '581012PA70',
        '612204E600',
        '252123A700',
        '495351Y001',
        '961252L000',
        '577102S000',
        '254564B150',
        '253102E101',
        '274103C000',
        '232002B020',
        '83120D9000',
        '0K60A26157',
        '956713E310',
        '921021D070',
        '971333SAA0N',
        '954104E050',
        '98620D9100',
        '0K60C23570',
        '82410A7040',
        '877511Y000',
        '9240207620',
        '94460B2700',
        '865122P100',
        '69100A7600',
        '581012SA70BI',
        '224412B000',
        '52960P1000',
        '877221F000',
        '2811307100',
        '1864761566N',
        '97133R0500',
        '9240207000',
        '928704D500TW',
        '4262026001',
        '393002B000',
        '548304D000',
        '976193E010',
        '97133C5000N',
        '845303W100',
        '581013LA10',
        '561002J230WK',
        '252864A010',
        '97133F2000',
        '517162S000',
        '552633R000',
        '583022LA00',
        '253102E100',
        '263003CAB1',
        '311102E001',
        '0K08133065A',
        '2730004000FFF',
        '877412P000',
        '58101M4A10BI',
        '253801F251',
        '546512E500BI',
        '5841139600',
        '865114D001',
        '7112007A30',
        '924011F011',
        '392102B040',
        '8651107500',
        '9770107110',
        '273013C100',
        '86642A7000',
        '577242E000',
        '58101B2A20BI',
        '922023W000',
        '1882311101',
        '876261M005',
        '81646D9000ED',
        '260609154R',
        '543004E200BI',
        '583022EA00',
        '5295014140N',
        '82530D9000',
        '866202T500',
        '97606D3010',
        '663114D000',
        '568202H090BI',
        '865302P000',
        '254124E450',
        '1881411051',
        '86612M7010',
        '865621M310',
        '581013SA20BI',
        '886001M800',
        '581013RA05',
        '54584C5000',
        '523713E000',
        '86565D9010',
        '76004A9001',
        '545512H000',
        '760042K000',
        '2811308000',
        '415534E000',
        '655131Y010',
        '546512P900BI',
        '954302T610',
        '581012SA51',
        '824022P000',
        'B281132S000',
        '546602K820',
        '921021Y530',
        '1887508085',
        '1249304127B',
        '883001Y470DGB',
        '866122T500',
        '284112B600',
        '664002T010',
        '553512E501BI',
        '986902T200',
        '28113G6000',
        '924011F530',
        '583023RA00',
        '86111A7020N',
        '876202P160',
        '922021F000',
        '546202F000',
        '971332W000',
        '319114D600',
        '291363T000',
        '925011F000',
        '353043CA00QQK',
        '213502B011',
        '96720D9010',
        '97759A9000',
        '541204E500',
        '218100Q000',
        '865304E600',
        '714012KB20',
        '553202J401BI',
        '517161Y010',
        '956702F000',
        '55310B2040',
        '86560C5500',
        '51712C1000',
        '210202B010BI',
        '8651107520N',
        '872702J030',
        '4142102000',
        '883604E322BC4',
        '555302W100',
        '919203J000',
        '956701Y300',
        '234104A950',
        '58101A5A00',
        '760043W011',
        '555302E200',
        '876103W132',
        '981102P000',
        '944302P000',
        '91113D9190',
        '583502FA00',
        '58101D3A11',
        '2221225002',
        '252122G700',
        '517122C000',
        '664002P500',
        '545842K000',
        '957203W400',
        '95720D3200EB',
        '31111A5750N',
        '2630035503',
        '261004A012',
        '546110U000',
        '865712P500',
        '58510A7100',
        '584112B000',
        '581011FE01',
        '252863C250',
        '252814A600',
        '28600D9260',
        '517123K010',
        '893102J001',
        '373002B101',
        '284112B320',
        '224102B800',
        '29110D9500',
        '219502K000',
        '770042K511',
        '210203C230',
        '944602E000',
        '2244125002',
        '861102J000BI',
        '281132S000',
        '971332E210N',
        '252853A500',
        '834011M010',
        '824601Y000',
        '545842S100',
        '2431237500',
        '957201M0103D',
        '495092PA20',
        '976262P000',
        '587113R000',
        '3921004010',
        '92202B0500',
        '495001D201',
        '2846142610N',
        '219302E300',
        '924062P020',
        '813104E020',
        '2510042700',
        '283554A800',
        '47354H1010',
        '851012K200',
        '209102BH04',
        '553102P600BI',
        '581013FA01',
        '2335742030',
        '214433C700',
        '866204D010',
        'GPKITTROCAOLEOHYUNDAI10',
        '219502K500',
        '319274H000',
        '4110026021',
        '663211F130',
        '581013SA25',
        '0K63B40050G',
        '313422K000',
        '263203CAA0N',
        '565002W300',
        '253102K000',
        '86612B2100',
        '58101N7A20',
        '581102S550',
        '412003C550',
        '970403W300',
        '4335032100',
        '218304E000',
        '826521M250',
        '868213E510',
        '56351G3AAAFFF',
        '311112P000',
        '659103W070',
        '863502P000',
        '564004F000',
        '92403B0730',
        '581902KA10',
        '715041YC30',
        '823011M620DAG',
        '314532D530',
        '254421D100',
        '971333SAA0',
        '86611D9000',
        '553302S150',
        '264324A700',
        '86611C5500',
        '2521207490',
        '583022SA00BI',
        '924012P640',
        '81620D9030',
        '891001Y200DGB',
        '568202W050BI',
        '823083W010WK',
        '3920042030',
        '5810107A00',
        '92402D9600',
        '79710D9000',
        '737003W010',
        '977211G000',
        '462044C000',
        '391083C540',
        '831301D100',
        '922013W000',
        '58302D3A00BI',
        '583022FA00',
        '568202W000BI',
        '3931038050',
        '8452007000ED',
        '545412H000',
        '2214439001',
        '0K08133067A',
        '1885011050',
        '97133N9100',
        '584111H300',
        '9771302000',
        '646013W000',
        '31112L1000',
        '553001Y210BI',
        '545012S500',
        '857151Y200EQ',
        '1140306123',
        '922013W100',
        '1887208085',
        '581012VA00',
        '715032PC70',
        '9783429010',
        '87731C5200',
        '922011Y500',
        '1885508070',
        '252123C322',
        '283122E000',
        '417004F010',
        '546612E500BI',
        '82307D9020WK',
        '71503B2C00',
        '921302P000',
        '2103003100',
        '877112P010',
        '281103X000',
        '983503X000N',
        '976062S500',
        '583023XA30',
        '963102K000',
        '517602E000',
        '2210002766',
        '823062K537EXA',
        '282153CAA0',
        '2431223202',
        '284854A800',
        '546502K820',
        '737002K020',
        '1864935009NN',
        '97133G6000',
        '49583C5050',
        '865703W000',
        '256003CAE0',
        '568204E050BI',
        '252123N300',
        '92126C1500',
        '2546235504',
        '97133F2000N',
        '231242B010',
        '392102B310',
        '291103R100SJ',
        '866204D000',
        '311152P000QQK',
        '715032PC01',
        '495013W600SJ',
        '3930084400',
        '957602K0001D',
        '811252P500',
        '218202S000',
        '962003W000',
        '583021FA50',
        '2442342200',
        '219502S100',
        '86565C5010',
        '770032P010',
        'GPKITTROCAOLEOHYUNDAI16',
        '91845C5010',
        '95920B4000',
        '2151025001',
        '92406D9600',
        '243603CAA2',
        '25380D3500',
        '69200M7100',
        '6D0562EU00',
        '373002E400',
        '1887111075',
        '353102B030',
        '92401D9010',
        '956802P100',
        '319112F000',
        '529104D500',
        '230412B510',
        '924023E530',
        '583502KA00',
        '82220D9000',
        '54830D3000',
        '911113W081',
        '863602T000',
        '97133A9000',
        '281124E500',
        '517121S300',
        '86350M7010',
        '56111A7100D3B',
        '1885810090',
        '529103W100',
        '1249304167B',
        '86260R0000',
        '467802J000',
        '546232P500',
        '861324E011',
        '918762J500',
        '7140107B20',
        '77003B2000',
        '857403W000WK',
        '58101A7A01',
        '1864221007NN',
        '2731002610',
        '28113AT100',
        '2895042760',
        '956702W000',
        '866713F500',
        '252123C122',
        '86521D9520',
        '467902J000',
        '52910C5710',
        '215004Z400',
        '2144142010',
        '338004A710N',
        '87722R2100',
        '581012SA30',
        '1220106127B',
        '55530A9000',
        '861802K000',
        '546101D000',
        '0K52A42110',
        '944601M500',
        '86820C5500',
        '8651007130',
        '3921025110',
        '0K9A03329Z',
        '2521207480',
        '863502F501',
        '2306042921',
        '2528125000',
        '281131R100',
        '92402C5600',
        '986802P000',
        '545513L000',
        '261104A701',
        '9862214200',
        '770031M011',
        '972502K640',
        '554993N100',
        '924A3R2000',
        '541104E100',
        '865532K000',
        '285213C712',
        '91971A7091',
        '834102F011BI',
        '555133E000',
        '887001F0007BK',
        '645023W000',
        '546612S000N',
        '1338608007B',
        '553422P000',
        '546261G000',
        '972504E051CA',
        '657102K300',
        '86595D9540',
        'GPKIT20X2151323001',
        '972501Y170ASB',
        '865612J000',
        '510203E100',
        '58101A7A00',
        '981103W000',
        '2432104001',
        '866624D000',
        '553112S011',
        '281134E000',
        '0K08133065B',
        '865302J000',
        '866823R110',
        '956702P000',
        '651714E000',
        '881834E300GW',
        '052031Y000',
        '545002K600',
        '86596D9510',
        '86611B2500',
        '58101A9A10BI',
        '715043WC00',
        '552202E010',
        '2213242850',
        '28113H8100',
        '1864421058LN',
        '373003A002',
        '663112T000',
        '59810C5100',
        '581442C000',
        '823081Y010BK5',
        '865823W000',
        '92101M7110',
        '282102F000',
        '714023WB10',
        '857302P500VA',
        '314532P000',
        '847114E300GW',
        '392102B070',
        '561002P240AMN',
        '691004D500',
        '925011F100',
        '54610D3000',
        '4326026200',
        '76003B2001',
        'GPKIT4VELAS1885510060',
        '833083W030AK5',
        '3614025011',
        '54661B0HA0BI',
        '760044E001',
        '91950D9250QQK',
        '09363T4000FFF',
        '253502S500',
        '416052E005',
        '2336142000',
        '919503W033',
        '396503C200',
        '58101A7A00N',
        '834012P000',
        '314804A011',
        '973043F000',
        '1884308072',
        '663111F110',
        '569001D000WK',
        '252122E800',
        '52710D9000',
        '0K02027255',
        '2304104400',
        '2851037530',
        '223112B000',
        '1884408070',
        '8200676403',
        '957902J100',
        '546254D000',
        '646011M010',
        '863501Y500',
        '76003A7000',
        '833071Y000BK5',
        '311112P400BI',
        '833062K254EXA',
        '2521542602N',
        '737002P050',
        '922011Y300',
        '83530D9000',
        '263502M100',
        '824501M000',
        '88390A7000WK',
        '561101M830DAM',
        '58302B2A00BI',
        '553303K620',
        '71504A7C00',
        '877222P000',
        '83110B2000',
        '846602P000J7',
        '583023JA00',
        '924063W220',
        '76004A7000',
        '847403W540GA7',
        '4325032050',
        '529102K900',
        '922014E001',
        '973144D200',
        '715042PC70',
        '49583C5100',
        '561002P230AMN',
        '282101M400',
        '97133D3000N',
        '976742P100',
        '252802G105',
        '311513E600',
        '92406D9500',
        '877212J000',
        '77004M7010',
        '715042PC01',
        '9725007300',
        'GPKIT4X1884608073',
        '97420D9AB0SA1',
        '4528249000',
        '546342G000',
        '983501Y000N',
        '882001Y000DGB',
        '2441025001',
        '82860D9010',
        '31454L1300',
        '4110002870',
        '2431542200',
        '866503T500',
        '1884608073',
        '8230207220ED',
        '94460C5100',
        '25310D3550',
        '866113R050',
        '546302K270',
        '495934D700',
        '9220107500',
        '91950D9540',
        '87732R2100',
        '866343W000',
        '210202B911',
        '877223R110',
        '872802J030',
        '842601Y070EQ',
        '546602P700BI',
        '210204A941BI',
        '811901D100',
        '91950D9300QQK',
        '291204D100',
        '863503T310',
        '663182P000',
        '865691Y300',
        '2244323001',
        '967002P330VA2',
        '940222P251',
        '86530B0020',
        '86522D9300',
        '285134A002',
        '3104007800',
        '827604E000GW',
        '919503W030',
        '845501F001EZ',
        '254202S500QQK',
        '981101Y000',
        '845104E100GW',
        '3911104065',
        '28113L1000',
        '713221MD10',
        '77004B2000',
        '581011SA30BI',
        '86595D9530',
        '92850A9100GBU',
        '715102P000',
        '84126M7000',
        '84660A7101D8C',
        '760043R002',
        '822203W010',
        '222242B001',
        '817502P000J7',
        '4323539060',
        '582511M300',
        '548302T000',
        '0K55419100',
        '944604E010',
        '565532S000',
        '977012F030',
        '86611C5000',
        '0K67226152',
        '353103C300',
        '919584D221',
        '954502J000',
        '866203R000',
        '811253R000',
        '1864755007LNN',
        '770032G010',
        '859103W000WK',
        '86512B2510',
        '517034E100',
        '569002K200WK',
        '894001Y390DGB',
        '0K65A12115C',
        '58302N7A10',
        '658512K300',
        '863502K550',
        '716201M030',
        '417103E100',
        '247003C910',
        '713221YD30',
        '652104E001',
        '876103F660',
        '973104E130',
        '3918025300A',
        '2817342761',
        '969852D700',
        '892001Y200DGB',
        '28220A7000',
        '868222T300',
        '4529523000',
        '87620C5240',
        '92402B2120',
        '82140B2000EQ',
        '224414A700',
        '71504B2C00',
        '845501F001WK',
        '896204D210',
        '1885209070',
        '32700B2110QQK',
        '285213C702',
        '314202S100',
        '561104E025',
        '52934D9100',
        '392102G600',
        '961502K805',
        '91KIT3WGENQQK',
        '58302D3A00',
        '857011Y300EQ',
        '552182B000',
    ],
    Byd: [
        '1476508400',
        '1387050000',
        '1254394500',
        '1444405800',
        '1454936400',
        '1341423200',
        '1223754900',
        '1425027600',
        '1321537500',
        '1451239900',
        '1356857800',
        '1344043900',
        '1444406700',
        '1358336300',
        '1268305600',
        '1315352300',
        '1332246100',
        '1376621500',
        '1315349000',
        '1425043700',
        '1374602500',
        '1350648600',
        '1412045000',
        '1316704400',
        '1359118000',
        '1268109700',
        '1405138600',
        '1325454000',
        '1346477200',
        '1431735000',
        '1354178000',
        '1356527100',
        '1548679300',
        '1268305800',
        '1295713300',
        '1412913400',
        '1570974500',
        '1360789200',
        '1318274400',
        '1360789100',
        '1344264500',
        '1412045400',
        '1394417500',
        '1354178700',
        '1574432300',
        '1475331100',
        '1313439100',
        '1342326300',
        '1432905100',
        '1360789300',
        '1268305900',
        '1315349400',
        '1380686000',
        '1412045100',
        '1381452000',
        '1268317300',
        '1398684200',
        '1313438900',
        '1313543200',
        '1369831400',
        '1398683400',
        '1369835300',
        '1318650600',
        '1361122500',
        '1268108200',
        '1548679400',
        '1344044300',
        '1273765800',
        '1344044200',
        '1315199000',
        '1354179000',
        '1362859000',
        '1315350600',
        '1366911600',
        '1360788700',
        '1454936300',
        '1303389100',
        '1412045300',
        '1343115800',
        '1369074400',
        '1268298700',
        '1268316000',
        '1361021800',
        '1326338300',
        '1431936500',
        '1380686400',
        '1271232300',
        '1268298900',
        '1178596400',
        '1323518200',
        '1313656000',
        '1114959900',
        '1363961800',
        '1313658600',
        '1312461000',
        '1322205800',
        '1321826500',
        '1402812300',
        '1313656200',
        '1315372300',
        '1574702700',
        '1412045200',
        '1577134100',
        '1315016900',
        '1316156500',
        '1521943700',
        '1317133400',
        '1325483300',
        '1303389800',
        '1288574700',
        '1321479400',
        '1323234300',
        '1325242900',
        '1316511400',
        '1380691200',
        '1425043400',
        '1342344500',
        '1334547900',
        '1343650600',
        '1301136300',
        '1336809600',
        '1576482500',
        '1342346600',
        '1356780000',
        '1553815700',
        '1250870600',
        '1340886500',
        '1325859400',
        '1323517200',
        '1360796300',
        '1521943800',
        '1155151800',
        '1315275700',
        '1245993200',
        '1316161400',
        '1373308900',
        '1323504800',
        '1055497600',
        '1194060800',
        '1087701600',
        '1315199200',
        '1491173100',
        '1304454500',
        '1351041400',
        '1266517700',
        '1425028100',
        '1315372100',
        '1360795600',
        '1453311600',
        '1325242700',
        '1554106400',
        '1336809700',
        '1361122600',
        '1303390000',
        '1325483400',
        '1414524400',
        '1331636200',
        '1313439300',
        '1381600200',
        '1388680900',
        '1278446100',
        '1362336700',
        '1316153900',
        '1393332000',
        '1426328200',
        '1361021900',
        '1316151700',
        '1313967500',
        '1412913500',
        '1316498700',
        '1300280300',
        '1370801000',
        '1334547800',
        '1342346700',
        '1425050600',
        '1305667100',
        '1370800900',
        '1362861800',
        '1341841100',
        '1363961900',
        '1343115900',
        '1320529700',
        '1323504400',
        '1431936600',
        '1325737100',
        '1268108300',
        '1313444800',
        '1454936800',
        '1390720900',
        '1381661300',
        '1344044000',
        '1381600300',
        '1313658500',
        '1320528700',
        '1432904800',
    ],
    FiltrosAudi: [
        '04E115561T',
        '06L115562B',
        '4M0819439B',
        '04E129620',
        '1K1819669',
        '5Q0819669',
        '1SB201511',
        '5Q0129620B',
        '8W0133843C',
        '4M0133843G',
        '03C115561H',
        '06J115403Q',
        '02E305051C',
        'GPKIT02E305051C',
        '5C0129620',
        '4H0819439',
        '4M0127434H',
        '0BH325183B',
        '1K0201051K',
        'GPKITFILTRODSG7',
        '1K0129620G',
        '8W0133843',
        '8W0133843D',
        '06D115562',
        '03C115721A',
        '0B5325330A',
        '8K0133843M',
        '8K0819439B',
        '06E115562C',
        '059198405B',
        '079198405D',
        '6R0819653',
        '971819429B',
        '06E115562H',
        '4G0133843K',
        '8K0133843',
        '4K0133843G',
        'GPKITTROCAOLEODQ500',
        'GPKITTROCAOLEODSG7',
    ],
}

// function findDuplicates(obj) {
//     const duplicates = new Set();

//     for (const key in obj) {
//         if (Array.isArray(obj[key])) {
//             const seen = new Set();
//             for (const item of obj[key]) {
//                 if (seen.has(item)) {
//                     duplicates.add(item);
//                 } else {
//                     seen.add(item);
//                 }
//             }
//         }
//     }

//     return Array.from(duplicates);
// }

// const duplicateItems = findDuplicates(SliderProducts);
// console.log('duplicateItems:', duplicateItems);